<template>
    <div class="wrapper" v-loading="dataLoading">
        <div style="position: fixed; top: 10px; margin-left: 950px;text-align: center;">
            <el-button type="primary" @click="saveWord">
                保存Word
            </el-button>
            <br>
            <br>
            <el-button type="primary" @click="printHandler">
                打印
            </el-button>
        </div>

        <div class="paper" ref="wordContext" id="printer" style="max-width:800px;">
            <h1 style="text-align: center;font-size: 22px;">{{ dataInfo.msName }}</h1>
            <template v-for="(item, i) in dataInfo.items">
                <p>
                    <span style="font-size: 20px">{{ i + 1 }}.{{ item.MSI_Item }}：</span>
                    <span style="font-weight: bold;">平均分：{{ item.avgScore.toFixed(2) }}</span>
                </p>
                <template v-for="(sitem, index) in item.subItems">
                    <p>
                        <span>({{ index + 1 }})&nbsp;{{ sitem.MSI_Item }}</span> <span>({{ sitem.MSI_Score }}分)</span>
                        <br>
                        <span style="font-weight: bold;">本项目平均分：{{ sitem.avgScore.toFixed(2) }}</span>
                        <br>
                    </p>
                    <table style="width: 100%;border:solid 1px #eee;padding: 0;border-spacing: 0;">
                        <thead style="background: #f5f5f5;">
                            <th
                                style="margin:0px;width: 30%;text-align: left;padding: 5px 10px;border-right: solid 1px #eee; border-bottom: solid 1px #eee;">
                                分数</th>
                            <th
                                style="margin:0px;width:10%;padding: 5px 10px;border-right: solid 1px #eee; border-bottom: solid 1px #eee;">
                                小计</th>
                            <th style="margin:0px;padding: 5px 10px;border-bottom: solid 1px #eee;text-align: left;">比例</th>
                        </thead>
                        <tbody>
                            <tr v-for="ss in sitem.scoreList">
                                <td
                                    style="margin:0px;text-align: left;padding: 5px 10px;border-right: solid 1px #eee; border-bottom: solid 1px #eee;">
                                    {{ ss.Score }}分</td>
                                <td
                                    style="margin:0px;text-align: center;padding: 5px 10px;border-right: solid 1px #eee; border-bottom: solid 1px #eee;">
                                    {{ ss.score_Count }}</td>
                                <td style="margin:0px;padding: 5px 10px; border-bottom: solid 1px #eee;display: flex;">
                                    <div style="padding: 0px;
                                        border-radius: 10px;
                                        overflow: hidden;
                                        width: 260px;
                                        height: 20px;
                                        border: solid 0px #e3e4e6;
                                        background: #e3e4e6;">
                                        <div
                                            :style="'background-color: #29a4fa;height:20px;width:' + (ss.score_Count / sitem.allCount * 100) + '%'">
                                        </div>
                                    </div>
                                    <span>
                                        &nbsp;{{ (ss.score_Count / sitem.allCount * 100).toFixed(2) }}%
                                    </span>
                                </td>
                            </tr>
                            <tr style="background-color: #f5f5f5;">
                                <td style="margin:0px;text-align: left;padding: 5px 10px;border-right: solid 1px #eee;">
                                    本题有效填写人次</td>
                                <td style="margin:0px;text-align: center;padding: 5px 10px;border-right: solid 1px #eee; ">
                                    {{ sitem.allCount }}</td>
                                <td style="margin:0px;padding: 5px 10px;">

                                </td>
                            </tr>
                        </tbody>
                    </table>
                </template>
            </template>
        </div>
    </div>
</template>

<script>

import { exportMarkSheetInfoToWord } from '@/api/marksheet'
import { asBlob } from 'html-docx-js-typescript'
import printer from 'print-js'

export default {
    components: {},
    props: {},
    data() {
        return {
            unitCode: '',
            msId: '',
            dataInfo: {},
            dataLoading: false
        };
    },
    watch: {},
    computed: {},
    created() { },
    mounted() {
        if (!this.$route.query.unitCode) {
            this.$nextTick(() => {
                this.$message.error("没有发现机构参数");

            })
        } else {
            this.unitCode = this.$route.query.unitCode
        }
        if (!this.$route.query.msId) {
            this.$nextTick(() => {
                this.$message.error("没有发现评分表参数");

            })
        } else {
            this.msId = this.$route.query.msId
            this.getDataList();
        }
    },
    methods: {
        getDataList() {
            this.dataLoading = true;
            exportMarkSheetInfoToWord({
                unitCode: this.unitCode,
                msId: this.msId
            }).then(res => {
                this.dataInfo = res.data.data;
                this.dataLoading = false;
            })
        },
        printHandler() {
            printer({
                printable: 'printer',	//打印区域id
                type: 'html',		//打印类型是html
                scanStyles: false,
                targetStyles: ['*'],
            })
        },
        saveWord() {
            let htmls = this.$refs.wordContext;
            this.exec(htmls.innerHTML)
        },

        exec(htmls) {
            let page =
                `<!DOCTYPE html>
              <html lang="en">
                <head>
                  <meta charset="utf-8">
                  <meta http-equiv="X-UA-Compatible" content="IE=edge">
                  <meta name="viewport" content="width=device-width,initial-scale=1.0">
                  <title>` + this.dataInfo.msName + `</title>
                </head>
                <body>
                  <div id="app"> ` + htmls + ` </div>
                </body>
            </html>
            `;

            asBlob(page).then(data => {
                const link = document.createElement('a');  // 创建元素
                console.log("获取的数据============》", data);
                let blob = new Blob([data], { type: "application/msword" });
                link.style.display = 'none';
                link.href = URL.createObjectURL(blob);   // 创建下载的链接
                link.setAttribute('download', this.dataInfo.msName + ".doc");  // 给下载后的文件命名
                document.body.appendChild(link);
                link.click();  // 点击下载
                document.body.removeChild(link);  //  下载完成移除元素
                window.URL.revokeObjectURL(link.href);  // 释放掉blob对象
                //saveAs(fileData, 'file.docx') // 保存为docx文件
            }) // asBlob() 返回 Promise<Blob|Buffer>，用promise.then还是async语法都行
        },
    },
};
</script>
<style lang="scss" scoped>
.wrapper {
    display: flex;
    align-items: center;

    .paper {
        width: 800px;
        border: solid 1px #ccc;
        background: #fff;
        padding: 20px;
    }
}
</style>