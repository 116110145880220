<template>
    <div class="wrapper">
        <!-- <router-link to="/">首页</router-link> -->
        <div class="unitBar">
            <span>机构代码：<el-input v-model="unitCode" style="width:200px" @input="inputChange"></el-input></span>
        </div>

        <router-link :to="'/achievement?unitCode=' + unitCode" class="link">考试统计</router-link>

        <router-link :to="'/ate?unitCode=' + unitCode" class="link">随时考统计</router-link>

        <router-link :to="'/singleStatistics?msId=243&unitCode=' + unitCode" class="link">单项统计</router-link>
        <!-- <router-link :to="'/scoreList?unitCode='+unitCode" class="link">单项统计</router-link> -->

        <!-- <router-link :to="'/examiner?unitCode=' + unitCode" class="link">考官培训</router-link> -->


        <a class="link" href="/swagger">Swagger</a>

        <h3 style="text-align: center;">直播部分</h3>
        <div style="text-align: center;display: flex;align-items: center;justify-content: center;font-size: 20px;">
            <span>选择用户：</span>
            <!-- <el-link v-for="item in userList" @click="roomUserId = item.U_ID" :type="roomUserId == item.U_ID ? 'primary' : ''"
                style="margin-right: 10px;font-size: 20px;">{{ item.U_TrueName }}</el-link> -->

            <el-select v-model="roomUserId" filterable remote reserve-keyword placeholder="输入用户名称"
                remote-show-suffix :remote-method="remoteMethod" :loading="loading" @change="onUserChange">
                <el-option v-for="item in userList" :key="item.U_ID" :label="item.U_Name + ' : ' + item.U_TrueName"
                    :value="item.U_ID" />
            </el-select>
        </div>
        <div style="text-align: center;display: flex;align-items: center;justify-content: center;font-size: 20px;">
            <span>
                已选择： {{ this.userInfo.U_TrueName }} &nbsp;&nbsp;
            </span>
             <el-link @click="toRoomPage" style="font-size: 20px;margin: 10px;">进入直播间</el-link>
        </div>

        <!-- <router-link :to="'/rtc/list?uid=' + roomUserId + '&uname=' + " class="link">直播间</router-link> -->
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { queryUserByName } from '@/api/rtcRoom';
export default {
    components: {},
    props: {},
    data() {
        return {
            name: "",
            unitCode: 'osve',
            roomUserId: '',
            userList: [
                // {
                //     U_ID: 3647,
                //     U_TrueName: '管理员'
                // },
                // {
                //     U_ID: 3648,
                //     U_TrueName: '李智高'
                // },
                // {
                //     U_ID: 241915,
                //     U_TrueName: '评委31'
                // },
                // {
                //     U_ID: 241916,
                //     U_TrueName: '评委32'
                // },
                // {
                //     U_ID: 241917,
                //     U_TrueName: '评委33'
                // }
            ],
            loading: false
        };
    },
    watch: {

    },
    computed: {
        ...mapGetters(['userInfo'])
    },
    methods: {
        inputChange(val) {
            window.localStorage.setItem("unitCode", val)
        },
        toRoomPage() {
            if (!this.userInfo) {
                this.$message.error('请选择用户')
                return
            }

            // //从userList 里找出 id = roomUserId 的对象
            // let user = this.userList.find(item => item.U_ID == this.roomUserId)
            // this.$store.dispatch('SetUserInfo', user);
            this.$router.push(`/rtc/list`);
        },
        onUserChange(id){
            let user = this.userList.find(item => item.U_ID == id);
            console.log("onUserChange", user);
            this.$store.dispatch('SetUserInfo', user);
        },
        remoteMethod(query) {
            if (query) {
                console.log("remoteMethod", query)
                this.loading = true;
                queryUserByName(query).then(res => {
                    this.loading = false;
                    console.log("queryUserByName", res)
                    if (res.data.status == 200) {
                        this.userList = res.data.data
                    }
                })
            }
        }
    },
    created() {

    },
    mounted() {
        let geturl = window.location.href
        let getqyinfo = geturl.split('?')[1]   //qycode=1001&qyname=%E4%BC%81%E4%B8%9A%E5%BF%99   截取到参数部分
        let getqys = new URLSearchParams('?' + getqyinfo)  //将参数放在URLSearchParams函数中
        this.name = getqys.get('name')   //1001   
        //let getqyname = getqys.get('qyname')  //%E4%BC%81%E4%B8%9A%E5%BF%99

        if (window.localStorage.getItem("unitCode")) {
            this.unitCode = window.localStorage.getItem("unitCode");
        }

        this.$store.dispatch("GetUserInfo");//获取用户信息
    }
};
</script>
<style lang="scss">
.wrapper {
    .unitBar {
        text-align: center;
    }

    .link {
        font-size: 20px;
        text-align: center;
        padding: 10px;
    }
}
</style>