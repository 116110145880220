import request from './axiosApi'

request.defaults.baseURL = "/api"

export function getPageList(query){
    return request({
        url:'/MarkSheet',
        method:'post',
        data:query
    })
}

export function exportMarkSheetInfoToWord(query){
    return request({
        url:'/MarkSheet/exportMarkSheetInfoToWord',
        method:'post',
        data:query
    })
}

export function getMarkSheetItemTree(query){
    return request({
        url:'/MarkSheet/getMarkSheetItemTree',
        method:'post',
        data:query
    })
}

export function getRateInfoList(query){
    return request({
        url:'/MarkSheet/getRateInfoList',
        method:'post',
        data:query
    })
}


export function getExaminerTrainingList(query){
    return request({
        url:'/MarkSheet/getExaminerTrainingList',
        method:'post',
        data:query
    })
}

//