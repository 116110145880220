<template>
    <div class="wrapper scoreInfo">
        <div class="panel search" v-if="false">
            <div class="title">
                查询条件
            </div>
            <div class="content scoreDetail">
                <div class="inlineblo">
                    <div class="label">考核任务</div>
                    <div>
                        <el-select ref="selec" v-model="query.examTaskId" placeholder="请选择" style="width: 500px;" filterable
                            @change="onExamSelected">
                            <el-option v-for="(item, index) in examTaskList" :key="index" :value="item.TaskID"
                                :label="item.TaskName">
                                {{ item.TaskName }}
                            </el-option>
                        </el-select>
                        <!-- <el-input placeholder="请选择" v-model="selectedName" style="width:50vW"></el-input>
                    <el-button @click="open" type="primary">选择考试</el-button> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="panel top">
            <div class="title">
                基本数据
            </div>
            <div class="content scoreDetail">
                <div class="box">
                    <span class="value">{{ avgScore.mS_Score ? avgScore.mS_Score : 0 }}</span>
                    <span class="text">评分表分值</span>
                </div>
                <div class="box">
                    <span class="value">{{ avgScore.avg_Score ? avgScore.avg_Score.toFixed(2) : 0 }}</span>
                    <span class="text">平均得分</span>
                </div>
                <div class="box">
                    <span class="value">{{ avgScore.center_Score ? avgScore.center_Score.toFixed(2) : 0 }}</span>
                    <span class="text">中值</span>
                </div>
                <div class="box">
                    <span class="value">{{ avgScore.stdev_Score ? avgScore.stdev_Score.toFixed(2) : 0 }}</span>
                    <span class="text">标准差</span>
                </div>
                <div class="box">
                    <span class="value">{{ avgScore.s_count ? avgScore.s_count : 0 }}</span>
                    <span class="text">被考评次数</span>
                </div>
            </div>
        </div>
        <div class="panel dsScore">
            <div class="title">
                <el-link :type="dsNowType == 0 ? 'primary' : 'default'" @click="showScore(0)">评分细则失分率>=<el-input
                        style="width:70px" type="number" v-model="dsSearch" @input="onDsSearch"
                        @focus="getInputFocus($event)" min="0"
                        oninput="value=value.replace(/[^0-9.]/g,'')"></el-input></el-link>
                <el-link :type="dsNowType == 2 ? 'primary' : 'default'" @click="showScore(2)">评分细则得分率</el-link>

                <el-link :type="dsNowType == 1 ? 'primary' : 'default'" @click="showScore(1)">一级分类失分率</el-link>

                <el-link :type="dsNowType == 3 ? 'primary' : 'default'" @click="showScore(3)">一级分类得分率</el-link>
                <div style="flex: 1;"></div>
            </div>
            <div class="content">
                <div ref="dsChartDiv" :style="'height: ' + dsChartDivHeight + 'px;'"></div>
            </div>
        </div>
        <!-- <div style="display: flex;">
            <div class="panel">
                <div class="title">
                    <div>
                        评委统计
                        <span class="dis">(评委评分情况统计)</span>
                    </div>


                    <el-button type="" @click="scoreSection">设置成绩区间</el-button>
                </div>


                <div id="raterDistributionChartDiv" style="width:100%;height:440px;margin-top: 5px;"></div>
            </div>
        </div> -->

    </div>
</template>

<script>
import { getScoreAvg, getScoreDistribution, getDSScore1, getDSScoreDetail, getRaterScore } from "@/api/single.js";

import { toRaw } from '@vue/reactivity'
// import ECharts from 'vue-echarts'
// import 'echarts/lib/chart/bar'
// import { reactive, toRefs, Ref, ref, onMounted } from 'vue';

// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from 'echarts/core';
// 引入柱状图图表，图表后缀都为 Chart
import { PieChart, BarChart, LineChart } from 'echarts/charts';

// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
    TitleComponent,
    TooltipComponent,
    GridComponent,
    DatasetComponent,
    TransformComponent,
    LegendComponent,
    DataZoomComponent
} from 'echarts/components';
// 标签自动布局、全局过渡动画等特性
import { LabelLayout, UniversalTransition } from 'echarts/features';
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from 'echarts/renderers';

// 注册必须的组件
echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    DatasetComponent,
    TransformComponent,
    PieChart,
    BarChart,
    LineChart,
    LabelLayout,
    UniversalTransition,
    CanvasRenderer,
    LegendComponent,
    DataZoomComponent
]);
const echartsColors = [
    '#900c3f',
    '#c70039',
    '#e00c49',
    '#f82c00',
    '#f57356',
    '#f39268',
    '#ffc300',
    '#daf7a6',
    '#52be80',
    '#61be52'
];

export default {
    components: {
        // 'v-chart': ECharts
    },
    props: {},
    data() {
        return {
            avgScore: {
                mS_Score: 0,
                avg_Score: 0,
                center_Score: 0,
                stdev_Score: 0,
                s_count: 0
            },
            search: {
                msId: 243,
                start: '',
                end: ''
            },
            distributionScore: {},
            dsScoreDetail: [],
            dsScore1: [],
            dsNowSource: 0,
            dsNowType: 0,
            raterList: [],
            query: {

            },
            dsSearch: 0,
            dsChartDivHeight: 660,
            scoreDistribution: '0-60-70-80-90-100',
        };
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                if (this.$route.query.msId) {//需要监听的参数
                    //把需要显示的数据重新赋值
                    this.search.msId = this.$route.query.msId
                    console.log(this.search.msId)

                }
                if (this.$route.query.start) {
                    this.search.start = this.$route.query.start;
                }


                if (this.$route.query.end) {
                    this.search.end = this.$route.query.end;
                }
            }
        }
    },
    computed: {},
    methods: {
        ///设置成绩区间
        scoreSection() {
            //this.visibles = true
            this.$prompt('成绩区间设置样例:0-60-70-80-90-100', '设置成绩区间', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputValue: this.scoreDistribution
                // inputPattern:
                //     /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
                // inputErrorMessage: 'Invalid Email',
            })
                .then(({ value }) => {
                    this.$message({
                        type: 'success',
                        message: `设置成功`,
                    })
                    window.localStorage.setItem('scoreDistribution', value);
                    this.scoreDistribution = value;
                    this.getDataList();
                })
                .catch(() => {
                })
        },
        // initChart() {
        //     var option = {
        //         series: [
        //             {
        //                 type: 'pie',
        //                 label: {
        //                     alignTo: 'edeg',
        //                     formatter: '{b}\n{c}%'
        //                 },
        //                 data: [
        //                     {
        //                         value: (this.distributionScore.s_60 / this.distributionScore.total * 100).toFixed(2),
        //                         name: '0-60'
        //                     },
        //                     {
        //                         value: (this.distributionScore.s_70 / this.distributionScore.total * 100).toFixed(2),
        //                         name: '60-70'
        //                     },
        //                     {
        //                         value: (this.distributionScore.s_80 / this.distributionScore.total * 100).toFixed(2),
        //                         name: '70-80'
        //                     },
        //                     {
        //                         value: (this.distributionScore.s_90 / this.distributionScore.total * 100).toFixed(2),
        //                         name: '80-90'
        //                     },
        //                     {
        //                         value: (this.distributionScore.s_100 / this.distributionScore.total * 100).toFixed(2),
        //                         name: '90-100'
        //                     },
        //                 ],
        //                 roseType: 'area'
        //             }
        //         ]
        //     }

        //     //var distributionChart = echarts.init(document.getElementById('distributionChartDiv'));
        //     var distributionChart = echarts.init(this.$refs.distributionChartDiv);
        //     distributionChart.setOption(option);
        // },
        // addPercent(value) {
        //     return value + '%';
        // },
        // removePercent(value) {
        //     return value.replace('%', '');
        // },
        getInputFocus(event) {
            // console.log("event", event)
            event.currentTarget.select();
        },

        onDsSearch(v) {
            console.log(v, this.dsSearch);
            this.initDSChart();
        },
        showScore(type) {
            this.dsNowType = type;
            this.initDSChart();
        },
        initDSChart() {
            var label = [];
            var data = [];
            var rowHeight = 20;
            var rowCount = 0;
            if (this.dsNowType == 0 || this.dsNowType == 2) {
                for (var i = this.dsScoreDetail.length - 1; i >= 0; i--) {
                    let el = this.dsScoreDetail[i];
                    if (!this.dsSearch || el.sfl >= this.dsSearch) {
                        rowCount++;
                        label.push({
                            value: el.msiItem
                        });
                        if (this.dsNowType == 0) {
                            data.push(el.sfl)
                        }
                        else if (this.dsNowType == 2)
                            data.push(el.dfl)
                    }
                }
            } else if (this.dsNowType == 1 || this.dsNowType == 3) {

                for (var i = this.dsScore1.length - 1; i >= 0; i--) {
                    let el = this.dsScore1[i];
                    rowCount++;
                    label.push({
                        value: el.msiItem
                    });
                    if (this.dsNowType == 1)
                        data.push(el.sfl)
                    else if (this.dsNowType == 3)
                        data.push(el.dfl)
                }

            }
            var hh = label.length * 20;
            if (hh > 660)
                this.dsChartDivHeight = hh;
            else
                this.dsChartDivHeight = 660;

            var option = {
                // title: {
                //     text: 'World Population'
                // },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },

                },

                legend: {},
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    max: 100,
                    boundaryGap: [0, 0.01]
                },
                yAxis: {
                    type: 'category',
                    data: label,
                    axisLabel: {
                        formatter: function (value) {
                            if (value.length > 60) {
                                value = value.substring(0, 60) + '...'; // 截断字符串，并添加省略号
                            }
                            return value.replace(/\n/g, "");
                        }
                    }
                },
                series: [
                    {
                        type: 'bar',
                        label: {
                            show: true,
                            position: 'right',
                            formatter: this.dsLabelFormatter //"{@[0]}%" + (this.dsNowType == 0 ? '，{@[2]}人' : '')
                        },
                        data: data
                    }
                ]
            }

            var myChart = echarts.init(this.$refs.dsChartDiv);
            myChart.resize({
                height: this.dsChartDivHeight
            });
            myChart.clear();
            myChart.setOption(option);

        },
        dsLabelFormatter(data) {
            if (this.dsNowType == 0) {
                let dd = toRaw(this.dsScoreDetail[this.dsScoreDetail.length - data.dataIndex - 1]);
                return data.data + "%，" + dd.sf_count + "人"
            } else {
                return data.data + "%"
            }

            // switch (this.dsNowType) {
            //     case 0:
            //         let d0 = toRaw(this.dsScoreDetail[this.dsScoreDetail.length - data.dataIndex - 1]);
            //         return d0.sfl + "%，" + d0.sf_count +"人"
            //     case 1:
            //         let d1 = toRaw(this.dsScore1[this.dsScore1.length - data.dataIndex - 1]);
            //         return d1.dfl + "%"
            //     case 2:
            //         let d2 = toRaw(this.dsScoreDetail[this.dsScoreDetail.length - data.dataIndex - 1]);
            //         return d2.sfl + "%"
            //     case 3:
            //         let d3 = toRaw(this.dsScore1[this.dsScore1.length - data.dataIndex - 1]);
            //         return d3.dfl + "%"
            //     default:
            //         return ""
            // }
        },

        getDataList() {

            var search = Object.assign({
                scoreList: this.scoreDistribution
            }, this.search);
            console.log("this.search", this.scoreDistribution, search)
            getScoreAvg(search).then(res => {
                if (res.status == 200 && res.data.data) {
                    this.avgScore = res.data.data
                } else {
                    this.avgScore = {
                        mS_Score: 0,
                        avg_Score: 0,
                        center_Score: 0,
                        stdev_Score: 0,
                        s_count: 0
                    }
                }
            })

            // getScoreDistribution(search).then(res => {
            //     if (res.status == 200) {
            //         this.distributionScore = res.data.data;
            //         this.initRaterDistributionChart();
            //     }
            // })

            getDSScoreDetail(search).then(res => {
                if (res.status == 200) {
                    this.dsScoreDetail = res.data.data;
                    // this.initChart();
                    this.showScore(0, 0)
                }
            })

            getDSScore1(search).then(res => {
                if (res.status == 200) {
                    this.dsScore1 = res.data.data;
                    // this.initChart();
                }
            })
            // getRaterScore(search).then(res => {
            //     if (res.status == 200) {
            //         this.raterList = res.data.data;
            //         this.initRaterChart();
            //     }
            // })
        },
        //评委分数区间图表
        initRaterDistributionChart() {
            var emphasisStyle = {
                itemStyle: {
                    shadowBlur: 10,
                    shadowColor: 'rgba(0,0,0,0.3)'
                }
            };
            var scoreDistributionList = this.scoreDistribution.split('-');
            var legend = [];
            var series = {};
            var showColor = [];

            var raterDistributionList = toRaw(this.distributionScore)

            let div = document.getElementById('raterDistributionChartDiv');

            for (var i = 0; i < scoreDistributionList.length - 1; i++) {
                var start = scoreDistributionList[i];
                var end = scoreDistributionList[i + 1];

                let colorIndex = Math.ceil(Number(end) / 10) - 1;
                console.log(colorIndex)
                showColor.push(echartsColors[colorIndex]);

                let ll = start + "-" + end;
                legend.push(ll);
                series[ll] = {
                    name: ll,
                    type: 'bar',
                    stack: 'total',
                    yAxisIndex: 0,
                    emphasis: emphasisStyle,
                    label: {
                        show: true,
                        formatter: (data) => {
                            // console.log('formatter', data)
                            if (data.data > 0) {
                                return raterDistributionList[data.name][data.seriesName].UCount + "人 - " + data.data + "%";
                            }
                            return ""
                        }
                    },
                    // itemStyle: {
                    //     normal: {
                    //         color: function (colors) {
                    //             return colorList[colors.dataIndex];
                    //         }
                    //     },
                    // },

                    // emphasis: {
                    //     focus: 'series'
                    // },
                    data: []
                }
            }
            let xAxisData = [];





            for (const name in raterDistributionList) {
                // labels.push(name);
                xAxisData.push(name)
                // let data = [];
                for (const dis in raterDistributionList[name]) {
                    var el = raterDistributionList[name][dis];

                    series[dis].data.push(el.Proportion);

                }
            }

            var seriesData = [];
            for (const key in series) {
                seriesData.push(series[key])
            }


            var option = {
                color: showColor,
                legend: {
                    // show: true,
                    data: legend,
                    // left: "center",                              //组件离容器左侧的距离,'left', 'center', 'right','20%'
                    // top: "top",
                },
                title: [{
                    subtext: '（百分制）',
                    left: div.clientWidth / 2 + legend.length * 35 + 10,
                    top: -9,
                    textStyle: {
                        fontSize: 30,
                        fontWeight: 'blod'
                    }
                }],
                // brush: {
                //     toolbox: ['rect', 'polygon', 'lineX', 'lineY', 'keep', 'clear'],
                //     xAxisIndex: 0
                // },
                // toolbox: {
                //     feature: {
                //         magicType: {
                //             type: ['stack']
                //         },
                //         dataView: {}
                //     }
                // },
                tooltip: {},
                xAxis: {
                    data: xAxisData,
                    name: '评委',
                    axisLine: { onZero: true },
                    splitLine: { show: false },
                    splitArea: { show: false }
                },
                yAxis: [
                    {
                        type: 'value',
                        // name: '比例',
                        // position: 'left',
                        // alignTicks: true,
                        // axisLine: {
                        //     show: true,
                        // },
                        axisLabel: {
                            formatter: '{value}%'
                        }
                    }
                ],
                grid: {
                    bottom: 20
                },
                series: seriesData
            };

            // console.log('option', option)
            var distributionChart = echarts.init(document.getElementById('raterDistributionChartDiv'));
            distributionChart.clear();
            distributionChart.setOption(option);
        },
    },

    created() {
        console.log("created")
        // window.location = window.location

    },
    mounted() {
        console.log("mounted")

        // this.$nextTick(() => {
        //     this.getDataList();
        // })

        //获取本地缓存的成绩区间数据
        let sd = window.localStorage.getItem('scoreDistribution');
        if (sd) {
            this.scoreDistribution = sd;
            console.log("this.search1", this.scoreDistribution)
        } else {
            //没有已缓存的数据用默认数据填充
            window.localStorage.setItem('scoreDistribution', this.scoreDistribution)
        }

        if (this.search.msId) {
            this.getDataList();
        }
    }

};
</script>
<style lang="scss">
.wrapper {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .panel {
        border: solid 1px #eee;
        margin-bottom: 10px;
        flex: 1;
        margin-left: 5px;
        align-items: center;
        .title {
            background: #ecf5ff;
            padding: 10px 20px;

            .dis {
                color: #666;
                font-size: 14px;
            }
        }

        .content {
            padding: 10px
        }
    }
}

.scoreInfo {
    .scoreDetail {
        display: flex;
        margin-left: 10px;

        .box {
            display: flex;
            flex-direction: column;
            width: 200px;

            .value {
                font-size: 26px;
            }

            .text {
                color: #666;
            }
        }
    }

    .dsScore {
        .title {
            .el-link {
                margin-right: 20px;
            }
        }
    }

    .distribution {
        display: flex;

        .chart {
            width: 600px;
            height: 450px;
        }

        .grid {
            width: 100%;
            height: 450px;
        }

        .pagination {
            display: flex;
            justify-content: flex-end;
        }
    }

}</style>
