<template>
    <div>

        <!-- <el-card :bordered="false"> -->

        <div class="table-page-search-wrapper" id="divSearch">
            <div class="inlineblo">
                <div class="label">考试名称</div>
                <div style="flex: 1;">
                    <!-- <el-select ref="selec" v-model="query.examTaskId" placeholder="请选择" style="width: 80%" filterable
                        @change="onExamSelected" multiple>
                        <el-option v-for="(item, index) in examTaskList" :key="index" :value="item.TaskID"
                            :label="item.TaskName">
                            {{ item.TaskName }}
                        </el-option>
                    </el-select> -->
                    <el-input placeholder="请选择" v-model="selectedName" style="width:50vW"></el-input>
                    <el-button @click="open" type="primary">选择考试</el-button>
                </div>
            </div>
            <div class="inlineblo" style="margin-bottom:0px;display: none;">
                <!-- <div class="label">考试时间：</div>
                <div>{{ info.ExamTime }}</div> -->
                <div class="label">考站数量：</div>
                <div>{{ info.StationCount }}</div>
                <div class="label">应考人数：</div>
                <div>{{ info.AllStu }}</div>
                <div class="label">实考人数：</div>
                <div>{{ info.StuCount }}</div>
                <div class="label">缺考人数：</div>
                <div>{{ info.LStu }}</div>
            </div>
        </div>
        <div class="card-container">
            <div class="export_buttons">
                <el-button type="primary" plain @click="ExportExcel" :icon="Search"
                    v-loading="excelLoading">导出excel</el-button>

                <el-button type="" @click="scoreSection(3)">设置成绩区间</el-button>
            </div>
            <el-tabs default-active-key="marksheet" type="card" v-model="tabIndex">
                <el-tab-pane name="marksheet" label="按评分表统计">
                    <el-table id="table_marksheet" :data="markSheetList" border height="calc(100vh - 580px)"
                        v-loading="dataLoading" stripe :header-cell-class-name="'tableHeader'">
                        <el-table-column label="评分表" prop="MS_Name" />
                        <el-table-column label="人次" prop="all_count" sortable width="100">
                            <template #default="scope">
                                <el-link type="primary" @click="showScoreInfoListDialog({ msId: scope.row.MS_ID })">{{
                                    scope.row.all_count
                                }}</el-link>
                            </template>
                        </el-table-column>
                        <el-table-column label="总分" prop="MS_Score" sortable width="100" />

                        <el-table-column label="平均分" prop="avg_Score" :formatter="floatFormater" sortable />
                        <el-table-column label="中值" prop="median_Score" :formatter="floatFormater" sortable />
                        <el-table-column label="最高分" prop="max_Score" :formatter="floatFormater" sortable />
                        <el-table-column label="最低分" prop="min_Score" :formatter="floatFormater" sortable />
                        <el-table-column label="标准差" prop="stdev_Score" :formatter="floatFormater" sortable />
                    </el-table>
                    <div :class="'botom' + (expand ? ' expand' : '')">

                        <span v-if="expand" class="expandButton" type="primary" @click="() => { expand = false }"><el-icon>
                                <Minus />
                            </el-icon></span>
                        <span v-else class="expandButton" type="primary" @click="() => { expand = true }"><el-icon>
                                <FullScreen />
                            </el-icon></span>

                        <div class="echarts">
                            <div id="markSheetDistributionChartDiv"></div>
                        </div>

                    </div>
                </el-tab-pane>
                <el-tab-pane name="rater" label="按评委统计">
                    <el-table id="table_rater" :data="raterList" border height="calc(100vh - 580px)" v-loading="dataLoading"
                        highlight-current-row @current-change="stationHandleCurrentChange" stripe
                        :header-cell-class-name="'tableHeader'">
                        <el-table-column label="评委" prop="Rater_TrueName" width="400" />
                        <el-table-column label="评分表" prop="MS_Name" width="400" />

                        <el-table-column label="考生人数" prop="student_count" sortable />
                        <el-table-column label="评分人次" prop="all_count" sortable>
                            <template #default="scope">
                                <el-link type="primary"
                                    @click="showScoreInfoListDialog({ raterId: scope.row.Rater_ID, msId: scope.row.MS_ID })">{{
                                        scope.row.all_count
                                    }}</el-link>
                            </template>
                        </el-table-column>
                        <!-- <el-table-column label="总分" prop="MS_Score" sortable /> -->

                        <el-table-column label="平均分" prop="avg_Score" :formatter="floatFormater" sortable />
                        <el-table-column label="中值" prop="median_Score" :formatter="floatFormater" sortable />

                        <el-table-column label="最高分" prop="max_Score" :formatter="floatFormater" sortable />
                        <el-table-column label="最低分" prop="min_Score" :formatter="floatFormater" sortable />
                        <el-table-column label="标准差" prop="stdev_Score" :formatter="floatFormater" sortable />
                    </el-table>
                    <div class="botom">
                        <div class="echarts" id="raterDistributionChartDiv">

                        </div>
                    </div>

                </el-tab-pane>
                <el-tab-pane name="student" label="按学生统计" force-render>
                    <div class="inlinebloTab">
                        <div>&nbsp;学/工号</div>
                        <div>
                            <el-select v-model="studentSelected.Student_NO" style="width: 200px" filterable
                                placeholder="请选择学/工号" clearable>
                                <!-- <el-option :value="''"> 不限 </el-option> -->
                                <el-option v-for="(item, index) in studentList" :key="item.Student_NO"
                                    :value="item.Student_NO" :label="item.Student_NO">
                                    <!-- {{ item['学/工号'] }} -->
                                </el-option>
                            </el-select>
                        </div>
                        <div>&nbsp;姓名</div>
                        <div>
                            <el-select v-model="studentSelected.Student_Name" style="width: 200px" filterable
                                placeholder="请选择姓名" clearable>
                                <!-- <el-option :value="''"> 不限 </el-option> -->
                                <el-option v-for="(item, index) in studentList" :key="item.Student_Name"
                                    :value="item.Student_Name" :label="item.Student_Name">
                                </el-option>
                            </el-select>
                        </div>
                        <div>&nbsp;参考班级</div>
                        <div>
                            <el-select v-model="studentSelected.O_Name" style="width: 200px" filterable placeholder="请选择班级"
                                clearable>
                                <!-- <el-option :value="''"> 不限 </el-option> -->
                                <el-option v-for="(item, index) in classOptions" :key="item" :value="item" :label="item">
                                </el-option>
                            </el-select>
                        </div>
                        <div>
                            <el-button type="primary" @click="filterStudentList" :icon="Search">查询</el-button>
                        </div>
                    </div>

                    <el-table id="table_student" :data="studentShowList" border stripe height="calc(100vh - 250px)"
                        v-loading="dataLoading" :header-cell-class-name="'tableHeader'">
                        <el-table-column label="学/工号" prop="Student_NO" sortable width="140"
                            align="center"></el-table-column>
                        <el-table-column label="姓名" prop="Student_Name" width="120" align="center"></el-table-column>
                        <el-table-column label="班级" prop="O_Name" width="200" align="center"></el-table-column>
                        <el-table-column label="被考评次数" prop="ms_count" width="120" align="center">
                            <template #default="scope">
                                <el-link type="primary"
                                    @click="showScoreInfoListDialog({ studentId: scope.row.Student_ID })">{{
                                        scope.row.ms_count
                                    }}</el-link>
                            </template>
                        </el-table-column>
                        <el-table-column label="被多少人考评" prop="rater_count" width="100" align="center"></el-table-column>
                        <el-table-column label="分数段" align="center">
                            <el-table-column v-for="item, index in scoreDistributionNames" :key="item" :label="item + '分'"
                                :prop="item" align="center" :formatter="studentScoreFormater">
                                <template #default="scope">
                                    <el-link type="primary" v-if="scope.row[item] > 0"
                                        @click="showScoreInfoListDialog({ studentId: scope.row.Student_ID, scoreRange: item })">{{
                                            studentScoreFormater(scope.row, item, scope.row[item], index)

                                        }}</el-link>
                                    <span v-else>{{ studentScoreFormater(scope.row, item, scope.row[item], index) }}</span>
                                </template>
                            </el-table-column>
                        </el-table-column>
                    </el-table>
                    <div style="display: flex;justify-content: space-between;padding: 5px;">
                        <div></div>
                        <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle"
                            :current-page="studentPage.pageIndex" :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
                            :page-size="studentPage.pageSize" :total="studentPage.totalPage" background
                            layout="total, prev, pager, next, sizes">
                        </el-pagination>
                    </div>

                </el-tab-pane>
                <el-tab-pane name="class" label="按班级统计">
                    <el-table id="table_class" :data="classList" border height="calc(100vh - 580px)" v-loading="dataLoading"
                        stripe :header-cell-class-name="'tableHeader'">
                        <el-table-column label="班级" prop="O_Name" width="400" />
                        <el-table-column label="人次" prop="all_count" sortable />
                        <el-table-column label="平均分" prop="avg_Score" :formatter="floatFormater" sortable />
                        <el-table-column label="中值" prop="median_Score" :formatter="floatFormater" sortable />
                        <el-table-column label="最高分" prop="max_Score" :formatter="floatFormater" sortable />
                        <el-table-column label="最低分" prop="min_Score" :formatter="floatFormater" sortable />
                        <el-table-column label="标准差" prop="stdev_Score" :formatter="floatFormater" sortable />
                    </el-table>
                    <div :class="'botom' + (expand ? ' expand' : '')">

                        <span v-if="expand" class="expandButton" type="primary" @click="() => { expand = false }"><el-icon>
                                <Minus />
                            </el-icon></span>
                        <span v-else class="expandButton" type="primary" @click="() => { expand = true }"><el-icon>
                                <FullScreen />
                            </el-icon></span>

                        <div class="echarts">
                            <div id="classDistributionChartDiv"></div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane name="room" label="按房间统计">
                    <el-table id="table_room" :data="stationList" border v-if="query.ScoreState == 1"
                        height="calc(100vh - 580px)" v-loading="dataLoading" highlight-current-row
                        @current-change="stationHandleCurrentChange" stripe :header-cell-class-name="'tableHeader'">
                        <!-- <el-table-column label="考站" prop="ES_Name" width="400" /> -->
                        <!-- <el-table-column label="一级房间" prop="P_Room_Name" width="400" /> -->
                        <el-table-column label="房间名称" prop="Room_Name" width="400" />
                        <el-table-column label="全部人数" prop="all_count" sortable />
                        <el-table-column label="实考人数" prop="sk_count" sortable />
                        <el-table-column label="未到人数" prop="qk_count" sortable />
                        <el-table-column label="平均分" prop="avg_Score" :formatter="floatFormater" sortable />
                        <el-table-column label="最高分" prop="max_Score" :formatter="floatFormater" sortable />
                        <el-table-column label="最低分" prop="min_Score" :formatter="floatFormater" sortable />
                        <el-table-column label="标准差" prop="stdev_Score" :formatter="floatFormater" sortable />
                    </el-table>
                    <div :class="'botom' + (expand ? ' expand' : '')">

                        <span v-if="expand" class="expandButton" type="primary" @click="() => { expand = false }"><el-icon>
                                <Minus />
                            </el-icon></span>
                        <span v-else class="expandButton" type="primary" @click="() => { expand = true }"><el-icon>
                                <FullScreen />
                            </el-icon></span>

                        <div class="echarts">
                            <div id="roomDistributionChartDiv"></div>
                        </div>
                    </div>

                </el-tab-pane>
                <el-tab-pane name="loss" label="失分率统计">
                    <div class="inlinebloTab">
                        <div class="title">
                            <el-link :type="dsNowType == 0 ? 'primary' : 'default'" @click="showScore(0)">评分细则失分率>=<el-input
                                    style="width:70px" type="number" v-model="dsSearch" @input="onDsSearch"
                                    @focus="getInputFocus($event)" min="0"
                                    oninput="value=value.replace(/[^0-9.]/g,'')"></el-input></el-link>
                            <el-link :type="dsNowType == 2 ? 'primary' : 'default'" @click="showScore(2)">评分细则得分率</el-link>

                            <el-link :type="dsNowType == 1 ? 'primary' : 'default'" @click="showScore(1)">一级分类失分率</el-link>

                            <el-link :type="dsNowType == 3 ? 'primary' : 'default'" @click="showScore(3)">一级分类得分率</el-link>
                            <div style="flex: 1;"></div>
                        </div>

                        <div style="flex:1">
                            <span>评分表名称:</span>
                            <el-select style="width: 200px" v-model="lossRateSelected.MS_Name" placeholder="请选择评分表"
                                clearable>
                                <el-option :key="item" :value="item" :label="item" v-for="(item, index) in dsMarkSheetList">
                                    <!-- {{ item.MS_Name }} -->
                                </el-option>
                            </el-select>
                        </div>

                        <div style="padding-right: 10px;">
                            <el-button @click="exportSFLExcel" :icon="Download" type="primary"
                                plain>导出学生失分率对比统计excel</el-button>
                        </div>
                    </div>
                    <div style="display: none;">
                        <table id="table_loss">
                            <thead>
                                <tr>
                                    <th>一级评分项</th>
                                    <th>二级评分项</th>
                                    <th>分值</th>
                                    <th>总得分</th>
                                    <th>失分率</th>
                                    <th>得分率</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item, index in lossRateShowList">
                                    <td>
                                        {{ item.itemName1 }}
                                    </td>
                                    <td>
                                        {{ item.itemName2 }}
                                    </td>
                                    <td>
                                        {{ item.MSI_Score }}
                                    </td>
                                    <td>
                                        {{ item.Score }}
                                    </td>
                                    <td>
                                        {{ item.sfl + "%" }}
                                    </td>
                                    <td>
                                        {{ item.dfl + "%" }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="echarts" id="sflChartDiv" style="height:calc(100vh - 240px)"></div>

                </el-tab-pane>
            </el-tabs>
        </div>
        <!-- </el-card> -->

        <form id="formExport" method="post" ref="formss">
            <input type="hidden" id="StatPic" :value="StatPic" name="StatPic" />
        </form>


        <DialogSelect @selected="onExamSelected" v-model:visible="dialogVisible" :unitCode="unitCode">
        </DialogSelect>
        <scoreListDialog v-model:visible="scoreListDialogVisible" :search="scoreListSearch">

        </scoreListDialog>
    </div>
</template>
<script setup>
import {
    Check,
    Delete,
    Edit,
    Message,
    Search,
    Download,
    FullScreen,
    Minus
} from '@element-plus/icons-vue'
</script>
<script>
import * as XLSX from 'xlsx'
import DialogSelect from './selectExamDialog.vue'
import { toRaw } from '@vue/reactivity'
import {
    getAllList, getStationStatic,
    getMarkSheetStatic,
    getDistributionStatistics,
    getStudentStatistics, getLossRateStatistics, getClassStatistics, getRaterStatistics,
    getRaterDistributionStatistics,
    getMarkSheetDistributionStatistics,
    getClassDistributionStatistics,
    getRoomStatic, getRoomDistributionStatistics,
    getLossRateStatisticsExport
} from "@/api/exam"
import * as echarts from 'echarts/core';
// 引入柱状图图表，图表后缀都为 Chart
import { BarChart } from 'echarts/charts';
import {
    TitleComponent,
    TooltipComponent,
    GridComponent,
    DatasetComponent,
    TransformComponent,
    LegendComponent,
    DataZoomComponent
} from 'echarts/components';
// 标签自动布局、全局过渡动画等特性
import { LabelLayout, UniversalTransition } from 'echarts/features';
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from 'echarts/renderers';

// import { toggleRowStatus } from 'element-plus/es/components/table/src/util';
import scoreListDialog from '../scoreListDialog.vue'
// 注册必须的组件
echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    DatasetComponent,
    TransformComponent,
    BarChart,
    LabelLayout,
    UniversalTransition,
    CanvasRenderer,
    LegendComponent,
    DataZoomComponent
]);

const serverUrl = ""
const echartsColors = [
    '#900c3f',
    '#c70039',
    '#e00c49',
    '#f82c00',
    '#f57356',
    '#f39268',
    '#ffc300',
    '#daf7a6',
    '#52be80',
    '#61be52'
];
export default {
    components: {
        DialogSelect,
        scoreListDialog
        // CreateForm
    },
    data() {
        return {
            dialogVisible: false,
            visible: false,
            visibles: false,
            excelLoading: false,
            expand: false,
            query: {
                examTaskId: '',
                examTableID: '',
                pageIndex: 1,
                pageSize: 10,
                sortField: 'totalScore',
                sortOrder: 'desc',
                student_UserInfoName: '',
                student_UserInfoTrueName: '',
                studentOrganization: '',
                ScoreStandard1: 60,
                ScoreStandard2: 80,
                ScoreState: 1,
                E_excel: 'no'
            },
            info: {},
            list: [],
            examTaskList: [],
            onOkData: {
                '学/工号': '',
                姓名: '',
                班级: ''
            },
            onOkDatas: {
                '学/工号': '',
                姓名: '',
                班级: ''
            },
            foottable: [],
            clomes: [],
            list2: [],

            stionGList: [],
            cli: [],
            clis: [],
            querydata: {
                MS_ID: '',
                E_ID: '',
                ScoreShow: false
            },
            StatPic: '',
            queryclome: [],
            quelist: [],
            quelist2: [],
            searchlist: [],
            footdata: [],
            s1: {},
            ////
            selectedList: [],
            dataLoading: false,
            distrlist: [],
            scoreDistribution: '0-60-70-80-90-100',
            stationList: [],
            markSheetList: [],
            dsMarkSheetList: [],
            classList: [],
            raterList: [],
            classOptions: [],
            studentList: [],
            studentShowList: [],
            studentSelected: {
                Student_NO: '',
                Student_Name: '',
                O_Name: ''
            },
            lossRateSelected: {},
            lossRateList: [],
            unitCode: '',
            tabIndex: "marksheet",
            raterDistributionList: [],
            markSheetDistributionList: [],
            markSheetChartDivHeight: 400,
            classChartDivHeight: 400,
            classDistributionList: [],
            roomDistributionList: [],
            dsNowType: 0,
            dsChartDivHeight: 600,
            dsSearch: 0,
            start: '',
            end: '',
            studentPage: {
                pageIndex: 1,
                pageSize: 20,
                totalPage: 0
            },
            exportExcelIng: false,
            scoreListDialogVisible: false,
            scoreListSearch: {}
        }
    },
    watch: {
        'studentSelected.Student_NO'(val) {
            this.filterStudentList();
            console.log(val)
        },
        'studentSelected.Student_Name'(val) {
            this.filterStudentList();
        },
        'studentSelected.O_Name'(val) {
            this.filterStudentList();
        },

        'lossRateSelected.MS_Name'(val) {
            this.filterLossRateList();
        },
        'lossRateSelected.U_Name'(val) {
            this.filterLossRateList();
            console.log(val)
        },
        'lossRateSelected.U_TrueName'(val) {
            this.filterLossRateList();
        },
        'lossRateSelected.O_Name'(val) {
            this.filterLossRateList();
        },
        $route: {
            immediate: true,
            handler() {
                if (this.$route.query.unitCode) {//需要监听的参数
                    //把需要显示的数据重新赋值
                    this.unitCode = this.$route.query.unitCode
                }
            }
        },
        dialogVisible(val) {
            console.log('dialogVisible', val)
        },
        tabIndex(val) {
            this.$nextTick(() => {
                this.getAllData(val)
            });
        }
    },
    computed: {
        //计算选择的考试名称
        selectedName() {
            var names = [];
            this.selectedList.forEach(element => {
                var el = toRaw(element)
                // console.log("name",el)
                names.push(el.E_Name)
            });
            return names.join(',');
        },
        scoreDistributionNames() {
            let scoreDistributionList = this.scoreDistribution.split('-');
            var legend = [];
            for (var i = 0; i < scoreDistributionList.length - 1; i++) {
                var start = scoreDistributionList[i];
                var end = scoreDistributionList[i + 1];

                let ll = start + "-" + end;
                legend.push(ll);
            }
            return legend;
        }
    },
    mounted() {

    },
    created() {
        if (!this.$route.query.unitCode) {
            this.$nextTick(() => {
                this.$message.error("没有发现机构参数");

            })
        } else {
            this.unitCode = this.$route.query.unitCode
        }
        //获取本地缓存的成绩区间数据
        let sd = window.localStorage.getItem('scoreDistribution');
        if (sd) {
            this.scoreDistribution = sd;
        } else {
            //没有已缓存的数据用默认数据填充
            window.localStorage.setItem('scoreDistribution', this.scoreDistribution)
        }

    },
    methods: {
        /**
         * 考试选择完成
         * @param {*} data 
         */
        onExamSelected(data, start, end) {
            this.selectedList = data;
            this.start = start;
            this.end = end;

            //直接获取按考站统计的数据填充上方的考站数量，应考人数等信息，这样可以少查一次数据。
            //this.changdata(1) 
            this.getAllData()
        },
        changdata(val) {
            this.query.ScoreState = val
        },
        /**
         * 标签切换
         * @param {*} index 
         */
        onTabChange(index) {
            this.tabIndex = index;
        },
        /**
         * 考站统计表行点击事件
         * @param {*} val 
         */
        stationHandleCurrentChange(val) {
            // var data = toRaw(val); //vue3的特性，事件传来的参数是响应参数，得toRaw 一下要不没法用。
            // this.getDistribution(data.ES_ID);
        },
        showScoreInfoListDialog(data) {
            var examIds = []; // = this.query.examTaskId;
            this.selectedList.forEach(el => {
                let dd = toRaw(el);
                examIds.push(dd.E_ID)
            })

            this.scoreListSearch = Object.assign({
                examIds: examIds,
                start: this.start,
                end: this.end,
                unitCode: this.unitCode
            }, data)
            this.scoreListDialogVisible = true;

        },
        /**
         * 获取总成绩数据
         */
        getAllData() {

            if (this.selectedList.length <= 0) {
                this.$message.error("至少选择一个考试")
                return
            }
            var examIds = []; // = this.query.examTaskId;
            this.selectedList.forEach(el => {
                let dd = toRaw(el);
                examIds.push(dd.E_ID)
            })
            this.dataLoading = true;

            if (this.tabIndex == "marksheet") {
                //获取评分表统计
                getMarkSheetStatic({
                    examIds: examIds,
                    start: this.start,
                    end: this.end,
                    unitCode: this.unitCode
                }).then(res => {
                    this.dataLoading = false;
                    if (res.status == 200)
                        this.markSheetList = res.data.data
                })

                //获取评分表分数段
                getMarkSheetDistributionStatistics({
                    examIds: examIds,
                    start: this.start,
                    end: this.end,
                    scoreList: this.scoreDistribution.split('-'),
                    unitCode: this.unitCode
                }).then(res => {
                    this.dataLoading = false;
                    if (res.status == 200 && res.data.data != null) {
                        this.markSheetDistributionList = res.data.data;
                        this.initDistributionChart(this.markSheetDistributionList, 'MS_Name', 'markSheetDistributionChartDiv');
                    }
                })

                //markSheetDistributionChartDiv
                //获取分数段
                //this.getDistribution();
            }


            if (this.tabIndex == "rater") {
                //获取评委统计
                getRaterStatistics({
                    examIds: examIds,
                    start: this.start,
                    end: this.end,
                    unitCode: this.unitCode
                }).then(res => {
                    this.dataLoading = false;
                    if (res.status == 200)
                        this.raterList = res.data.data
                })
                //获取评委评分分数段
                getRaterDistributionStatistics({
                    examIds: examIds,
                    start: this.start,
                    end: this.end,
                    scoreList: this.scoreDistribution.split('-'),
                    unitCode: this.unitCode
                }).then(res => {
                    this.dataLoading = false;
                    if (res.status == 200 && res.data.data != null) {
                        this.raterDistributionList = res.data.data;
                        this.initRaterDistributionChart();
                    }
                })
            }

            if (this.tabIndex == "class") {
                //班级统计
                getClassStatistics({
                    examIds: examIds,
                    start: this.start,
                    end: this.end,
                    unitCode: this.unitCode
                }).then(res => {
                    this.dataLoading = false;
                    if (res.status == 200)
                        this.classList = res.data.data
                })
                //班级评分段
                getClassDistributionStatistics({
                    examIds: examIds,
                    start: this.start,
                    end: this.end,
                    scoreList: this.scoreDistribution.split('-'),
                    unitCode: this.unitCode
                }).then(res => {
                    this.dataLoading = false;
                    if (res.status == 200 && res.data.data != null) {
                        this.classDistributionList = res.data.data;
                        this.initDistributionChart(this.classDistributionList, 'O_Name', 'classDistributionChartDiv');
                    }
                })
            }

            //学生统计
            if (this.tabIndex == "student") {
                getStudentStatistics({
                    examIds: examIds,
                    start: this.start,
                    end: this.end,
                    scoreList: this.scoreDistribution.split('-'),
                    unitCode: this.unitCode,
                    pageIndex: this.studentPage.pageIndex,
                    pageSize: this.studentPage.pageSize
                }).then(res => {
                    this.dataLoading = false;
                    if (res.status == 200) {
                        //this.studentList = res.data.data;
                        this.studentPage.totalPage = res.data.total;
                        this.initStudentList(res.data.data)
                    }
                })
            }

            if (this.tabIndex == "loss") {

                //失分率统计
                getLossRateStatistics({
                    examIds: examIds,
                    start: this.start,
                    end: this.end,
                    unitCode: this.unitCode
                }).then(res => {
                    this.dataLoading = false;
                    if (res.status == 200)
                        //this.lossRateList = res.data.data
                        this.initLossRateList(res.data.data)
                })
            }

            if (this.tabIndex == "room") {
                //获取房间统计
                getRoomStatic({
                    examIds: examIds,
                    start: this.start,
                    end: this.end,
                    unitCode: this.unitCode
                }).then(res => {
                    this.dataLoading = false;
                    if (res.status == 200 && res.data.data != null) {
                        this.stationList = res.data.data
                        this.info.StationCount = this.stationList.length;
                        this.info.AllStu = 0;
                        this.info.StuCount = 0;
                        this.info.LStu = 0;
                        this.stationList.forEach(el => {
                            this.info.AllStu += el.all_count;
                            this.info.StuCount += el.sk_count;
                            this.info.LStu += el.qk_count;
                        })
                    }
                });
                getRoomDistributionStatistics({
                    examIds: examIds,
                    start: this.start,
                    end: this.end,
                    scoreList: this.scoreDistribution.split('-'),
                    unitCode: this.unitCode
                }).then(res => {
                    this.dataLoading = false;
                    if (res.status == 200 && res.data.data != null) {
                        this.roomDistributionList = res.data.data;
                        this.initDistributionChart(this.roomDistributionList, 'Room_Name', 'roomDistributionChartDiv');
                    }
                })

            }

        },
        /**
         * 获取分数段统计的数据
         * @param {*} stationId 
         */
        // getDistribution(stationId) {
        //     var examIds = this.query.examTaskId;
        //     // this.selectedList.forEach(el => {
        //     //     let dd = toRaw(el);
        //     //     examIds.push(dd.E_ID)
        //     // })
        //     getDistributionStatistics({
        //         examIds: examIds,
        //         scoreList: this.scoreDistribution.split('-'),
        //         stationId: stationId ? stationId : ''
        //     }).then(res => {
        //         this.dataLoading = false;
        //         if (res.status == 200 && res.data.data != null) {
        //             this.distrlist = res.data.data;
        //             this.initChart()
        //         }
        //     })
        // },

        //学生统计的分页
        sizeChangeHandle(val) {
            this.studentPage.pageSize = val
            this.studentPage.pageIndex = 1
            this.getAllData();
        },
        //学生统计的分页
        currentChangeHandle(val) {
            this.studentPage.pageIndex = val
            this.getAllData();
        },



        /**
         * 表格行的formater float 类型保留两位小数
         * @param {*} row 
         * @param {*} column 
         * @param {*} cellValue 
         * @param {*} index 
         */
        floatFormater(row, column, cellValue, index) {
            if (cellValue == "NaN") {
                return 0;
            }
            if (typeof (cellValue) == "string") {
                return cellValue;
            }
            if (cellValue) {
                return cellValue.toFixed(2)
            }
            return cellValue
        },

        /**
 * 学生统计分数段比例计算
 * @param {*} row 
 * @param {*} column 
 * @param {*} cellValue 
 * @param {*} index 
 */
        studentScoreFormater(row, column, cellValue, index) {
            console.log('studentScoreFormater', row.ms_count, cellValue);
            return (cellValue / row.ms_count * 100).toFixed(2).replace(".00", "") + "%";
        },



        //评委分数区间图表
        initRaterDistributionChart() {
            var emphasisStyle = {
                itemStyle: {
                    shadowBlur: 10,
                    shadowColor: 'rgba(0,0,0,0.3)'
                }
            };
            var scoreDistributionList = this.scoreDistribution.split('-');
            var legend = [];
            var series = {};
            var showColor = [];
            let div = document.getElementById('raterDistributionChartDiv');

            for (var i = 0; i < scoreDistributionList.length - 1; i++) {
                var start = scoreDistributionList[i];
                var end = scoreDistributionList[i + 1];

                let colorIndex = Math.ceil(Number(end) / 10) - 1;
                console.log(colorIndex)
                showColor.push(echartsColors[colorIndex]);

                let ll = start + "-" + end;
                legend.push(ll);
                series[ll] = {
                    name: ll,
                    type: 'bar',
                    stack: 'total',
                    yAxisIndex: 0,
                    emphasis: emphasisStyle,
                    label: {
                        show: true,
                        formatter: (data) => {
                            // console.log('formatter', data)
                            if (data.data > 0) {
                                return this.raterDistributionList[data.name][data.seriesName].UCount + "人 - " + data.data + "%";
                            }
                            return ""
                        }
                    },
                    // itemStyle: {
                    //     normal: {
                    //         color: function (colors) {
                    //             return colorList[colors.dataIndex];
                    //         }
                    //     },
                    // },

                    // emphasis: {
                    //     focus: 'series'
                    // },
                    data: []
                }
            }
            let xAxisData = [];


            var raterDistributionList = toRaw(this.raterDistributionList)


            for (const name in raterDistributionList) {
                // labels.push(name);
                xAxisData.push(name)
                // let data = [];
                for (const dis in raterDistributionList[name]) {
                    var el = raterDistributionList[name][dis];

                    series[dis].data.push(el.Proportion);

                }
            }

            var seriesData = [];
            for (const key in series) {
                seriesData.push(series[key])
            }


            var option = {
                color: showColor,
                legend: {
                    // show: true,
                    data: legend,
                    // left: "center",                              //组件离容器左侧的距离,'left', 'center', 'right','20%'
                    // top: "top",
                },
                title: [{
                    subtext: '（百分制）',
                    left: div.clientWidth / 2 + legend.length * 35 + 10,
                    top: -9,
                    textStyle: {
                        fontSize: 30,
                        fontWeight: 'blod'
                    }
                }],
                // brush: {
                //     toolbox: ['rect', 'polygon', 'lineX', 'lineY', 'keep', 'clear'],
                //     xAxisIndex: 0
                // },
                // toolbox: {
                //     feature: {
                //         magicType: {
                //             type: ['stack']
                //         },
                //         dataView: {}
                //     }
                // },
                tooltip: {},
                xAxis: {
                    data: xAxisData,
                    name: '评委',
                    axisLine: { onZero: true },
                    splitLine: { show: false },
                    splitArea: { show: false }
                },
                yAxis: [
                    {
                        type: 'value',
                        // name: '比例',
                        // position: 'left',
                        // alignTicks: true,
                        // axisLine: {
                        //     show: true,
                        // },
                        axisLabel: {
                            formatter: '{value}%'
                        }
                    }
                ],
                grid: {
                    bottom: 20
                },
                series: seriesData
            };

            // console.log('option', option)
            var distributionChart = echarts.init(document.getElementById('raterDistributionChartDiv'));
            distributionChart.clear();
            distributionChart.setOption(option);
        },

        //分数区间图表
        initDistributionChart(data, titleField, divId) {
            let dataList = toRaw(data);

            var scoreDistributionList = this.scoreDistribution.split('-');
            var legend = [];
            var series = {};

            var showColor = [];

            let div = document.getElementById(divId);
            var width = div.clientWidth / 4;
            var height = 400;
            if (width < height) {
                height = width;
            }

            for (var i = 0; i < scoreDistributionList.length - 1; i++) {
                var start = scoreDistributionList[i];
                var end = scoreDistributionList[i + 1];

                let colorIndex = Math.ceil(Number(end) / 10) - 1;
                console.log(colorIndex)
                showColor.push(echartsColors[colorIndex]);

                let ll = start + "-" + end;
                legend.push(ll);
            }

            let seriesData = [];
            let titles = [{
                subtext: '（百分制）',
                left: div.clientWidth / 2 + legend.length * 35 + 10,
                top: -9
            }];
            let rowCount = Math.ceil(dataList.length / 4);
            this.classChartDivHeight = rowCount * 400;
            for (let i = 0; i < dataList.length; i++) {
                const el = dataList[i];
                let rowIndex = parseInt(i / 4);
                let colIndex = parseInt(i % 4);

                let dd = [];
                legend.forEach(leg => {
                    if (el[leg]) {
                        dd.push({
                            name: leg,
                            value: (el[leg] / el.total * 100).toFixed(2)
                        })
                    } else {
                        dd.push({
                            name: leg,
                            value: 0
                        })
                    }
                })

                let leftValue = (colIndex * width + width / 2);
                let topValue = (30 + rowIndex * height);
                titles.push({
                    subtext: el[titleField],
                    left: leftValue,
                    top: topValue,
                    textAlign: 'center'
                })
                let center = [(colIndex * width + width / 2), (rowIndex * height + height / 2 + 20)];
                // console.log("center", center, colIndex, rowIndex, width)
                seriesData.push({
                    type: 'pie',
                    radius: height / 3,
                    center: center,
                    data: dd,
                })
            }

            var option = {
                color: showColor,
                legend: {
                    // show: true,
                    data: legend,
                    // left: "center",                              //组件离容器左侧的距离,'left', 'center', 'right','20%'
                    // top: "top",
                },
                title: titles,
                tooltip: {
                    valueFormatter: (value) => value + "%"
                },
                grid: {
                    bottom: 20,
                    containLabel: true,
                    left: 'center',
                    top: 'middle',
                    width: '25%',
                    height: '25%'
                },
                series: seriesData,
                // dataZoom: [
                //     {
                //         type: 'slider',
                //         orient: 'horizontal',
                //         xAxisIndex: 0,
                //     }
                // ],

            };

            // console.log('option', option)
            var distributionChart = echarts.init(document.getElementById(divId));
            distributionChart.resize({
                height: this.classChartDivHeight
            });
            distributionChart.clear();
            distributionChart.setOption(option);
        },

        /**
         * 初始化学生列表
         * @param {*} data 
         */
        initStudentList(data) {
            this.studentList = data;
            var clist = [];
            data.forEach(el => {
                if (clist.indexOf(el.O_Name) < 0) {
                    clist.push(el.O_Name)
                }
            })
            this.classOptions = clist;
            this.studentShowList = JSON.parse(JSON.stringify(data));
        },
        /**
         * 筛选学生列表
         */
        filterStudentList() {
            var showList = this.studentList;

            if (this.studentSelected.Student_NO) {
                showList = showList.filter(el => el.Student_NO == this.studentSelected.Student_NO);
            }
            if (this.studentSelected.Student_Name) {
                showList = showList.filter(el => el.Student_Name == this.studentSelected.Student_Name)
            }
            if (this.studentSelected.O_Name) {
                showList = showList.filter(el => el.O_Name == this.studentSelected.O_Name)
            }

            // if (this.studentSelected.U_Name && this.studentSelected.U_TrueName && this.studentSelected.O_Name) {
            //     showList = this.studentList.filter(el => el.U_Name == this.studentSelected.U_Name && el.U_TrueName == this.studentSelected.U_TrueName && el.O_Name == this.studentSelected);
            // } else if (this.studentSelected.U_Name) {
            //     showList = this.studentList.filter(el => el.U_Name == this.studentSelected.U_Name);
            // } else if (this.studentSelected.U_TrueName) {
            //     showList = this.studentList.filter(el => el.U_TrueName == this.studentSelected.U_TrueName);
            // }

            this.studentShowList = JSON.parse(JSON.stringify(showList));
        },

        /**
         * 初始化得失分率表
         * @param {*} data 
         */
        initLossRateList(data) {

            this.lossRateList = data;
            var msNames = [];
            data.forEach(el => {
                if (msNames.indexOf(el.MS_Name) < 0) {
                    msNames.push(el.MS_Name);
                }
            })
            this.dsMarkSheetList = msNames;

            if (msNames.length > 0)
                this.lossRateSelected.MS_Name = msNames[0];
            //this.lossRateShowList = JSON.parse(JSON.stringify(data));
            this.filterLossRateList();
            this.initDSChart();
        },
        onDsSearch(v) {
            console.log(v, this.dsSearch);
            this.initDSChart();
        },
        showScore(type) {
            this.dsNowType = type;
            this.initDSChart();
        },
        /**
         * 筛选失分率表
         */
        filterLossRateList() {
            var showList = this.lossRateList;
            if (this.lossRateSelected.MS_Name) {
                showList = showList.filter(el => el.MS_Name == this.lossRateSelected.MS_Name)
            }
            this.lossRateShowList = JSON.parse(JSON.stringify(showList));
            this.initDSChart();
        },
        //得失分率图表
        initDSChart() {
            if (!this.lossRateShowList) return;
            var label = [];
            var data = [];
            var rowHeight = 20;
            var rowCount = 0;
            if (this.dsNowType == 0 || this.dsNowType == 2) {
                //for (var i = this.lossRateList.length - 1; i >= 0; i--) {
                this.lossRateShowList.forEach(el => {
                    if (!this.dsSearch || el.sfl >= this.dsSearch) {
                        rowCount++;
                        label.push({
                            value: el.itemName2
                        });
                        if (this.dsNowType == 0) {
                            data.push(el.sfl)
                        }
                        else if (this.dsNowType == 2)
                            data.push(el.dfl)
                    }
                });

                //}
            } else if (this.dsNowType == 1 || this.dsNowType == 3) {

                var dd = {};
                this.lossRateShowList.forEach(el => {
                    if (!dd[el.itemName1]) {
                        dd[el.itemName1] = {
                            SUM_MSI_Score: el.SUM_MSI_Score,
                            Score: el.Score,
                            msiItem: el.itemName1
                        }
                    } else {
                        dd[el.itemName1].SUM_MSI_Score += el.SUM_MSI_Score;
                        dd[el.itemName1].Score += el.Score;
                    }
                });

                for (const key in dd) {
                    var el = dd[key];
                    rowCount++;
                    label.push({
                        value: el.msiItem
                    });
                    if (this.dsNowType == 1)
                        data.push(Number(((el.SUM_MSI_Score - el.Score) / el.SUM_MSI_Score * 100).toFixed(2)))
                    else if (this.dsNowType == 3)
                        data.push(Number((el.Score / el.SUM_MSI_Score * 100).toFixed(2)))
                }
            }
            var hh = label.length * 20;
            if (hh > 660)
                this.dsChartDivHeight = hh;
            else
                this.dsChartDivHeight = 660;

            var option = {
                // title: {
                //     text: 'World Population'
                // },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },

                legend: {},
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    max: 100,
                    boundaryGap: [0, 0.01]
                },
                yAxis: {
                    type: 'category',
                    data: label,
                    axisLabel: {
                        formatter: function (value) {
                            if (value.length > 60) {
                                value = value.substring(0, 60) + '...'; // 截断字符串，并添加省略号
                            }
                            return value.replace(/\n/g, "");
                        }
                    }
                },
                series: [
                    {
                        type: 'bar',
                        label: {
                            show: true,
                            position: 'right',
                            formatter: this.dsLabelFormatter //"{@[0]}%" + (this.dsNowType == 0 ? '，{@[2]}人' : '')
                        },
                        data: data
                    }
                ],
                dataZoom: [
                    {
                        type: 'slider',
                        orient: 'horizontal',
                        //xAxisIndex: 0,
                    },
                    {
                        type: 'slider',
                        orient: 'vertical',
                        //xAxisIndex: 0,
                    }
                ],
            }

            var myChart = echarts.init(document.getElementById('sflChartDiv'));
            // myChart.resize({
            //     height: this.dsChartDivHeight
            // })
            myChart.setOption(option);

        },
        dsLabelFormatter(data) {
            return data.data.toFixed(2) + "%"
        },


        /**
 * 学生对比失分率统计导出
 */
        exportSFLExcel() {
            if (this.selectedList.length <= 0) {
                this.$message.error("至少选择一个考试")
                return
            }
            this.$message.success("开始导出...");
            var examIds = []; // = this.query.examTaskId;
            this.selectedList.forEach(el => {
                let dd = toRaw(el);
                examIds.push(dd.E_ID)
            })
            this.dataLoading = true;
            getLossRateStatisticsExport({
                examIds: examIds,
                start: this.start,
                end: this.end,
                unitCode: this.unitCode
            }, res => {
                this.dataLoading = false;
            })
        },

        /**
         * 导出EXCEL
         */
        ExportExcel() {
            if (this.selectedList.length <= 0) {
                this.$message.error("至少选择一个考试")
                return
            }
            var examIds = []; // = this.query.examTaskId;
            this.selectedList.forEach(el => {
                let dd = toRaw(el);
                examIds.push(dd.E_ID)
            })
            
            this.dataLoading = true;

            this.$message.success("开始导出...");

            var name = "";
            switch (this.tabIndex) {
                case "marksheet":
                    name = "评分表统计"
                    break;
                case "student":
                    name = "学生统计"
                    break;
                case "rater":
                    name = "评委统计"
                    break;
                case "class":
                    name = "班级评分统计"
                    break;
                case "room":
                    name = "房间统计"
                    break;
                case "loss":
                    //失分率统计
                    getLossRateStatistics({
                        examIds: examIds,
                        start: this.start,
                        end: this.end,
                        unitCode: this.unitCode,
                        toExcel:true
                    },res=>{
                        this.dataLoading = false;
                    });
                    name = "失分率统计-" + this.lossRateSelected.MS_Name
                    return;
                default:
                    break;
            }


            this.$nextTick(() => {
                // console.log(document.querySelector('#table_' + this.tabIndex))
               
                let xlsxParam = { raw: true }
                var wb = XLSX.utils.table_to_book(document.querySelector('#table_' + this.tabIndex), xlsxParam);
                //var wb = XLSX.utils.table_to_book(this.$refs['table_'+this.tabIndex]);
                var wbout = XLSX.write(wb, {
                    bookType: 'xlsx',
                    book: true,
                    type: 'array',
                });

                const link = document.createElement('a');  // 创建元素
                // console.log("获取的数据============》", data);
                let blob = new Blob([wbout], { type: "appliction/octet-stream" });
                link.style.display = 'none';
                link.href = URL.createObjectURL(blob);   // 创建下载的链接
                link.setAttribute('download', name + ".xlsx");  // 给下载后的文件命名
                document.body.appendChild(link);
                link.click();  // 点击下载
                document.body.removeChild(link);  //  下载完成移除元素
                window.URL.revokeObjectURL(link.href);  // 释放掉blob对象

                this.dataLoading = false;
            })
        },

        ///设置成绩区间
        scoreSection() {
            //this.visibles = true
            this.$prompt('成绩区间设置样例:0-60-70-80-90-100', '设置成绩区间', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputValue: this.scoreDistribution
                // inputPattern:
                //     /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
                // inputErrorMessage: 'Invalid Email',
            })
                .then(({ value }) => {
                    this.$message({
                        type: 'success',
                        message: `设置成功`,
                    })
                    window.localStorage.setItem('scoreDistribution', value);
                    this.scoreDistribution = value;
                    this.getAllData();
                })
                .catch(() => {
                })
        },
        open() {
            console.log('this.dialogVisible', this.dialogVisible)
            //this.$refs.selec.blur()
            this.dialogVisible = true
        }
    }
}
</script>
