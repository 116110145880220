import request from './axiosApi'

request.defaults.baseURL = "/api"
export function getList(page){
    return request({
        url:'/exam/list',
        method:'post',
        data:page
    })
}

export function getScoreInfoList(query) {
    return request({
        url: '/exam/scoreInfoList',
        method: 'post',
        data: query
    })
}
export function getScoreInfo(id) {
    return request({
        url: '/exam/scoreInfo?id=' + id,
        method: 'get'
    })
}


const mimeMap = {
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    xls:'application/vnd.ms-excel',
    zip: 'application/zip'
  }

const downloadXls= function(res,fileName){
    const link = document.createElement('a');  // 创建元素
    console.log("获取的数据============》",res);
    let blob = new Blob([res.data], { type: mimeMap });
    link.style.display = 'none';
    link.href = URL.createObjectURL(blob);   // 创建下载的链接
    link.setAttribute('download', fileName);  // 给下载后的文件命名
    document.body.appendChild(link);
    link.click();  // 点击下载
    document.body.removeChild(link);  //  下载完成移除元素
    window.URL.revokeObjectURL(link.href);  // 释放掉blob对象
}

export function getStationStatic(query,callback){
    if(query.toExcel){
        request({
            url:'/exam/stationStatistics',
            method:'post',
            data:query,
            responseType:'blob'
        }).then(res=>{
            downloadXls(res,"考站统计.xls");
            callback(res);
        }).catch(err=>{
            callback(err);
        })
    }else{
        return request({
            url:'/exam/stationStatistics',
            method:'post',
            data:query
        })
    }

}

export function getRoomStatic(query,callback){
    if(query.toExcel){
        request({
            url:'/exam/roomStatistics',
            method:'post',
            data:query,
            responseType:'blob'
        }).then(res=>{
            downloadXls(res,"房间统计.xls");
            callback(res);
        }).catch(err=>{
            callback(err);
        })
    }else{
        return request({
            url:'/exam/roomStatistics',
            method:'post',
            data:query
        })
    }

}
export function getRoomDistributionStatistics(query){
    return request({
        url:'/exam/roomDistributionStatistics',
        method:'post',
        data:query
    })
}



export function getMarkSheetStatic(query,callback){
    if(query.toExcel){
        request({
            url:'/exam/markSheetStatistics',
            method:'post',
            data:query,
            responseType:'blob'
        }).then(res=>{
            downloadXls(res,"评分表统计.xls");
            callback(res);
        }).catch(err=>{
            callback(err);
        })
    }
    else
        return request({
            url:'/exam/markSheetStatistics',
            method:'post',
            data:query
        })
}
export function getMarkSheetDistributionStatistics(query){
    return request({
        url:'/exam/markSheetDistributionStatistics',
        method:'post',
        data:query
    })
}

export function getClassStatistics(query,callback){
    if(query.toExcel){
        request({
            url:'/exam/classStatistics',
            method:'post',
            data:query,
            responseType:'blob'
        }).then(res=>{
            downloadXls(res,"班级评分统计.xls");
            callback(res);
        }).catch(err=>{
            callback(err);
        })
    }
    else
        return request({
            url:'/exam/classStatistics',
            method:'post',
            data:query
        })
}
export function getClassDistributionStatistics(query){
    return request({
        url:'/exam/classDistributionStatistics',
        method:'post',
        data:query
    })
}



export function getDistributionStatistics(query){
    return request({
        url:'/exam/distributionStatistics',
        method:'post',
        data:query
    })
}

export function getStudentStatistics(query,callback){
    if(query.toExcel){
        request({
            url:'/exam/studentStatistics',
            method:'post',
            data:query,
            responseType:'blob'
        }).then(res=>{
            downloadXls(res,"学生成绩统计.xls");
            callback(res);
        }).catch(err=>{
            callback(err);
        })
    }
    else
        return request({
            url:'/exam/studentStatistics',
            method:'post',
            data:query
        })
}

//失分率统计
export function getLossRateStatistics(query,callback){

    if(query.toExcel){
        request({
            url:'/exam/lossRateStatistics',
            method:'post',
            data:query,
            responseType:'blob'
        }).then(res=>{
            downloadXls(res,"得失分率统计.xls");
            callback(res);
        }).catch(err=>{
            callback(err);
        })
    }
    else
    return request({
        url:'/exam/lossRateStatistics',
        method:'post',
        data:query
    })
}

export function getLossRateStatisticsExport(query,callback){
    request({
        url: '/exam/lossRateStatisticsExport',
        method: 'post',
        data: query,
        responseType: 'blob'
    }).then(res => {
        downloadXls(res, "学生失分率对比统计.xls");
        callback(res);
    }).catch(err => {
        callback(err);
    })
}



//评委统计
export function getRaterStatistics(query,callback){
    if(query.toExcel){
        request({
            url:'/exam/raterStatistics',
            method:'post',
            data:query,
            responseType:'blob'
        }).then(res=>{
            downloadXls(res,"评委统计.xls");
            callback(res);
        }).catch(err=>{
            callback(err);
        })
    }
    else
        return request({
            url:'/exam/raterStatistics',
            method:'post',
            data:query
        })
}


export function getRaterDistributionStatistics(query){
    return request({
        url:'/exam/raterDistributionStatistics',
        method:'post',
        data:query
    })
}