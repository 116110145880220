<template>
    <div class="wrapper" v-loading="dataLoading">
        <div class="title">

            <span>评分表名称：{{ markSheet.MS_Name }}</span>
            <el-button type="success" @click="exportExcel"><el-icon>
                    <Download />
                </el-icon>导出excel</el-button>
        </div>
        <el-table id="dataTable" ref="dataTable" :data="tableData" style="height:calc(100vh - 70px)"
            @sort-change="onSortChange">
            <el-table-column prop="Rater_Name" label="考官信息" width="100" fixed align="center" />
            <el-table-column prop="Student_Name" label="考生信息" width="100" fixed align="center" />
            <el-table-column prop="SI_Score" label="总得分" width="80" fixed align="center" :formatter="floatFormater" />
            <el-table-column prop="all_pld" label="偏离度汇总" width="120" fixed align="center" sortable
                :formatter="pld_formatter" />

            <el-table-column :prop="'MSI_' + pitem.MSI_ID" :label="pitem.MSI_Item + ' (' + pitem.MSI_Score + ')'"
                v-for="(pitem, pkey, index) in markSheetItems" align="center" show-overflow-tooltip>
                <template #header="scope">
                    <el-tooltip class="table-label-tooltip" effect="dark" :content="scope.column.label"
                        placement="bottom"><span>{{ scope.column.label }}</span></el-tooltip>
                </template>
                <el-table-column :prop="'MSI_' + sitem.MSI_ID" :label="sitem.MSI_Item + ' (' + sitem.MSI_Score + ')'"
                    v-for="(sitem, pkey, index) in pitem.subItems" align="center" show-overflow-tooltip width="120">
                    <template #header="scope">
                        <el-tooltip class="table-label-tooltip" effect="dark" :content="scope.column.label"
                            placement="bottom"><span>{{ scope.column.label }}</span></el-tooltip>
                    </template>
                    <template #default="scope">
                        <template v-if="scope.row['MSI_' + sitem.MSI_ID]">
                            <span v-if="scope.row.ID != -1 || exportExcelIng">{{ scope.row['MSI_' + sitem.MSI_ID].score
                            }}分
                                <template v-if="scope.row.ID != -1">/ {{ scope.row['MSI_' + sitem.MSI_ID].pld
                                }}</template>
                            </span>
                            <el-input v-else v-model="scope.row['MSI_' + sitem.MSI_ID].score" @input="computedRows"
                                type="number" :max="sitem.MSI_Score" :min="0"></el-input>

                        </template>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
            </el-table-column>
            <!-- <el-table-column prop="id" label="操作" width="150" fixed="right" align="center" v-if="!exportExcelIng">
                <template #default="scope">
                    <el-link v-if="scope.row.ID != -1" @click="selectDefault(scope.row)"
                        :type="selectedRateId == scope.row.ID + scope.row.Student_ID ? 'success' : ''">选为标准值</el-link>
                </template>
            </el-table-column> -->
        </el-table>
    </div>
</template>
<script setup>
import {
    Check,
    Delete,
    Edit,
    Message,
    Search,
    Download,
} from '@element-plus/icons-vue'
</script>
<script>
import { getMarkSheetItemTree, getExaminerTrainingList as getRateInfoList } from "@/api/examinertraining"
import { toRaw } from '@vue/reactivity'
import * as XLSX from 'xlsx'

import { h } from "vue";//引入h
export default {
    components: {},
    props: {},
    data() {
        return {
            markSheet: {},
            markSheetItems: {},
            tableData: [],
            selectedRateId: '',
            exportExcelIng: false,
            start: '',
            end: ''
        };
    },
    watch: {},
    computed: {},
    methods: {
        // // 表头固定
        // tableRenderHeader(data) {
        //     // var column = toRaw(h.column);

        //     // console.log(column)

        //     return h('span', [
        //         h('el-tooltip', {

        //             class: "table-label",
        //             effect: 'dark',
        //             content: data.column.label,
        //             placement: 'top',

        //         }, [
        //             h('span', data.column.label)
        //         ])
        //     ])

        // },
        /**
 * 表格行的formater float 类型保留两位小数
 * @param {*} row 
 * @param {*} column 
 * @param {*} cellValue 
 * @param {*} index 
 */
        floatFormater(row, column, cellValue, index) {
            if (cellValue == "NaN") {
                return 0;
            }
            if (typeof (cellValue) == "string") {
                return cellValue;
            }
            if (cellValue) {
                return cellValue.toFixed(2)
            }
            return cellValue
        },
        onSortChange(column) {
            // console.log('onSocrtChange', column, this.tableData);
            if (column.order == 'descending') {
                 this.tableData[0].all_pld = 999;
            } else  {
                 this.tableData[0].all_pld = -111;
            }
        },
        getItemTree() {
            // this.dataLoading = true;
            getMarkSheetItemTree({
                unitCode: this.unitCode,
                msId: this.msId
            }).then(res => {
                this.markSheet = res.data.data.markSheet;
                this.markSheetItems = res.data.data.msiList;
                // this.dataLoading = false;
            })
        },
        getDataList() {
            this.dataLoading = true;
            getRateInfoList({
                unitCode: this.unitCode,
                msId: this.msId,
                start: this.start,
                end: this.end
            }).then(res => {

                //this.tableData = res.data.data;
                this.initTableData(res.data.data)
                this.dataLoading = false;
            })
        },
        initTableData(data) {
            var tempData = {};
            this.tableData = [];
            var defaultRow = {};

            data.forEach(el => {
                for (const key in el) {
                    if (key.indexOf("MSI_") >= 0) {
                        el[key] = {
                            score: el[key],
                            pld: 0
                        };
                        if (!defaultRow[key]) {
                            defaultRow[key] = {
                                score: el[key].score,
                                pld: 0
                            }
                        } else {
                            defaultRow[key].score += Number(el[key].score)
                        }
                    }
                }
                el.all_pld = 0;
                this.tableData.push(el)
            });
            for (const key in defaultRow) {
                defaultRow[key].score = Number((defaultRow[key].score / data.length).toFixed(2));
            }
            defaultRow.SI_Score = 0;
            defaultRow.ID = -1;
            defaultRow.Rater_Name = "参考值";
            defaultRow.Student_Name = "/";
            defaultRow.all_pld = "-";
            this.tableData.splice(0, 0, defaultRow);
            // console.log("defaultRow",defaultRow);
            //this.tableData.push(defaultRow);
            // console.log("this.tableData",this.tableData);
            this.computedRows();
        },
        pld_formatter(row, column, cellValue, index) {
            // console.log(cellValue)
            
            var vv = cellValue;
            if(row.ID == -1){
                return "-";
            }
            if (cellValue == 'Infinity' || cellValue == 'NaN') {
                vv = '标准值有0分'
            }
            return vv;
        },
        selectDefault(row) {
            var newRow = JSON.parse(JSON.stringify(row));

            //newRow.Score_Rater_ID = row.ID;
            newRow.all_pld = "/"
            this.selectedRateId = row.ID;

            newRow.ID = -1;
            newRow.Rater_Name = "参考答案";
            newRow.Student_Name = "/"
            if (this.tableData[0].ID == -1) {
                this.tableData.splice(0, 1, newRow);
            } else {
                this.tableData.splice(0, 0, newRow);
            }
            this.computedRows();
        },
        computedRows() {
            if (this.tableData.length > 0) {
                var defaultRow = this.tableData[0];
                if (defaultRow.ID == -1) {
                    var defaultAllScore = 0;
                    for (const key in defaultRow) {
                        if (key.indexOf("MSI_") >= 0) {
                            let dScore = Number(defaultRow[key].score);
                            defaultAllScore += dScore;
                        }
                    }
                    defaultRow.SI_Score = defaultAllScore;
                }



                if (defaultRow.ID == -1) {
                    for (let i = 1; i < this.tableData.length; i++) {
                        const row = this.tableData[i];
                        var pld = 0;
                        for (const key in row) {
                            if (key.indexOf("MSI_") >= 0) {
                                let dScore = Number(defaultRow[key].score);
                                let el = toRaw(row[key])
                                if (el) {
                                    if (dScore > 0) {
                                        var vv = Math.abs(dScore - Number(el.score)) / dScore;
                                        pld += vv;
                                        el.pld = vv.toFixed(2);
                                    } else {
                                        el.pld = 0
                                    }
                                }

                            }
                        }
                        row.all_pld = pld.toFixed(2);
                    }
                }

                this.$nextTick(() => {
                    this.$refs.dataTable.sort('all_pld', 'ascending')
                })
            }
        },
        exportExcel() {
            this.exportExcelIng = true;

            this.$nextTick(() => {
                var tableId = document.querySelector('#dataTable');
                // var tt = this.$refs.dataTable;
                // debugger
                var wb = XLSX.utils.table_to_book(tableId);
                var wbout = XLSX.write(wb, {
                    bookType: 'xlsx',
                    book: true,
                    type: 'array',
                });

                const link = document.createElement('a');  // 创建元素
                // console.log("获取的数据============》", data);
                let blob = new Blob([wbout], { type: "appliction/octet-stream" });
                link.style.display = 'none';
                link.href = URL.createObjectURL(blob);   // 创建下载的链接
                link.setAttribute('download', this.markSheet.MS_Name + "_偏离度汇总.xlsx");  // 给下载后的文件命名
                document.body.appendChild(link);
                link.click();  // 点击下载
                document.body.removeChild(link);  //  下载完成移除元素
                window.URL.revokeObjectURL(link.href);  // 释放掉blob对象

                this.exportExcelIng = false;
            })

        }
    },
    created() { },
    mounted() {
        if (!this.$route.query.unitCode) {
            this.$nextTick(() => {
                this.$message.error("没有发现机构参数");

            })
        } else {
            this.unitCode = this.$route.query.unitCode
        }

        if (this.$route.query.start) {
            this.start = this.$route.query.start
        }
        if (this.$route.query.end) {
            this.end = this.$route.query.end
        }

        if (!this.$route.query.msId) {
            this.$nextTick(() => {
                this.$message.error("没有发现评分表参数");
            })
        } else {
            this.msId = this.$route.query.msId
            this.getItemTree();
            this.getDataList();
        }
    }
};
</script>
<style lang="scss">
.wrapper {}

.title {
    display: flex;
    justify-content: space-between;

    span {
        text-align: center;
        padding: 10px;
        size: 24px;
    }
}

.table-label-tooltip {
    span {
        overflow: hidden;
        width: 100%;
        white-space: pre-wrap;
    }
}

.el-table th.el-table__cell>.cell {
    .el-tooltip__trigger {
        white-space: nowrap;
        overflow: hidden;
        display: block;
        text-overflow: ellipsis;
    }

    // white-space: pre-wrap; // 也行。
}
</style>
