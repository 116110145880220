<template>
  <div class="wrapper">

    <div class="top-bar">
      <span>直播间</span>
      <el-link type="primary">设置</el-link>
    </div>
    <div class="content">
      <div class="video-box">
        <rtcpuser :sig="sig" :isVideo="true" v-if="type == 0"></rtcpuser>
        <rtcplayer v-else :url="playUrl"></rtcplayer>

      </div>
      <div class="right">
        <div class="list">
          <div class="box">
            <div class="title">
              在线人员(2)
            </div>
            <ul style="height: 100px;">
              <li v-for="item in players">
                {{ display }}
              </li>
            </ul>
          </div>
          <div class="box">
            <div class="title">
              互动区
            </div>
            <ul style="height: calc(100vh - 500px);" ref="messageList">
              <li v-for="item in messages">
                {{ item.content }}
              </li>
            </ul>
          </div>
        </div>
        <div class="input-bar">
          <el-input v-model="sendMsgText" placeholder="请输入内容"></el-input>
          <el-button type="primary" @click="sendMsg">发送</el-button>
        </div>
        <div class="admin-video">
          <video ref="localVideo" class="pusher" controls autoplay style="width:100%;height:100%;"></video>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import rtcpuser from './rtcpuser.vue'
import rtcplayer from './rtcplayer.vue'
import Sig from './srs.sig'
export default {
  components: {
    rtcpuser,
    rtcplayer
  },
  props: {},
  data() {
    return {
      type: 0,
      messages: [],
      sendMsgText: '',
      sig: null,
      host: 'storm.osve.cn:9849',
      display: '1a1a1a2',
      players: [],
      playUrl: ''
    };
  },
  watch: {},
  computed: {
    ...mapGetters(['userInfo', 'rtcRoom'])
  },
  methods: {
    async init() {
      if (this.sig) {
        this.sig.close();
      }
      this.sig = new Sig.SrsRtcSignalingAsync();

      if (this.userInfo.U_TrueName == this.rtcRoom.Creater_UName) {
        this.type = 0;
      } else {
        this.type = 1;
        this.playUrl = `webrtc://storm.osve.cn:9849/${this.rtcRoom.Room_Number}/${this.userInfo.U_TrueName}`;
      }

      this.sig.onmessage = function (msg) {
        console.log('Notify: ', msg);
        // Subscribe if new user start to publish.
        if (msg.event === 'publish') {
          if (msg.peer && msg.peer.publishing && msg.peer.display !== display) {
            //this.startPlay(this.host, this.room, msg.peer.display);
          }
        }

        // Remove dead players.
        if (msg.event === 'join' || msg.event === 'leave') {
          console.log('onmessage', msg);
          // $.each(players, function (k, obj) {
          //     let stillAlive = false;
          //     msg.participants.forEach(function (participant) {
          //         if (participant.display === k) stillAlive = true;
          //     });

          //     if (!stillAlive) {
          //         obj.player.close();
          //         obj.ui.remove();
          //     }
          // });
        }
      };
      await this.sig.connect("wss", this.host, this.rtcRoom.Room_Number, this.userInfo.U_TrueName);
      console.log("connected");

      let r0 = await this.sig.send({ action: 'join', room: this.rtcRoom.Room_Number, display: this.userInfo.U_TrueName });
      console.log('Signaling: join ok', r0);
      if (r0) {
        this.players = r0.participants;
      }

    },
    sendMsg() {
      this.messages.push({
        content: this.sendMsgText
      });
      // this.sendMsgText='';
      this.$nextTick(() => {
        var list = this.$refs.messageList;
        list.scrollTop = list.scrollHeight;
      })
    }
  },
  created() {
    this.init();
  },
  mounted() {

  }
};
</script>
<style lang="scss" scoped>
.wrapper {
  background: #eee;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 5px;

  .top-bar {
    height: 50px;
    background: #fff;
    display: flex;
    align-items: center;
    padding: 0 10px;
    justify-content: space-between;
  }

  .content {
    display: flex;
    height: 100%;

    .video-box {
      flex: 1;
      height: calc(100vh - 50px)
    }

    .right {
      width: 300px;
      background: #fff;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0 5px;

      .top {
        height: 50px;
        display: flex;
        align-items: center;
        padding: 0 10px;
        justify-content: space-around;
      }

      .list {
        flex: 1;

        .box {
          .title {
            display: flex;
            justify-content: center;
            background: #1890ff;
            color: #fff;
            padding: 8px 0;

          }

          ul {
            overflow-y: auto;

            li {
              list-style: none;
            }
          }
        }
      }

      .input-bar {
        display: flex;
        margin-bottom: 10px;
      }
    }
  }
}
</style>