import request from './axiosApi'

request.defaults.baseURL = "/api"

export function getPageList(query){
    return request({
        url:'/ExaminerTraining',
        method:'post',
        data:query
    })
}

export function getMarkSheetItemTree(query){
    return request({
        url:'/ExaminerTraining/getMarkSheetItemTree',
        method:'post',
        data:query
    })
}

export function getExaminerTrainingList(query){
    return request({
        url:'/ExaminerTraining/getExaminerTrainingList',
        method:'post',
        data:query
    })
}

//