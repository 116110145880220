<template>
    <div>
        <el-dialog v-el-drag-dialog :title=title v-model="myVisible" append-to-body :close-on-click-modal="false"
            @closed="closeDialog" @open="openDialog" width="70vw" draggable>
            <el-table :data="tableList" border v-loading="dataLoading" @selection-change="onSelectionChange" height="50vh"
                stripe>
                <!-- <el-table-column type="selection" width="55" /> -->


                <el-table-column label="来源" prop="TaskName" :formatter="taskNameFormatter">
                </el-table-column>
                <el-table-column label="考生用户名" prop="Student_NO" width="150">
                </el-table-column>
                <el-table-column label="考生姓名" prop="Student_Name" width="130">
                </el-table-column>
                <el-table-column label="评分表" prop="MS_Name">
                </el-table-column>
                <el-table-column label="评委" prop="Rater_TrueName" width="100">
                </el-table-column>
                <el-table-column label="得分" prop="SI_Score" width="80" align="center">
                    <template #default="scope">
                        <el-link type="primary" @click="showScoreInfoDialog(scope.row)">{{ scope.row.SI_Score
                        }}</el-link>
                    </template>
                </el-table-column>

                <!-- <el-table-column label="开始时间" prop="StartTime" width="180" />
                <el-table-column label="结束时间" prop="EndTime" width="180" /> -->
                <el-table-column label="评分时间" prop="SI_CreateTime" width="180" />

            </el-table>
            <div class="avue-crud__pagination"
                style="display: flex;justify-content: space-between;padding:10px 10px 5px 10px;">
                <div></div>

                <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle"
                    :current-page="pageIndex" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage"
                    background layout="total, prev, pager, next, sizes">
                </el-pagination>
            </div>
        </el-dialog>
        <scoreInfoDialog v-model:visible="scoreInfoDialogVisible" :scoreId="selectedScoreId" :type="type"></scoreInfoDialog>
    </div>
</template>

<script>
import { getScoreInfoList as ateGetScoreInfoList } from '@/api/ateexam'
import { getScoreInfoList as GetScoreInfoList } from '@/api/exam'

import scoreInfoDialog from './scoreInfoDialog.vue'
export default {
    components: {
        scoreInfoDialog
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: ''
        },
        search: {
            type: Object,
            default: () => { }
        }
    },
    emits: [
        'update:visible'
    ],
    data() {
        return {
            myVisible: this.visible,
            title: "成绩列表",
            tableData: [],
            dataLoading: false,
            pageIndex: 1,
            pageSize: 20,
            totalPage: 0,
            scoreInfoDialogVisible: false,
            selectedScoreId: ''
        };
    },
    watch: {
        visible(val) {
            this.myVisible = val;
        }
    },
    computed: {},
    methods: {
        getList() {
            if (this.type == "ate") {
                this.dataLoading = true;
                ateGetScoreInfoList(Object.assign(
                    this.search,
                    {
                        pageSize: this.pageSize,
                        pageIndex: this.pageIndex
                    }
                )).then(res => {
                    this.dataLoading = false;
                    this.tableList = res.data.data
                    this.totalPage = res.data.total
                }).catch(res => {
                    this.dataLoading = false;
                });
            } else {
                this.dataLoading = true;
                GetScoreInfoList(Object.assign(
                    this.search,
                    {
                        pageSize: this.pageSize,
                        pageIndex: this.pageIndex
                    }
                )).then(res => {
                    this.dataLoading = false;
                    this.tableList = res.data.data
                    this.totalPage = res.data.total
                }).catch(res => {
                    this.dataLoading = false;
                });
            }
        },

        taskNameFormatter(row, column, cellValue, index) {
            return cellValue ? cellValue : '随时考';
        },
        showScoreInfoDialog(row) {
            this.selectedScoreId = row.ID;
            this.scoreInfoDialogVisible = true;
        },
        // 每页数
        sizeChangeHandle(val) {
            this.pageSize = val
            this.pageIndex = 1
            this.getList()
        },
        // 当前页
        currentChangeHandle(val) {
            this.pageIndex = val
            this.getList()
        },
        openDialog() {
            this.getList();
        },
        closeDialog() {
            this.myVisible = false;
            this.$emit('update:visible', false)
        }
    },
    created() { },
    mounted() { }
};
</script>
<style lang="scss" scoped>
.wrapper {}
</style>
