export  function GetExamTypeDetail(tempTable,isMatch) {
    var tempExam = tempTable;
    if (tempExam["E_Kind"] == "4") {
        return "自定义";    //自定义考试 added by lyl 20161123
    }
    if (tempExam["E_Kind"] == "3") { 
         if(isMatch == "1")
         {
          return "赛道式";
         }
         else
         {
          if (tempExam["float3"] == "8")
          {
              return "单站 - 响应式"
          }
          else{
              return "单站式"
          }
         }
        /*if (tempExam["float3"] == "7")
            //return "单站 - 考题"
            return "单站式"
        if (tempExam["float3"] == "8")
            return "单站 - 响应式"
        else                        
            //return "单站式";
            {
              return isMatch == "1" ? "赛道式" : "单站式";
            }*/
           
    }
    else if (tempExam["E_Kind"] == "2") {
        if (tempExam["float3"] == "1")
            //return "多站 - 轮循"
            return isMatch == "1" ? "多站 - 轮循" : "多站式";
        else if (tempExam["float3"] == "2")
            return "多站 - 队列";
        else if (tempExam["float3"] == "10")
            return "自动分配响应式";
        else if (tempExam["float3"] == "11")
            return "队列响应式";
        else if (tempExam["float3"] == "12")
            return "随签随考响应式";
    }
    else if (tempExam["E_Kind"] == "1") {
        if (tempExam["float3"] == "3")
            return "长短站 - 8人制"
        else if (tempExam["float3"] == "4")
            return "长短站 - 16人制";
        else if (tempExam["float3"] == "5")
            return "长短站 - 4人制";
        else if (tempExam["float3"] == "15")
            return "长短站 - 全类型";
        else if (tempExam["float3"] == "16")
            return "执医 （临床）";
        else if (tempExam["float3"] == "17")
            return "执医 （中医）";
        else if (tempExam["float3"] == "18")
            return "执医 （口腔）";
  
    }
  }