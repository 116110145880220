export const calcDate = (date1, date2) => {
  var date3 = date2 - date1

  var days = Math.floor(date3 / (24 * 3600 * 1000))

  var leave1 = date3 % (24 * 3600 * 1000) // 计算天数后剩余的毫秒数
  var hours = Math.floor(leave1 / (3600 * 1000))

  var leave2 = leave1 % (3600 * 1000) // 计算小时数后剩余的毫秒数
  var minutes = Math.floor(leave2 / (60 * 1000))

  var leave3 = leave2 % (60 * 1000) // 计算分钟数后剩余的毫秒数
  var seconds = Math.round(date3 / 1000)
  return {
    leave1,
    leave2,
    leave3,
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds
  }
}

/**
 * 日期格式化
 */
export function dateFormat(date) {
  let format = 'yyyy-MM-dd hh:mm:ss'
  if (date !== 'Invalid Date') {
    var o = {
      'M+': date.getMonth() + 1, // month
      'd+': date.getDate(), // day
      'h+': date.getHours(), // hour
      'm+': date.getMinutes(), // minute
      's+': date.getSeconds(), // second
      'q+': Math.floor((date.getMonth() + 3) / 3), // quarter
      'S': date.getMilliseconds() // millisecond
    }
    if (/(y+)/.test(format)) {
      format = format.replace(RegExp.$1,
        (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    for (var k in o) {
      if (new RegExp('(' + k + ')').test(format)) {
        format = format.replace(RegExp.$1,
          RegExp.$1.length === 1 ? o[k]
            : ('00' + o[k]).substr(('' + o[k]).length))
      }
    }
    return format
  }
  return ''
}

export function dateFormat2(date) {
  const year = date.getFullYear().toString().padStart(4, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hour = date.getHours().toString().padStart(2, '0');
  const minute = date.getMinutes().toString().padStart(2, '0');
  const second = date.getSeconds().toString().padStart(2, '0');
  console.log(`${year}-${month}-${day} ${hour}:${minute}:${second}`); // 2023-02-16 08:25:05
  return `${year}-${month}-${day} ${hour}:${minute}:${second}`
}

export function getNowDate() {
  let timeOne = new Date();
  let year = timeOne.getFullYear();
  let month = timeOne.getMonth() + 1;
  let day = timeOne.getDate();
  month = month < 10 ? "0" + month : month;
  day = day < 10 ? "0" + day : day;
  const NOW_MONTHS_AGO = `${year}-${month}-${day}`;
  return NOW_MONTHS_AGO;
}

export function getDateFromDays(days) {
  var today = new Date();  // 获取当前日期
  var sevenDaysAgo = new Date(today.getTime() - days * 24 * 60 * 60 * 1000);  // 通过减去几天天的毫秒数来获取几天前的日期

  // 获取年、月、日
  var year = sevenDaysAgo.getFullYear();
  var month = (sevenDaysAgo.getMonth() + 1).toString().padStart(2, '0');  // 月份从0开始计数，所以需要加1，然后使用padStart方法在不满两位数时补0
  var day = sevenDaysAgo.getDate().toString().padStart(2, '0');

  var sevenDaysAgoDate = year + '-' + month + '-' + day;
  console.log(sevenDaysAgoDate);
}