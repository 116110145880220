import request from './axiosApi'
request.defaults.baseURL = "/api"
export function getList(page) {
    return request({
        url: '/ateexam/list',
        method: 'post',
        data: page
    })
}
export function getAllList(unitCode) {
    return request({
        url: '/ateexam/list?unitCode=' + unitCode,
        method: 'get'
    })
}

export function getScoreInfoList(query) {
    return request({
        url: '/ateexam/scoreInfoList',
        method: 'post',
        data: query
    })
}
export function getScoreInfo(id) {
    return request({
        url: '/ateexam/scoreInfo?id=' + id,
        method: 'get'
    })
}

export function getMarkSheetList(query){
    return request({
        url: '/ateexam/markSheetList',
        method: 'post',
        data: query
    }) 
}
export function getRaterList(query){
    return request({
        url: '/ateexam/raterList',
        method: 'post',
        data: query
    }) 
}

const mimeMap = {
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    xls:'application/vnd.ms-excel',
    zip: 'application/zip'
}

const downloadXls = function (res, fileName) {
    const link = document.createElement('a');  // 创建元素
    console.log("获取的数据============》", res);
    let blob = new Blob([res.data], { type: mimeMap });
    link.style.display = 'none';
    link.href = URL.createObjectURL(blob);   // 创建下载的链接
    link.setAttribute('download', fileName);  // 给下载后的文件命名
    document.body.appendChild(link);
    link.click();  // 点击下载
    document.body.removeChild(link);  //  下载完成移除元素
    window.URL.revokeObjectURL(link.href);  // 释放掉blob对象
}

export function getStationStatic(query, callback) {
    if (query.toExcel) {
        request({
            url: '/ateexam/stationStatistics',
            method: 'post',
            data: query,
            responseType: 'blob'
        }).then(res => {
            downloadXls(res, "考站统计.xls");
            callback(res);
        }).catch(err => {
            callback(err);
        })
    } else {
        return request({
            url: '/ateexam/stationStatistics',
            method: 'post',
            data: query
        })
    }

}



export function getRaterStatistics(query, callback) {
    if (query.toExcel) {
        request({
            url: '/ateexam/raterStatistics',
            method: 'post',
            data: query,
            responseType: 'blob'
        }).then(res => {
            downloadXls(res, "评委统计.xls");
            callback(res);
        }).catch(err => {
            callback(err);
        })
    }
    else
        return request({
            url: '/ateexam/raterStatistics',
            method: 'post',
            data: query
        })
}



export function getRaterDistributionStatistics(query) {
    return request({
        url: '/ateexam/raterDistributionStatistics',
        method: 'post',
        data: query
    })
}

export function getMarkSheetStatic(query, callback) {
    if (query.toExcel) {
        request({
            url: '/ateexam/markSheetStatistics',
            method: 'post',
            data: query,
            responseType: 'blob'
        }).then(res => {
            downloadXls(res, "评分表统计.xls");
            callback(res);
        }).catch(err => {
            callback(err);
        })
    }
    else
        return request({
            url: '/ateexam/markSheetStatistics',
            method: 'post',
            data: query
        })
}
export function getMarkSheetDistributionStatistics(query) {
    return request({
        url: '/ateexam/markSheetDistributionStatistics',
        method: 'post',
        data: query
    })
}


export function getClassStatistics(query, callback) {
    if (query.toExcel) {
        request({
            url: '/ateexam/classStatistics',
            method: 'post',
            data: query,
            responseType: 'blob'
        }).then(res => {
            downloadXls(res, "班级评分统计.xls");
            callback(res);
        }).catch(err => {
            callback(err);
        })
    }
    else
        return request({
            url: '/ateexam/classStatistics',
            method: 'post',
            data: query
        })
}

export function getClassDistributionStatistics(query) {
    return request({
        url: '/ateexam/classDistributionStatistics',
        method: 'post',
        data: query
    })
}




export function getDistributionStatistics(query) {
    return request({
        url: '/ateexam/distributionStatistics',
        method: 'post',
        data: query
    })
}

export function getStudentStatistics(query, callback) {
    if (query.toExcel) {
        request({
            url: '/ateexam/studentStatistics',
            method: 'post',
            data: query,
            responseType: 'blob'
        }).then(res => {
            downloadXls(res, "学生成绩统计.xls");
            callback(res);
        }).catch(err => {
            callback(err);
        })
    }
    else
        return request({
            url: '/ateexam/studentStatistics',
            method: 'post',
            data: query
        })
}

export function getLossRateStatistics(query, callback) {

    if (query.toExcel) {
        request({
            url: '/ateexam/lossRateStatistics',
            method: 'post',
            data: query,
            responseType: 'blob'
        }).then(res => {
            downloadXls(res, "得失分率统计.xls");
            callback(res);
        }).catch(err => {
            callback(err);
        })
    }
    else
        return request({
            url: '/ateexam/lossRateStatistics',
            method: 'post',
            data: query
        })
}


export function getLossRateStatisticsExport(query,callback){
    request({
        url: '/ateexam/lossRateStatisticsExport',
        method: 'post',
        data: query,
        responseType: 'blob'
    }).then(res => {
        downloadXls(res, "学生失分率对比统计.xls");
        callback(res);
    }).catch(err => {
        callback(err);
    })
}

