import request from './axiosApi'
request.defaults.baseURL = "/api"
export function getRoomList(page) {
    return request({
        url: '/rtc/room',
        method: 'get',
        data: page
    })
}

export function addRoom(data) {
    return request({
        url: '/rtc/room',
        method: 'post',
        data: data
    })
}
export function updateRoom(data) {
    return request({
        url: '/rtc/room',
        method: 'put',
        data: data
    })
}
export function delRoom(id) {
    return request({
        url: '/rtc/room/' + id,
        method: 'delete'
    })
}
export function getSetting() {
    return request({
        url: '/rtc/room/setting/',
        method: 'get'
    })
}

export function addRoomUser(data){
    return request({
        url: '/rtc/room/users',
        method: 'post',
        data: data
    })
}

export function getRoomUsers(id){
    return request({
        url: '/rtc/room/users/' + id,
        method: 'get'
    })
}

export function getToken(channelName, account, type) {
    return request({
        url: '/rtc/token',
        method: 'get',
        params: {
            channelName: channelName,
            account: account,
            type: type ? type : 'rtc'
        }
    })
}

export function startRecording(data) {
    return request({
        url: '/rtc/recoreding/start',
        method: 'post',
        data: data
    })
}

export function stopRecording(data) {
    return request({
        url: '/rtc/recoreding/stop',
        method: 'post',
        data: data
    })
}
export function queryRecording(data) {
    return request({
        url: '/rtc/recoreding/query',
        method: 'post',
        data: data
    })
}

export function getRecordingFiles(cname) {
    return request({
        url: '/rtc/recording/files/' + cname,
        method: 'get'
    })
}

export function getRecordingFileUrl(fileName) {
    return request({
        url: '/rtc/recording/fileUrl',
        method: 'get',
        params: {
            fileName: fileName
        }
    })
}

export function getUserInfo(id) {
    return request({
        url: '/rtc/user/' + id,
        method: 'get'
    })
}

export function queryUserByName(name) {
    return request({
        url: '/rtc/user/query/' + name,
        method: 'get'
    })
}