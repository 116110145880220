<template>
    <el-dialog v-el-drag-dialog title="考核任务选择" v-model="myVisible" append-to-body :close-on-click-modal="false"
        @closed="closeDialog" @open="openDialog" draggable>
        <div class="table-page-search-wrapper">
            <div class="inlineblo">
                <div>任务名称:</div>
                <div>
                    <el-input v-model="query.examName" placeholder="任务名称" clearable></el-input>
                </div>
                <!-- <div>&nbsp;排考类型:</div>
                <div>
                    <el-checkbox-group v-model="query.examKind" label="label">
                        <el-checkbox :label="item.value" v-for="item in plainOptions" checked>
                            {{ item.label }}
                        </el-checkbox>
                    </el-checkbox-group>
                </div> -->


                <div>&nbsp;考试时间:</div>
                <div>
                    <!-- <el-radio-group v-model="query.examDatetime">
                        <el-radio :label="0"> 不限 </el-radio>
                        <el-radio :label="7"> 一周之内 </el-radio>
                        <el-radio :label="30"> 一个月之内 </el-radio>
                        <el-radio :label="90"> 三个月之内 </el-radio>
                        <el-radio :label="180"> 半年之内 </el-radio>
                        <el-radio :label="365"> 一年之内 </el-radio>
                    </el-radio-group> -->
                    <el-date-picker v-model="searchDates" type="datetimerange" unlink-panels range-separator="至"
                        start-placeholder="开始日期" end-placeholder="结束日期">
                    </el-date-picker>
                </div>


                <div>
                    <el-button @click="searchList" type="primary" :icon="Search">搜索</el-button>
                    <el-button @click="clearSearch" :icon="Search">清空</el-button>
                </div>

                <div>

                    <!-- <el-switch v-model="ifSelectWPK" class="ml-2" width="60" inline-prompt active-text="选择未排考任务"
    inactive-text="选择未排考任务" /> -->
                    &nbsp;
                    <el-checkbox v-model="ifSelectWPK">包含随时考</el-checkbox>

                </div>
            </div>
        </div>
        <el-table :data="tableList" border v-loading="dataLoading" @selection-change="onSelectionChange" height="500">
            <el-table-column type="selection" width="55" />

            <!-- <el-table-column label="序号" prop="">
            <template slot-scope="text, record, index">
              <span>
                {{ (query.pageIndex - 1) * query.pageSize + Number(index) + 1 }}
              </span>
            </template>
          </el-table-column> -->
            <!-- <el-table-column label="排考类型" prop="E_Kind">
                <template #default="scope">
                    {{ GetExamTypeDetail(scope.row) }}
                </template>
            </el-table-column> -->

            <el-table-column label="考试名称" prop="TaskName">
            </el-table-column>
            <el-table-column label="考生人数" prop="StudentCount">
            </el-table-column>

            <!-- <el-table-column label="考试类型" prop="Exam_Class_Name">
            </el-table-column> -->
            <el-table-column label="开始时间" prop="StartTime" />
            <el-table-column label="结束时间" prop="EndTime" />
            <!-- <el-table-column label="评分时间" prop="CreateDate" /> -->
        </el-table>
        <div class="avue-crud__pagination" style="display: flex;justify-content: space-between;padding:10px 10px 5px 10px;">
            <el-button type="primary" @click="onSelect">确定</el-button>

            <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex"
                :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage" background
                layout="total, prev, pager, next, sizes">
            </el-pagination>
        </div>
    </el-dialog>
</template>

<script>
import { toRaw } from '@vue/reactivity'
import { getNowDate } from '@/unit/date.js'
import {
    getList as getExamList
} from "@/api/ateexam"
export default {
    components: {},
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        unitCode: {
            type: String
        }
    },
    emits: [
        'update:visible'
    ],
    data() {
        return {
            myVisible: this.visible,
            dataLoading: false,
            pageIndex: 1,
            pageSize: 20,
            totalPage: 0,
            tableList: [],
            total: 0,
            // ExamKindMap: [
            //     "长短站",
            //     "多站式",
            //     "单站式",
            //     "自定义", // added by lyl on 20161216
            // ],
            zIndex: 999,
            current: 1,
            inline: true,
            checkedList: [],
            // plainOptions: [
            //     { label: "单站考试", value: 3 },
            //     { label: "多站考试", value: 2 },
            //     { label: "长短站考试", value: 1 },
            //     { label: "响应式考试", value: 4 },
            // ],
            query: {
                examSearchType: "examCondition",
                examDatetime: 0,
                examName: "",
                examClass: "",
                examKind: [3, 2, 1, 4],
                examStudentUserInfoName: "",
                examStudentNumber: "",
                examStudentUserInfoTrueName: "",
                pageIndex: 1,
                pageSize: 10,
                sortField: "E_StartTime",
                sortOrder: "desc",
                IsMatch: 0,
            },
            multipleSelection: [],
            searchDates: [],
            ifSelectWPK: true, //未排考
            start: '',
            end: ''
        };
    },
    watch: {
        visible(val) {
            this.myVisible = val;
        },
        'searchDates': function (n, o) {
            //console.log("searchDates", n, o)
            if (n && n.length > 1) {
                this.start = n[0].Format("yyyy-MM-dd HH:mm:ss")
                this.end = n[1].Format("yyyy-MM-dd HH:mm:ss")
                this.examDatetime = -1;
            } else {
                this.start = "";
                this.end = "";
            }
            this.pageIndex = 1;
            this.getlist();
        }
    },
    computed: {},
    methods: {
        getlist() {
            this.dataLoading = true;
            var search = {
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
                start: this.start,
                end: this.end,
                // plainTypes: toRaw(this.query.examKind),
                // examDatetime: toRaw(this.query.examDatetime),
                examName: toRaw(this.query.examName),
                unitCode: this.unitCode
            };

            getExamList(search).then(res => {
                this.dataLoading = false;
                this.tableList = res.data.data
                this.totalPage = res.data.total
            })
            // request(
            //   "/SortExam/PrintResult/SearchScore1.aspx/SearchExamTable",
            //   { ...this.query, examKind: this.query.examKind.toString() },
            //   "matchList"
            // ).then((res) => {
            //   that.list = res.tableData.examTableList;
            //   that.total = parseInt(res.tableData.examTableCount);
            // });
        },
        onSelectionChange(val) {
            this.multipleSelection = val;
        },
        onSelect() {
            if (this.ifSelectWPK) {
                if (this.start && this.end) {
                    this.multipleSelection.push({
                        TaskID: '00000000-0000-0000-0000-000000000000',
                        TaskName: '未排考'
                    })
                } else {
                    this.$message.error("随时考数据太多，请选择考试时间。")
                    return;
                }

            }

            if (this.multipleSelection.length <= 0) {
                this.$message.error("至少选择一场考试")
                return
            }

            this.$emit("selected", this.multipleSelection, this.start, this.end)

            this.myVisible = false;
            this.$emit("update:visible", this.myVisible)

            this.$emit('cancel', false)


        },
        clearSearch() {
            this.query.examName = '';

            this.start = '';
            this.end = '';
            this.searchDates = [

            ]
            // this.getlist(); //不用在这调用 getlist 修改 searchDates 时就调用了。
        },
        searchList() {
            this.pageIndex = 1;
            this.getlist();
        },
        // 每页数
        sizeChangeHandle(val) {
            this.pageSize = val
            this.pageIndex = 1
            this.getlist()
        },
        // 当前页
        currentChangeHandle(val) {
            this.pageIndex = val
            this.getlist()
        },
        openDialog() {
            if (this.tableList.length <= 0) {
                var today = new Date();
                today.setHours(23);
                today.setMinutes(59);
                today.setSeconds(59);
                this.searchDates = [
                    new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000 + 1000),// 获取一周之前的日期), 
                    today
                ]

                this.multipleSelection = [];
                // this.getlist();
            }
        },
        closeDialog() {
            this.myVisible = false;
            this.$emit('update:visible', false)
        }
    },
    created() { },
    mounted() {


    }
};
</script>
<style lang="scss" scoped>
.wrapper {}
</style>