<template>
    <div class="wrapper" v-loading="dataLoading">
        <div class="search_bar">
            <span>直播间名称：</span> <el-input v-model="searchForm.roomName" style="width:400px"></el-input>
            <el-radio-group v-model="searchForm.roomType" style="margin-left:10px">
                <el-radio :label="1">直播中</el-radio>
                <el-radio :label="2">回放</el-radio>
                <el-radio :label="0">全部</el-radio>
            </el-radio-group>

            <el-button type="primary" @click="search" style="margin-left:10px">查询</el-button>
            <el-button type="primary" @click="createRoomDialog = true">创建直播</el-button>
        </div>
        <div>
            <h2>我的房间</h2>
        </div>
        <div class="room_list">
            <template v-for="item in roomList">
                <div class="room_panel" v-if="userInfo.U_ID == item.Creater_UID">
                    <span class="date">{{ item.Create_Date }}</span>
                    <span class="room_name">{{ item.Room_Name }}</span>
                    <span class="room_type">{{ item.Creater_UName }}</span>
                    <el-dropdown @command="(com) => {
                        goToRoom(item, com);
                    }" v-model="cameraId">
                        <el-button type="primary">
                            进入直播间<el-icon class="ArrowDown" style="margin-left: 5px;"><arrow-down /></el-icon>
                        </el-button>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item command="host_usb">主摄像头</el-dropdown-item>
                                <el-dropdown-item command="host_phone">手机摄像</el-dropdown-item>
                                <el-dropdown-item command="host_distop">电脑投屏</el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </div>
            </template>

        </div>
        <div>
            <h2>其他房间</h2>
        </div>
        <div class="room_list">
            <template v-for="item in roomList">
                <div class="room_panel" v-if="userInfo.U_ID != item.Creater_UID">
                    <span class="date">{{ item.Create_Date }}</span>
                    <span class="room_name">{{ item.Room_Name }}</span>
                    <span class="room_type">{{ item.Creater_UName }}</span>
                    <el-button type="primary" @click="goToRoom(item, 'audience')">进入直播间</el-button>
                </div>
            </template>

        </div>
    </div>
    <el-dialog v-model="createRoomDialog" width="30%" title="创建直播间" destroy-on-close center>
        <div>
            <el-input placeholder="输入直播间名称" v-model="newRoomName"></el-input>
        </div>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="createRoomDialog = false">取消</el-button>
                <el-button type="primary" @click="add">
                    创建
                </el-button>
            </span>
        </template>
    </el-dialog>
</template>
<script setup>
import {
    Check,
    Delete,
    Edit,
    Message,
    Search,
    Download,
    FullScreen,
    Minus,
    ArrowDown
} from '@element-plus/icons-vue'
</script>
<script>
import { mapGetters } from 'vuex'

import { getRoomList, addRoom, updateRoom, delRoom } from "@/api/rtcRoom";
export default {
    components: {},
    props: {},
    data() {
        return {
            searchForm: {
                roomType: 0,
                roomName: ''
            },
            createRoomDialog: false,
            roomList: [],
            newRoomName: '',
            uid: '',
            uname: '',
            dataLoading: false,
            cameraId: 0
        };
    },
    watch: {},
    computed: {
        ...mapGetters(['userInfo'])
    },
    methods: {
        getList() {

            this.dataLoading = true;
            getRoomList().then(ret => {
                this.dataLoading = false;
                this.roomList = [];
                this.roomList = ret.data.data;
            })
        },
        add() {
            if (!this.newRoomName) {
                this.$message.error('直播间名称不能为空')
                return;
            }
            addRoom({
                Room_Name: this.newRoomName,
                Room_Number: `${this.userInfo.U_ID}${Number(parseInt(new Date().getTime() * Math.random() * 100)).toString(16).toString(16)}`,
                Creater_UID: this.userInfo.U_ID,
                Creater_UName: this.userInfo.U_TrueName,
                Status: 1,
            }).then(ret => {
                if (ret.data.status == 200) {
                    this.$store.dispatch('SetRtcRoom', ret.data.data);
                    this.$message.success('创建成功');
                    this.createRoomDialog = false;
                    this.getList();
                } else {
                    this.$message.error(ret.data.msg);
                }
            })

        },
        // dropdownClick(command) {
        //     console.log('dropdownClick',command)
        //     this.$store.dispatch('SetRtcRoom', command);
        //     this.$router.push({
        //         path: 'room_sw',
        //         params: {
        //             cameraId:command
        //         }
        //     });
        // },
        goToRoom(data, command) {
            this.$store.dispatch('SetRtcRoom', data);
            this.$router.push({
                path: 'room_sw?type=' + command,
                query: {
                    roomId: data.ID,
                    type: command,
                    channel: data.Room_Number,
                    // role: this.userInfo.U_TrueName == data.Creater_UName ?"host":"audience",
                    uid: this.userInfo.U_ID,
                    channelName: data.Room_Name,
                    uname: this.userInfo.U_TrueName,
                }
            });
        }
    },
    created() {
        if (!this.userInfo.U_ID) {
            this.$store.dispatch("GetUserInfo").then(res => {
                if (!res) {
                    //返回上一页
                    this.$router.go(-1);
                }
            });
        }
    },
    mounted() {
        // //获取uid参数
        // this.uid = this.$route.query.uid;
        // //获取uname参数
        // this.uname = this.$route.query.uname;


        this.$nextTick(() => {
            this.getList();
        })
    }
};
</script>
<style lang="scss" scoped>
.wrapper {
    .search_bar {
        display: flex;

    }

    .room_list {
        display: flex;
        flex-wrap: wrap;

        .room_panel {
            width: 200px;
            border-radius: 15px;
            padding: 15px;
            background: #eee;
            display: flex;
            flex-direction: column;
            margin-right: 10px;
            margin-bottom: 10px;
            align-items: center;
            position: relative;


            .date {
                position: absolute;
                top: 10px;
                left: 10px;
                font-size: 12px;
                color: #1890ff;
            }

            .room_type {
                position: absolute;
                top: 10px;
                right: 10px;
                font-size: 12px;
                color: #1890ff;
            }

            .room_name {
                font-size: 16px;
                padding: 20px 0;
                margin-top: 15px;
            }
        }
    }

}
</style>