<template>
  <div class="wrapper">

    <div class="top-bar">


      <span> <el-link type="primary" :underline="false" @click="exit"><el-icon>
            <DArrowLeft />
          </el-icon>退出房间</el-link> &nbsp;&nbsp;&nbsp;&nbsp; 房间：{{ channelName }}</span>

      <div class="mic-status">
        <!-- <el-popover :width="310" placement="top-start" title="Title" :width="200" trigger="hover"
          content="this is content, this is content, this is content">
          <template #reference>
            <el-button class="m-2" circle> <el-icon v-if="!isMute">
                <Microphone />
              </el-icon>
              <el-icon v-else>
                <Mute />
              </el-icon></el-button>
          </template>
          <el-slider v-model="volume" @change="volumeChange" style="margin-left: 10px;" />
        </el-popover> -->

        <el-popover :width="300">
          <template #reference>
            <el-button class="m-2" circle @click="onChangeMute" :type="isMute ? 'danger' : 'success'"> <el-icon
                v-if="!isMute">
                <Microphone />
              </el-icon>
              <el-icon v-else>
                <Mute />
              </el-icon></el-button>
          </template>
          <div>
            Mic音量：{{ volume }} <el-slider v-model="volume" @change="volumeChange" />
          </div>

        </el-popover>


      </div>
      <div>
        <template v-if="type == 'host_usb'">
          <el-button type="primary" @click="startRecord" v-if="!isRecording">开始录制</el-button>
          <el-button type="primary" plain @click="stopRecord" v-else>停止录制</el-button>
        </template>
        <el-popover :width="300" trigger="click" @before-enter="filesMenuVisible">
          <template #reference>
            <el-button style="margin-right: 16px">查看已录文件</el-button>
          </template>
          <div v-loading="filesLoading">
            <el-menu>
              <el-menu-item v-for="item, index in recordingFiles" :index="index" @click="onFileClick(item)">
                <!-- <el-icon>
                  <Film />
                </el-icon> -->
                {{ index + 1 }} :
                {{ getDateFormat(item.putTime) }}</el-menu-item>
            </el-menu>
          </div>

        </el-popover>
      </div>


      <el-link type="primary" @click="settingDialogVisible = true" :underline="false"><el-icon>
          <Tools />
        </el-icon>设置</el-link>
    </div>
    <div class="content">
      <div class="video-box" style="display: flex;justify-content: space-between;position: relative;">
        <!-- <h1>直播还未开始</h1> -->
        <div ref="mainVideo"
          style="height: calc(100% - 5px);  background: #fff; margin: 5px 5px 5px 0;position: relative;flex:2;">
          <h2 style="position: absolute;top:50%;left: 50%;transform:translate(-50%,-50%);">主摄像头未开启</h2>
        </div>

        <div style="flex: 1;height: 100%;">
          <div ref="phoneVideo"
            style="height: calc(70% - 5px); background: #fff; margin: 5px 5px 5px 0;position: relative;">
            <div style="position: absolute;top:50%;left: 50%;transform:translate(-50%,-50%);">
              <vue-qr :text="phoneUrl" :size="200" v-if="role.startsWith('host')"></vue-qr>
              <h2 v-if="role.startsWith('host')">扫码开启手机摄像头</h2>
              <h2 v-else>手机摄像头未开启</h2>
            </div>
          </div>
          <div ref="distopVideo"
            style="height: calc(30% - 5px);  background: #fff; margin: 5px 5px 5px 0;position: relative;">
            <div style="position: absolute;top:50%;left: 50%;transform:translate(-50%,-50%);">
              <el-button @click="startScreenCall" v-if="role.startsWith('host')" type="primary">开启屏幕共享</el-button>
              <h2 v-else>屏幕共享未开启</h2>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="list">
          <div class="box">
            <div class="title">
              在线人员
            </div>
            <div class="user-list">
              <div v-for="item in rtmUsers" class="user">
                <userVolume v-model="item.volume" v-if="item.status == 1"></userVolume>
                <span :class="item.status == 0 ? 'outline' : 'online'">{{ item.userName || item.userId }} </span>

                <!-- <span v-if="item.status == 0" class="outline">离线</span>
                <span v-if="item.status == 1" class="online">在线</span> -->
              </div>
            </div>
          </div>
          <div class="box">
            <div class="title">
              互动区
            </div>
            <div style="height: calc(100vh - 410px);overflow: auto;" ref="messageList" class="message-list">
              <div v-for="item in messages" :class="item.type">
                <div v-if="item.type == 'info'" class="info">
                  <span class="date">{{ item.date.Format("HH:mm:ss") }}</span> -
                  <span class="msg">{{ item.message }}</span>
                </div>
                <div v-else class="message">
                  <div class="user">
                    <el-icon class="hicon">
                      <UserFilled />
                    </el-icon>
                    <div class="inf">
                      <span :class="'name' + (userId == item.user ? ' my' : '')">{{
                        rtmUsers[item.user] ? rtmUsers[item.user].userName : item.user
                      }}</span>
                      <span class="date">{{
                        item.date.Format("HH:mm:ss") }}</span>
                    </div>
                  </div>

                  <div class="msg" v-if="item.type == 'text'" v-html="item.message"></div>
                  <span class="emo" v-if="item.type == 'emo'"></span>

                  <span class="emo" v-if="item.type == 'rate'">
                    <el-rate v-model="item.message" disabled />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="input-bar">
          <div class="btns">
            <!-- <el-input v-model="sendMsgText" placeholder="请输入内容" @keyup.enter="sendMsg"></el-input> -->
            <div class="icons">
              <el-popover placement="top" :width="400" trigger="hover" @before-enter="emojiMenuVisible">
                <template #reference>
                  <div class="emoji_btn">
                    😀
                  </div>
                </template>
                <template #default>
                  <div class="emoji_list" style="display: flex;flex-wrap: wrap;">
                    <div class="emoji" v-for="item in emojiList" @click="onEmojiClick(item)"
                      style="cursor: pointer;display: flex;flex-direction: column;justify-content: center; width: 30px;margin-right: 8px;margin-bottom: 10px;">
                      <span style="text-align: center;">{{ item.value }}</span>
                      <span style="text-align: center;">{{ item.name }}</span>
                    </div>
                  </div>
                </template>
              </el-popover>
              <el-popover placement="top" trigger="hover">
                <template #reference>
                  <el-icon>
                    <Star />
                  </el-icon>
                </template>
                <template #default>
                  <el-rate v-model="rateValue" allow-half @change="rateChange" />
                </template>
              </el-popover>
              <el-icon>
                <ChatLineRound />
              </el-icon>

              <el-icon>
                <Position />
              </el-icon>
            </div>
            <el-popover trigger="hover" :width="160" placement="top" title="" content="快捷键:Ctrl+Enter">
              <template #reference>
                <el-button type="primary" @click="sendMsg">发送</el-button>
              </template>

            </el-popover>

          </div>
          <div class="input_border">
            <!-- <div class="input_div" ref="inputDiv" contenteditable ondragenter="return false" oncontextmenu="return false;"
              style="ime-mode:disabled" v-html="sendMsgText" placeholder="在此输入" @keydown="messageTextKeyDown"
              @blur="messageTextBlur">
            </div> -->
            <el-input ref="msgInput" v-model="sendMsgText" :autosize="{ minRows: 5, maxRows: 5 }" type="textarea"
              placeholder="请输入要发送的内容" @keydown="messageTextKeyDown" :maxlength="300" />
          </div>

        </div>
        <!-- <div class="admin-video">
          <video ref="localVideo" class="pusher" controls autoplay style="width:100%;height:100%;"></video>
        </div> -->
      </div>
    </div>

    <el-dialog title="设置" v-model="settingDialogVisible" style="width: 400px;">

      <div>
        <el-form label-width="80px">
          <el-form-item label="摄像头">
            <el-select v-model="selectedCameraId" placeholder="请选择" @change="onCameraChange">
              <el-option v-for="item in cameras" :key="item.deviceId" :label="item.label"
                :value="item.deviceId"></el-option>
            </el-select>
          </el-form-item>


          <el-form-item label="麦克风">
            <el-select v-model="selectedMicrophoneId" placeholder="请选择" @change="onMicrophoneChange">
              <el-option v-for="item in microphones" :key="item.deviceId" :label="item.label"
                :value="item.deviceId"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <!-- <span>{{ playingM3u8 }}</span> -->

      <span slot="footer" class="dialog-footer">
        <el-button @click="settingDialogVisible = false">关 闭</el-button>
        <!-- <el-button type="primary" @click="dialogVisible = false">确 定</el-button> -->
      </span>
    </el-dialog>

    <el-dialog title="播放" v-model="dialogVisible" style="width: 70%;" @opened="onVideoOpened">

      <div style="height: 50vh">

        <!-- <video-player class="video-player vjs-custom-skin" ref="videoPlayer" :src="playingM3u8"></video-player> -->
        <!-- 
        <div id="video-box" class="video-item" v-show="showFlag">
          <video id="my-video" class="video-js vjs-default-skin" controls preload="auto" style="width: 100%;height:100%">
            <source id="source" :src="playingM3u8" type="application/x-mpegURL" />
          </video>
        </div> -->

        <!-- <iframe :src="playingM3u8" width="100%" height="100%"></iframe> -->
      </div>
      <!-- <span>{{ playingM3u8 }}</span> -->

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script setup>
import {
  DArrowLeft,
  Microphone,
  Mute,
  VideoCamera,
  Monitor,
  Film,
  Tools,
  Refresh,
  UserFilled,
  ChatDotRound,
  ChatLineRound,
  Star,
  Position

} from '@element-plus/icons-vue'
</script>
<script>
// import { mapGetters } from 'vuex'
import AgoraRTC from 'agora-rtc-sdk-ng'
import AgoraRTM from 'agora-rtm';

import {
  getSetting, getToken, startRecording, stopRecording, queryRecording, getRecordingFiles,
  getRecordingFileUrl, addRoomUser, getRoomUsers
} from '@/api/rtcRoom'

import vueQr from 'vue-qr/src/packages/vue-qr.vue'

import userVolume from './userVolume.vue'

// import AgoraVideoPlayer from '@/components/AgoraVideoPlayer/index.vue'
const videoClient = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });
const screenCient = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });

// const rtmClient = AgoraRTM.createInstance('685266397091469220', { logFilter: 'warn' });
const { RTM } = AgoraRTM;
const rtmClient = {
  client: null
}

// import videojs from 'video.js';
// import 'videojs-contrib-hls';
// import 'video.js/dist/video-js.css';
// import { VideoPlayer } from '@videojs-player/vue'


export default {
  components: {
    userVolume,
    // VideoPlayer
    vueQr
  },
  props: {},
  data() {
    return {
      roomId: null,
      type: "", //当前页面的直播类型 host_usb、host_phone、host_distop
      role: 'host', //权限
      players: [], //所有参与人
      channelName: "",
      userId: null, //当前用户的userId
      userName: "", //当前用户的userName
      //rtc的设置
      options: {
        appId: "3ce74dfa23874a94afd3ecf3eaa8ac37",
        channel: "",
        token: "",
        uid: null,
        screenUid: null,
        screenToken: "",
        rtmChannel: ""
      },
      domain: "http://cloudexam.osve.cn/", //oss 的URL
      // videoClient: null,
      volume: 50,
      isMute: false,

      settingDialogVisible: false,

      audioTrack: { value: null },
      videoTrack: { value: null },
      isScreenShow: false,
      cameras: [],
      selectedCameraId: null,
      microphones: [],
      selectedMicrophoneId: null,
      recordingData: null,
      recordingFiles: [],
      playingM3u8: "",
      dialogVisible: false,
      isRecording: false,

      phoneUrl: "",
      localVideoTrack: null,
      localAudioTrack: null,

      rtmUsers: {},
      messages: [],
      sendMsgText: "",
      rateValue: null,
      msgInputSelection: {}, //记录输入框的选中状态
      emojiList: [
        { name: '嘿嘿', value: '😀' },
        { name: '哈哈', value: '😄' },
        { name: '可爱', value: '😃' },
        { name: '可怜', value: '😥' },
        { name: '挖鼻', value: '🤮' },
        { name: '吃惊', value: '😲' },
        { name: '害羞', value: '😳' },
        { name: '挤眼', value: '😵' },
        { name: '闭嘴', value: '🤐' },
        { name: '鄙视', value: '😒' },
        { name: '爱你', value: '😍' },
        { name: '泪', value: '😢' },
        { name: '偷笑', value: '😄' },
        { name: '亲亲', value: '😗' },
        { name: '生病', value: '🤒' },
        { name: '生气', value: '😡' },
        { name: '困惑', value: '😖' },
      ],
      emojiVisible: false
    };
  },
  watch: {},
  computed: {
    // ...mapGetters(['userInfo', 'rtcRoom'])
  },
  created() {
    //监听window 的关闭和退回上一步事件，用来退出直播
    window.addEventListener('beforeunload', () => {
      this.stopRtc();
      this.stopRtm();
    });
    window.addEventListener('popstate', () => {
      this.stopRtc();
      this.stopRtm();
    }, false);

    // 开启日志上传功能
    AgoraRTC.enableLogUpload();

    // 获取url 的type 参数
    this.roomId = this.$route.query.roomId;
    this.type = this.$route.query.type;
    this.userId = this.$route.query.uid;
    this.userName = this.$route.query.uname;

    // this.options.uid = this.type + "|" + this.$route.query.uid;
    switch (this.type) {
      case "host_usb":
        this.options.uid = "1" + this.$route.query.uid;
        break;
      case "host_phone":
        this.options.uid = "2" + this.$route.query.uid;
        break;
      // case "host_distop":
      //   this.options.screenUid = "3" + this.$route.query.uid;
      //   break;
      case "audience":
        this.options.uid = "4" + this.$route.query.uid;
        break;
    }

    this.options.channel = this.$route.query.channel;

    this.options.screenUid = "3" + this.$route.query.uid;

    this.role = this.type == "audience" ? "audience" : "host";
    this.channelName = this.$route.query.channelName;
    this.options.rtmChannel = "RTM_" + this.$route.query.channel;

    //手机摄像头二维码网址
    this.phoneUrl = window.location.origin + window.location.pathname +
      "#/rtc/room_phone?appId=" + this.options.appId + "&type=host_phone&channel=" + this.$route.query.channel + "&uid=" + this.userId;


    //获取默认选择的摄像头ID
    var cameraId = window.localStorage.getItem("cameraId");
    //获取所有摄像头信息
    AgoraRTC.getCameras().then(res => {
      console.log("getCameras", res);
      this.cameras = res;
      if (cameraId) {
        this.selectedCameraId = cameraId;
      } else {
        this.selectedCameraId = res[0].deviceId;
        window.localStorage.setItem("cameraId", this.selectedCameraId);
      }
    });
    //获取默认选择的麦克风ID
    var microphoneId = window.localStorage.getItem("microphoneId");
    //获取所有麦克风信息
    AgoraRTC.getMicrophones().then(res => {
      this.microphones = res;
      if (microphoneId) {
        this.selectedMicrophoneId = microphoneId;
      } else {
        this.selectedMicrophoneId = res[0].deviceId;
        window.localStorage.setItem("microphoneId", this.selectedMicrophoneId);
      }
    })

    videoClient.on("user-joined", (user, reason) => {
      console.log("user-joined", user, reason);
    })
    //绑定用户加入事件
    videoClient.on("user-published", this.handleUserPublished)
    //绑定用户离开事件
    videoClient.on("user-unpublished", this.handleUserUnpublished);

    //说话人音量提示
    videoClient.enableAudioVolumeIndicator();
    videoClient.on("volume-indicator", result => {
      // console.log('volume-indicator', result);
      result.forEach((volume, index) => {
        //console.log('volume', `${index} UID ${volume.uid} Level ${volume.level}`);

        var uid = volume.uid + "";
        uid = uid.substr(1, uid.length);

        if (this.rtmUsers[uid]) {
          this.rtmUsers[uid].volume = volume.level;
          this.rtmUsers[uid].status = 1;
          //console.log('volume', this.rtmUsers[uid])
        }
      });
    });

    //视频token 超时处理
    // 收到 token-privilege-will-expire 回调时，从服务器重新申请一个 Token，并调用 renewToken 将新的 Token 传给 SDK
    videoClient.on("token-privilege-will-expire", async () => {
      console.log("token-privilege-will-expire")
      var ret = await getToken(this.options.channel, this.options.uid);
      if (ret.data.status == 200) {
        this.options.token = ret.data.data;
      }
      await videoClient.renewToken(this.options.token);
    });
    // 收到 token-privilege-did-expire 回调时，从服务器重新申请一个 Token，并调用 join 重新加入频道
    videoClient.on("token-privilege-did-expire", async () => {
      console.log("token-privilege-did-expire")
      await this.init();
    });

    //投屏 token 超时处理
    screenCient.on("token-privilege-will-expire", async () => {
      console.log("token-privilege-will-expire", "screenCient")
      var ret = await getToken(this.options.channel, this.options.screenUid);
      if (ret.data.status == 200) {
        this.options.screenToken = ret.data.data;
      }
      await screenCient.renewToken(this.options.screenToken);
    });
    videoClient.on("token-privilege-did-expire", async () => {
      console.log("token-privilege-did-expire", "screenCient")
      await this.startScreenCall();
    });

  },
  async mounted() {
    //从服务器获取Rtc设置
    var setting = await getSetting();
    if (setting.data.status == 200) {
      this.options.appId = setting.data.data.appId;
      this.domain = setting.data.data.domain;
    }

    let userList = await getRoomUsers(this.roomId);
    if (userList.data.status == 200) {
      userList.data.data.forEach(el => {
        this.rtmUsers[el.UserId + ""] = {
          userId: el.UserId,
          userName: el.U_TrueName || el.U_Name,
          status: 0,
          volume: 0
        }
      })
    }

    if (!this.rtmUsers[this.userId]) {
      //将自己提交给后台
      await addRoomUser({
        RoomId: this.roomId,
        UserId: this.userId,
        Role: this.role
      });
      this.rtmUsers[this.userId] = {
        userId: this.userId,
        userName: this.userName,
        status: 1,
        volume: 0
      }
    }


    this.init();
    this.initRtm();
  },
  methods: {
    getDateFormat(tick) {
      return new Date(tick / 10000).Format('yyyy-MM-dd HH:mm:ss')
    },


    //#region RTM部分
    async initRtm() {
      var rtmToken = await getToken(this.options.channel, this.userId, "rtm");
      rtmClient.client = new RTM(this.options.appId, this.userId, {
        token: rtmToken.data.data
      });
      //message事件
      rtmClient.client.addEventListener("message", this.onRTMMessage);
      //presence事件
      rtmClient.client.addEventListener("presence", this.onRTMPresence);
      //status 事件
      rtmClient.client.addEventListener("status", this.onRTMStatus);

      try {
        let loginRet = await rtmClient.client.login();
        console.log("rtm_loginRet", loginRet);
      } catch (status) {
        console.log("rtm_loginRet_error", status);
      }

      try {
        const result = await rtmClient.client.subscribe(this.options.rtmChannel, {
          withMessage: true,
          withPresence: true,
          withMetadata: true,
          withLock: true,

        });
        console.log("rtm_subscribe", result);
      } catch (status) {
        console.log("rtm_subscribe_error", status);
      }


    },
    async getWhoNow() {
      let whoRet = await rtmClient.client.presence.whoNow(this.options.rtmChannel, "MESSAGE", {
        includeUserId: true,
        includeState: true,
        // page: "yourBookMark"

      });
      console.log("rtm_whoNow", whoRet);
    },

    getRoomUserList(callback) {
      getRoomUsers(this.roomId).then(res => {
        res.data.data.forEach(data => {
          let uid = data.UserId + "";

          if (this.rtmUsers[uid]) {
            this.rtmUsers[uid].userName = data.U_TrueName || data.U_Name;
          } else {
            this.rtmUsers[uid] = {
              userId: data.UserId,
              userName: data.U_TrueName || data.U_Name,
              status: 0,
              volume: 0
            };
          }
        })
        if (callback) callback();
      })
    },
    async onUserJoin(data) {
      // console.log("onUserJoin", data);

      if (this.rtmUsers[data.userId]) {
        this.rtmUsers[data.userId].status = 1;
        this.showMessage("INFO", {
          type: "info",
          message: "" + this.rtmUsers[data.userId].userName + " 加入"
        });
      } else {
        data.status = 1;
        this.rtmUsers[data.userId] = data;
        this.getRoomUserList(() => {
          this.showMessage("INFO", {
            type: "info",
            message: "" + this.rtmUsers[data.userId].userName + " 加入"
          });
        });
      }


    },
    onUserLeave(uid) {
      if (this.rtmUsers[uid]) {
        this.rtmUsers[uid].status = 0;

        this.showMessage("INFO", {
          type: "info",
          message: "" + this.rtmUsers[uid].userName + " 退出"
        });
      }
    },
    //RT消息
    onRTMMessage(event) {
      console.log("rtm_onRTMMessage", event);
      var data = JSON.parse(event.message);

      this.showMessage(event.publisher, data);
    },
    onRTMPresence(event) {
      console.log("rtm_onRTMPresence", event);
      switch (event.eventType) {
        case "SNAPSHOT":
          this.showMessage("INFO", {
            type: "info",
            message: "已加入"
          });
          event.snapshot.forEach(el => {
            this.onUserJoin(el);
          })

          break;
        case "INTERVAL":
          // this.onUserLeave(event.data);
          break;
        case "REMOTE_JOIN":
          this.onUserJoin({ userId: event.publisher, userName: "", status: 1 });
          break;
        case "REMOTE_LEAVE":
        case "TIMEOUT":
        case "OUT_OF_SERVICE":

          this.onUserLeave(event.publisher);
          break;
        case "STATE_CHANGED":
          // this.onUserJoin(event.data);
          break;
      }
      // if (event.type == "SNAPSHOT") {

      // } else {
      //   this.showMessage("INFO", {
      //     type: "info",
      //     message: "" + event.publisher + " 加入"
      //   });
      //   this.onUserJoin({ userId: event.publisher, userName: "", status: 1 });
      // }
    },
    onRTMStatus(event) {
      console.log("rtm_onRTMStatus", event);
    },

    async publishMessage(message, type = "text") {
      let payload = { type: type, message: message };
      let json = JSON.stringify(payload);
      try {
        let ret = await rtmClient.client.publish(this.options.rtmChannel, json, {
          customType: "chat"
        });
        this.showMessage(this.userId, payload);
      } catch (ex) {
        console.log(ex);
      }
    },

    showMessage(user, data) {
      this.messages.push({
        user: user,
        type: data.type,
        message: data.message,
        date: new Date()
      });
      this.$nextTick(() => {
        var div = this.$refs.messageList;
        div.scrollTop = div.scrollHeight;
      })

    },
    sendMsg() {
      //把this.sendMsgText 的换行符替换成 <br>
      this.publishMessage(this.sendMsgText.replace(/\n/g, "<br>"));
      this.sendMsgText = "";
      // console.log("sendMsg", div);
      // this.$refs.inputDiv.innerHTML = "";
    },
    messageTextBlur(val) {
      this.sendMsgText = val.target.innerText;
    },
    messageTextKeyDown(val) {
      // console.log("text1KeyDown", val)
      if (val.keyCode == 13 && val.ctrlKey) {
        this.sendMsg();
      }
    },
    onEmojiClick(emoji) {
      var msg = this.sendMsgText;
      this.sendMsgText = msg.substring(0, this.msgInputSelection.startPos) + emoji.value + msg.substring(this.msgInputSelection.endPos);
      this.msgInputSelection.startPos += 2;
      this.msgInputSelection.endPos += 2;
    },
    emojiMenuVisible() {
      let element = this.$refs.msgInput.$el.children[0];
      let startPos = element.selectionStart; // 获取光标开始的位置
      let endPos = element.selectionEnd; // 获取光标结束的位置
      //console.log(startPos, endPos);
      this.msgInputSelection = {
        startPos, endPos
      }
    },
    rateChange(val) {
      this.publishMessage(val, "rate");
    },
    //#endregion 
    //#region RTC部分
    async init() {
      //获取token
      var ret = await getToken(this.options.channel, this.options.uid);
      if (ret.data.status == 200) {
        this.options.token = ret.data.data;
      }
      //加入房间
      var joinRet = await videoClient.join(this.options.appId, this.options.channel, this.options.token, Number(this.options.uid));
      console.log("joinRet", joinRet);

      //获取麦克风音频
      this.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack({
        AEC: true,
        ANS: true,
        microphoneId: this.selectedMicrophoneId
      });

      if (this.role == 'host') {
        // await this.initTracks();
        switch (this.type) {
          case "host_phone":
            // {
            //   var localVideoTrack = await AgoraRTC.createCameraVideoTrack({
            //     cameraId: this.selectedCameraId
            //   });
            //   await videoClient.publish([localAudioTrack, localVideoTrack]);
            //   localVideoTrack.play(this.$refs.phoneVideo);
            // }
            break;
          case "host_usb": {
            //获取摄像头视频
            this.localVideoTrack = await AgoraRTC.createCameraVideoTrack({
              encoderConfig: "1080p_1",
              cameraId: this.selectedCameraId
            });
            //发布
            await videoClient.publish([this.localAudioTrack, this.localVideoTrack]);
            this.localVideoTrack.play(this.$refs.mainVideo);
          }
            break;
          case "host_distop": {
          }
        }
      } else {
        //观众权限，直接发布音频轨道
        await videoClient.publish([this.localAudioTrack]);
      }
      this.$nextTick(()=>{
        this.volumeChange(50);
      })
    },
    onChangeMute() {
      this.isMute = !this.isMute;
      this.localAudioTrack.setVolume(0);
    },
    volumeChange(val) {
      this.localAudioTrack.setVolume(val);
      this.isMute = val <= 0;
    },

    onVideoOpened() {
      // var player = videojs('my-video', {
      //   bigPlayButton: false,
      //   textTrackDisplay: false,
      //   posterImage: true,
      //   errorDisplay: false,
      //   controlBar: true,
      // });

      // player.value.play();
    },
    onFileClick(item) {
      this.$copyText(this.domain + item.key);
      this.$message.success('已复制到剪贴板');
      window.open("https://tools.liumingye.cn/m3u8/#" + this.domain + item.key);
    },

    //屏幕共享
    async startScreenCall() {
      var ret = await getToken(this.options.channel, this.options.screenUid);
      if (ret.data.status == 200) {
        this.options.screenToken = ret.data.data;
      }
      await screenCient.join(this.options.appId, this.options.channel, this.options.screenToken, Number(this.options.screenUid));
      const screenTrack = await AgoraRTC.createScreenVideoTrack({
        // 在这里配置屏幕共享编码参数，详细参考 API 文档
        encoderConfig: "1080p_1",
      });
      await screenCient.publish(screenTrack);
      screenTrack.play(this.$refs.distopVideo);
      this.isScreenShow = true;
    },

    //观众进入房间
    async handleUserPublished(user, mediaType) {
      console.log("user-published", user, mediaType);
      // if(this.players.indexOf(user.uid)>=0){
      //   return
      // }
      await videoClient.subscribe(user, mediaType);
      // this.players.push(user)
      // if (this.role == "audience") {
      // if (this.players.indexOf(user.uid) < 0) {
      //   this.players.push(user.uid);
      // }
      var uidStr = user.uid + "";
      var coms = uidStr.substr(0, 1);
      var uid = uidStr.substr(1, uidStr.length);
      switch (coms) {
        case "1": //主摄像头
          if (mediaType === 'video') {
            const remoteVideoTrack = user.videoTrack;
            remoteVideoTrack.play(this.$refs.mainVideo);
          }
          break;
        case "2": //手机
          if (mediaType === 'video') {
            const remoteVideoTrack = user.videoTrack;
            remoteVideoTrack.play(this.$refs.phoneVideo);
          }
          break;
        case "3": //屏幕
          if (mediaType === 'video') {
            const remoteVideoTrack = user.videoTrack;
            remoteVideoTrack.play(this.$refs.distopVideo);
          }

          break;
      }

      if (mediaType === 'audio') {
        const remoteAudioTrack = user.audioTrack;
        remoteAudioTrack.play();
      }
      // }
      console.log("subscribe");
    },
    //用户离开房间
    handleUserUnpublished(user, mediaType) {
      this.players.splice(this.players.indexOf(user.uid), 1);
      console.log("unsubscribe");
    },

    onCameraChange() {
      window.localStorage.setItem("cameraId", this.selectedCameraId);
      // videoClient.leave();
      // this.init();
      if (this.localVideoTrack) {
        this.localVideoTrack.setDevice(this.selectedCameraId).then(() => {
          this.$message.success("切换摄像头成功");
        }).catch(e => {
          this.$message.error("切换摄像头失败");
        })
      }
    },
    onMicrophoneChange() {
      window.localStorage.setItem("microphoneId", this.selectedMicrophoneId);
      this.localAudioTrack.setDevice(this.selectedMicrophoneId).then(
        () => {
          this.$message.success("切换麦克风成功");
        }
      ).catch(e => {
        this.$message.error("切换麦克风失败");
      });
    },

    //#endregion 
    //#region 录制部分

    //开始录制
    async startRecord() {
      var ret = await startRecording({
        cname: this.options.channel,
        uid: this.userId
      });
      if (ret.data) {
        this.recordingData = ret.data;
        console.log("startRecord", ret);
        this.$message.success("开始录制");
        this.isRecording = true;
      } else {
        this.$message.error("开始录制失败");
      }

    },
    //停止录制
    async stopRecord() {
      var ret = await stopRecording(this.recordingData);
      console.log("stopRecord", ret);
      if (ret.data) {
        this.$message.success("录制已停止");
        this.isRecording = false;
      } else {
        this.$message.error("停止录制失败");
      }
    },
    //查询录制情况 ps:目前没用
    async queryRecord() {
      var ret = await queryRecording(this.recordingData);
      console.log("queryRecord", ret);
    },
    //点击文件列表 查询已录制的文件
    filesMenuVisible(show) {
      // this.filesLoading = true;
      getRecordingFiles(this.options.channel).then(res => {
        // this.filesLoading = false;
        res.data.sort((a, b) => {
          return a.putTime - b.putTime
        })
        this.recordingFiles = res.data;
      })
    },
    //#endregion

    stopRtc() {
      videoClient.leave();
      screenCient.leave();
      if (this.isRecording)
        this.stopRecord();
    },
    stopRtm() {
      try {
        const result = rtmClient.client.logout();
        console.log(result);
      } catch (status) {
        console.log(status);
      }

    },
    exit(e) {
      this.stopRtc();
      this.stopRtm();
      this.$router.back()
    },

  },

  destroyed() { // 销毁vm组件
    // 避免堆栈溢出，多次创建、多次触发
    window.removeEventListener('popstate', this.stopRtc, false);
  },
  unmounted() {
    videoClient.leave();
    screenCient.leave();
  }
};
</script>
<style lang="scss" scoped>
.wrapper {
  background: #eee;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 5px;

  .top-bar {
    height: 50px;
    background: #fff;
    display: flex;
    align-items: center;
    padding: 0 10px;
    justify-content: space-between;

    .mic-status {
      width: 300px;
      display: flex;
      align-items: center;
    }
  }

  .filesList {
    li {
      text-decoration: none;
    }
  }

  .content {
    display: flex;
    height: calc(100vh - 50px);

    .video-box {
      flex: 1;
      height: 100%;
    }

    .right {
      width: 300px;
      background: #fff;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0 5px;

      .top {
        height: 50px;
        display: flex;
        align-items: center;
        padding: 0 10px;
        justify-content: space-around;
      }

      .list {
        flex: 1;

        .box {
          .title {
            display: flex;
            justify-content: center;
            background: #1890ff;
            color: #fff;
            padding: 8px 0;

          }

          ul {
            overflow-y: auto;

            li {
              list-style: none;
            }
          }
        }

        .user-list {
          display: flex;
          height: 100px;
          overflow: auto;
          font-size: 14px;
          flex-wrap: wrap;
          align-content: flex-start;

          .user {
            display: flex;
            align-items: flex-end;

            span {
              margin-left: 3px;
            }
          }

          span {
            margin: 5px 10px 0 0;
          }

          .outline {
            color: #f77a06;
          }

          .online {
            color: #52c41a;
          }
        }

        .message-list {
          padding: 5px;

          .info {
            font-size: 12px;
            color: #1890ff;
          }

          .message {
            font-size: 16px;
            margin-top: 5px;

            .user {
              display: flex;
              align-items: center;

              .hicon {
                font-size: 18px;
                background: #ccc;
                border-radius: 50%;
                border: 3px solid #ccc;
                padding: 4px;
              }

              .inf {
                display: flex;
                flex-direction: column;
                margin-left: 10px;
              }
            }


            .name {
              font-size: 16px;
              color: #130ffc;
            }

            .my {
              color: #0ffc2f;
            }

            .date {
              font-size: 10px;
              color: #333;
            }

            .msg {
              color: #333;
              padding: 5px;
            }
          }
        }
      }

      .input-bar {
        margin-bottom: 10px;

        .btns {
          display: flex;
          justify-content: space-between;
          margin-bottom: 4px;
          align-items: center;

          .icons {
            display: flex;
            align-items: center;
            cursor: pointer;

            .el-icon {
              font-size: 21px;
              margin-left: 10px;
            }
          }
        }

        .emoji_btn {
          cursor: pointer;
        }

        .emoji_list {
          display: flex;
          flex-wrap: wrap;

          .emoji {
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
        }

        .input_border {}

        .input_div {
          border: 0;
          outline: none;
          padding: 8px;
          height: 120px;
        }
      }
    }
  }

  .status-bar {
    height: 30px;
  }
}
</style>