<template>
  <div class="table-page-search-wrapper">
    <div class="inlineblo">
      <div>评分表名称:</div>
      <div>
        <el-input v-model="msName" placeholder="评分表名称" style="width: 400px;" clearable></el-input>
      </div>
      <!--<div>&nbsp;排考类型:</div>
       <div>
        <el-checkbox-group v-model="query.examKind" label="label">
          <el-checkbox :label="item.value" v-for="item in plainOptions" checked>
            {{ item.label }}
          </el-checkbox>
        </el-checkbox-group>
      </div> -->
      <div>
        <el-button @click="searchList()" type="primary" :icon="Search">搜索</el-button>
      </div>
    </div>
    <div class="inlineblo">
      <div>考试时间:</div>
      <div>
        <el-radio-group v-model="examDatetime">
          <el-radio :label="-1" v-if="false"> </el-radio>
          <el-radio :label="0"> 不限 </el-radio>
          <el-radio :label="7"> 一周之内 </el-radio>
          <el-radio :label="30"> 一个月之内 </el-radio>
          <el-radio :label="90"> 三个月之内 </el-radio>
          <el-radio :label="180"> 半年之内 </el-radio>
          <el-radio :label="365"> 一年之内 </el-radio>
        </el-radio-group>
      </div>
      &nbsp;&nbsp;&nbsp;

      <div style="width:500px">
        <el-date-picker v-model="searchDates" type="daterange" unlink-panels range-separator="至" start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>

      </div>
    </div>
  </div>
  <el-table :data="list" border v-loading="dataLoading" @selection-change="onSelectionChange"
    height="calc(100vh - 260px)">
    <el-table-column type="selection" width="55" />

    <!-- <el-table-column label="序号" prop="">
            <template slot-scope="text, record, index">
              <span>
                {{ (query.pageIndex - 1) * query.pageSize + Number(index) + 1 }}
              </span>
            </template>
          </el-table-column> -->
    <el-table-column label="评分表名称" width="420" prop="MS_Name">
      <!-- <template #default="scope">
        {{ GetExamTypeDetail(scope.row) }}
      </template> -->
    </el-table-column>

    <el-table-column label="评分表类型" prop="MSK_Kind">
    </el-table-column>
    <el-table-column label="总分" prop="MS_Score">
    </el-table-column>
    <el-table-column label="平均分" prop="avg_Score" />
    <el-table-column label="最高分" prop="max_Score" />
    <el-table-column label="最低分" prop="min_Score" />
    <el-table-column label="标准差" prop="stdev_Score" :formatter="floatFormatter" />
    <el-table-column label="人次" prop="s_count" />
    <el-table-column label="操作" prop="MS_ID">
      <template #default="scope">
        <!-- <router-link :to="'/scoreInfo/detail?msId=' + scope.row.MS_ID + '&unitCode=' + unitCode"
          class="link">单项统计</router-link>
        &nbsp; -->
        <router-link
          :to="'/examiner/info?msId=' + scope.row.MS_ID + '&unitCode=' + unitCode + '&start=' + start + '&end=' + end"
          class="link" target="_blank">评分偏离度</router-link>
        <!-- &nbsp;
        <router-link :to="'/scoreInfo/word?msId=' + scope.row.MS_ID + '&unitCode=' + unitCode"
          class="link">导出word</router-link> -->


      </template>
    </el-table-column>
  </el-table>
  <div class="avue-crud__pagination" style="display: flex;justify-content: space-between;padding:10px 10px 5px 10px;">
    <div></div>

    <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage" background
      layout="total, prev, pager, next, sizes">
    </el-pagination>
  </div>
</template>
<script setup>
import {
  Check,
  Delete,
  Edit,
  Message,
  Search,
  Download,
} from '@element-plus/icons-vue'
</script>
<script>
// import { request } from "@/global/CommonService";
// import { GetExamTypeDetail } from "./allFun";
import { getPageList } from "@/api/examinertraining"
import { toRaw } from '@vue/reactivity'
export default {
  created() {

  },
  data() {
    return {
      dataLoading: false,
      zIndex: 0,
      myVisible: this.visible,
      total: 0,
      ExamKindMap: [
        "长短站",
        "多站式",
        "单站式",
        "自定义", // added by lyl on 20161216
      ],
      zIndex: 999,
      current: 1,
      inline: true,
      checkedList: [],
      plainOptions: [
        { label: "单站考试", value: 3 },
        { label: "多站考试", value: 2 },
        { label: "长短站考试", value: 1 },
        { label: "响应式考试", value: 4 },
      ],

      list: [],
      list2: [],
      selectedRowKeys: [],
      selectedRows: [],
      selectedRowKeys2: [],
      selectedRows2: [],
      pageIndex: 1,
      pageSize: 20,
      totalPage: 0,
      examDatetime: 7,
      start: '',
      end: '',
      msName: '',
      multipleSelection: [],
      unitCode: '',
      searchDates: []
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        if (this.$route.query.unitCode) {//需要监听的参数
          //把需要显示的数据重新赋值
          this.unitCode = this.$route.query.unitCode
        }
      }
    },

    examDatetime(val) {
      if (val >= 0) {
        this.pageIndex = 1
        this.start = "";
        this.end = "";

        this.getlist();
      }

    },
    'searchDates': function (n, o) {
      //console.log("searchDates", n, o)
      if (n && n.length > 1) {
        this.start = n[0].Format("yyyy-MM-dd") + " 00:00:00"
        this.end = n[1].Format("yyyy-MM-dd") + " 23:59:59"
        this.examDatetime = -1;
      } else {
        this.start = "";
        this.end = "";
      }
      this.pageIndex = 1;
      this.getlist();
    }
  },
  mounted() {
    if (!this.$route.query.unitCode) {
      this.$nextTick(() => {
        this.$message.error("没有发现机构参数");

      })
    } else {
      this.unitCode = this.$route.query.unitCode
    }
    this.getlist();
  },
  methods: {
    onSelectionChange(val) {
      this.multipleSelection = val;
    },

    floatFormatter(row, column, value) {
      return value.toFixed(2)
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getlist()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getlist()
    },
    GetExamTypeDetail(r) {
      return GetExamTypeDetail(r, 0);
    },
    searchList() {
      this.pageIndex = 1;
      this.getlist();
    },
    //data=一级分类；sec_kind=二级分类
    gettype(data, sec_kind) {
      switch (data) {
        case "1":
          //return this.ExamKindMap[0]
          return "长短站";
        case "2":
          if (sec_kind === "1" || sec_kind === "2") {
            return "多站式";
          }
          if (sec_kind === "10" || sec_kind === "12") {
            return "响应式";
          }
        case "3":
          //return this.ExamKindMap[2]
          if (this.isMatch == 1) {
            return "赛道式";
          } else {
            return "单站式";
          }
      }
    },
    getlist() {

      if (this.examDatetime > 0) {
        var myDate = new Date();
        switch (this.examDatetime) {
          case 7:
            // 一周之内的日期
            myDate.setDate(myDate.getDate() - 7);
            break;
          case 30:
            // 一个月之内的日期
            myDate.setMonth(myDate.getMonth() - 1);
            break;
          case 90:
            // 三个月之内的日期
            myDate.setMonth(myDate.getMonth() - 3);
            break;
          case 180:
            // 半年之内的日期
            myDate.setMonth(myDate.getMonth() - 6);
            break;
          case 365:
            // 一年之内的日期
            myDate.setFullYear(myDate.getFullYear() - 1);
            break;
        }
        this.start = myDate.Format("yyyy-MM-dd 00:00:00")
        this.end = ''
      }

      var query = {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        start: this.examDatetime == 0 ? '' : this.start,
        end: this.examDatetime == 0 ? '' : this.end,
        msName: this.msName,
        unitCode: this.unitCode
      };

      this.dataLoading = true;
      getPageList(query).then(res => {
        this.dataLoading = false;
        this.list = res.data.data
        this.totalPage = res.data.total
      })
    },
    pagesicechang(page, size) {
      this.query.pageIndex = page;
      this.query.pageSize = size;
      this.getlist();
    },
    //<a-pagination :showTotal="total => `共 ${total} 条`" v-model="query.pageIndex" :total="total" @change="pagesicechang" show-less-items show-size-changer @showSizeChange="onShowSizeChange"/>
    onShowSizeChange(current, pageSize) {
      console.log(current, pageSize);
      this.query.pageIndex = 1;
      this.query.pageSize = pageSize;
      this.getlist();
    },
  },
};
</script>
<style  lang="scss" scoped>
.inlineblo {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  div {
    margin-left: 5px;
  }
}

div #tableSelByExam .ant-table-body {
  min-height: 500px;
  max-height: 500px;
  overflow-y: auto;
}

div #tableSelByStudent .ant-table-body {
  min-height: 500px;
  max-height: 500px;
  overflow-y: auto;
}
</style>
