import request from './axiosApi'

request.defaults.baseURL = "/api"

export function getScoreAvg(query){
    return request({
        url:'/Single/avg',
        method:'get',
        params:query
    })
}
export function getScoreDistribution(query){
    return request({
        url:'/Single/distribution',
        method:'get',
        params:query
    })
}

export function getDSScore1(query){
    return request({
        url:'/Single/DSScore1',
        method:'get',
        params:query
    })
}

export function getDSScoreDetail(query){
    return request({
        url:'/Single/DSScoreDetail',
        method:'get',
        params:query
    })
}

export function getRaterScore(query){
    return request({
        url:'/Single/RaterScore',
        method:'get',
        params:query
    })
}
