<template>
  <el-config-provider :locale="locale">
    <!-- <keep-alive v-if="$route.meta.keepAlive"> -->
      <router-view></router-view>
    <!-- </keep-alive>
    <router-view v-else></router-view> -->
  </el-config-provider>
</template>

<script>
import { ElConfigProvider } from 'element-plus'

import zhCn from 'element-plus/lib/locale/lang/zh-cn'



export default {
  name: 'App',
  components: {
    ElConfigProvider,
  },
  data() {
    return {
      locale: zhCn,
    }
  }
}
</script>

<!-- <style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style> -->

<style  lang="scss">
.table-page-search-wrapper {
    background-color: #fff;
    padding: .75rem .5rem;
    border: .05rem solid rgba(0, 0, 0, .1);
    margin-bottom: .5rem;
}

.inlineblo {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .label {
        min-width: 70px;
    }

    div {
        white-space: nowrap;
        margin-left: 5px;
    }
}

.botom {
    height: 400px;
    padding-top: 15px;
    // div {
    //     flex: 1;
    // }
    position: relative;

    .expandButton {
        position: absolute;
        top: 15px;
        right: 20px;
        z-index: 300;
        cursor: pointer;
    }
}

.expand {
    position: fixed;
    z-index: 200;
    height: calc(100vh - 30px);
    top: 5px;
    bottom: 5px;
    left: 5px;
    right: 5px;
    background: white;
    border: solid 1px #ccc;
    animation-duration: 0.2s;
    animation-name: slidein;
}

@keyframes slidein {
    from {
        top: calc(100vh - 400px);
    }

    to {
        top: 5px
    }
}

.pie {
    width: 100%;
    height: 380px;
}

.echarts {
    width: 100%;
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
}

.ant-table-column-title {
    overflow: hidden;

    text-overflow: ellipsis;

    display: -webkit-box;

    -webkit-line-clamp: 1;

    -webkit-box-orient: vertical;
}

.inlinebloTab {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 10px;

    .label {
        min-width: 70px;
    }

    div {
        white-space: nowrap;
        margin-left: 5px;
    }
}

.card-container {
    // border-color: #f5f5f5;
    //border: 1px solid rgba(0, 0, 0, 0.1);
    overflow: auto;
    // padding: 24px;
    // min-height: calc(100vh - 100px);
    // max-height: calc(100vh - 100px);
    height: calc(100vh - 100px);
    position: relative;
    overflow: hidden;

    .export_buttons {
        position: absolute;
        right: 10px;
        top: 3px;
        z-index: 100;
    }
    .el-tabs__header{
        margin:0 !important;
    }
}


#tableStation .ant-table-body {
    min-height: 260px;
    max-height: 260px;
    overflow-y: auto;
}

#tableStationScore .ant-table-body {
    min-height: 260px;
    max-height: 260px;
    overflow-y: auto;
}

#tableStudent .ant-table-body {
    min-height: calc(100vh - 480px);
    max-height: calc(100vh - 480px);
    height: calc(100vh - 480px);
    overflow-y: auto;
}

#tableSf .ant-table-body {
    min-height: 260px;
    max-height: 260px;
    overflow-y: auto;
}
.el-dialog__header{
    background: #1890ff;
    margin: 0px !important;
    border: solid 1px #fff;
    text-align: center;
    padding: 10px !important;
    border-radius:  3px 3px 0 0;
    .el-dialog__title{
        color: #fff;
        text-align: center;
    }
    .el-dialog__headerbtn{
        top:-4px;
    }
    .el-dialog__close{
        border: solid 1px #fff;
        border-radius: 50%;
        color: #fff !important;
        padding: 2px;

    }
}
.tableHeader{
    color:#555
}
</style>
  
