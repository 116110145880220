<template>
    <div class="volume">
        <div class="val" :style="'height:' + modelValue + '%'">
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        modelValue: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {

        };
    },
    watch: {

    },
    computed: {},
    methods: {},
    created() { },
    mounted() { }
};
</script>
<style lang="scss" scoped>
.volume {
    position: relative;
    width: 5px;
    height: 16px;
    border:solid 1px #ddd;
    border-radius:2px;
    .val {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: green;
    }
}
</style>