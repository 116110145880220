<template>
    <video ref="localVideo" class="pusher" controls autoplay style="width:100%;height:100%;object-fit:fill;"></video>
</template>
   
<script>
import Srs from './srs.sdk'
export default {
    name: 'webrtcPusher',
    props: {
        videoId: {
            type: String,
            default: 'pusher'
        },
        url: {
            type: String,
            default: ''
        },
        isVideo: {
            type: Boolean,
            default: false
        },
        sig: {
            type: Object,
            default: () => null
        },
        host:{
            type:String,
            default:'storm.osve.cn:9849'
        },
        room: {
            type: String,
            default: 'live'
        },
        display: {
            type: String,
            default: '123456'
        },
    },
    data() {
        return {
            sdk: null,
            conf: null,
            publisher: null
        }
    },
    created() {
        // this.url = 'webrtc://41.128.16.190:1990/live/livestream'
    },
    mounted() {
        // this.$watch('url', () => {
        //     console.log('url', this.url)
        //     if (this.url) {
        //         this.start()
        //     } else {
        //         // this.stop()
        //     }
        // }, { immediate: true, deep: true })
        // this.conf = Sig.SrsRtcSignalingParse(window.location);
        this.start()
    },
    methods: {
        async start() {
            console.log("start")
            
            // Start publish media if signaling is ok.
            await this.startPublish(this.host, this.room, this.display);

            let r1 = await this.sig.send({ action: 'publish', room: this.room, display: this.display });

            console.log('Signaling: publish ok', r1);

            // Play the stream already in room.
            // r0.participants.forEach(participant => {
            //     if (participant.display === this.display || !participant.publishing) return;
            //     this.startPlay(this.host, this.room, participant.display);
            // });

        },
        async startPublish(host, room, display) {
            var url = 'webrtc://' + host + '/' + room + '/' + display;
            console.log('startPublish', url)

            if (this.publisher) {
                this.publisher.close();
            }
            var publisher = new Srs.SrsRtcPublisherAsync();
            let video = this.$refs.localVideo; //document.getElementById(this.videoId);
            console.log('video', video, publisher);
            video.srcObject = publisher.stream;

            this.publisher = publisher;
            return publisher.publish(url).then(session => {
                //$('#self').text('Self: ' + url);
                console.log('publish success');
            }).catch((reason) => {
                publisher.close();
                //$('#rtc_media_publisher').hide();
                console.error(reason);
            });
        },
        startPlay(host, room, display) {

            let video = this.$refs.localVideo; //document.getElementById(this.videoId);
            console.log('video.length', video);
            let player = new Srs.SrsRtcPlayerAsync();

            var url = 'webrtc://' + host + '/' + room + '/' + display;
            console.log('start play url', url);
            video.show();


            player.play(url).then(function (session) {

                video.prop('muted', false);
            }).catch(function (reason) {
                player.close();
                video.hide();
                console.error(reason);
            });
        }
    }
}
</script>