<template>
  <el-dialog v-el-drag-dialog title="考试评分详情" v-model="myVisible" append-to-body :close-on-click-modal="false"
    @closed="closeDialog" @open="openDialog" draggable width="60vw" top="5vh">
    <div class="form">
      <div class="row">
        <div><span>姓名：</span>{{ scoreInfo.Student_Name }}</div>
        <div><span>学/工号：</span>{{ scoreInfo.Student_NO }}</div>
      </div>
      <div class="row">
        <div><span>评价人：</span>{{ scoreInfo.Rater_TrueName }}</div>
        <div><span>评分时间：</span>{{ scoreInfo.SI_CreateTime }}</div>
      </div>
      <div class="row">
        <div><span>评分表：</span>{{ scoreInfo.MS_Name }}</div>
        <div><span>得分：</span>{{ scoreInfo.SI_Score }}</div>
      </div>
    </div>
    <el-table :data="tableList" border v-loading="dataLoading" @selection-change="onSelectionChange" height="65vh" stripe
      :span-method="tableSpanMethod">
      <el-table-column label="评分项" prop="ms_count" width="120" align="center">
        <el-table-column label="一级评分项" prop="Item_Name" align="center">

        </el-table-column>
        <el-table-column label="二级评分项" prop="Item_Name_2" align="center">

        </el-table-column>
      </el-table-column>
      <el-table-column label="分值" prop="MSI_Score" width="80" align="center">

      </el-table-column>
      <el-table-column label="得分" prop="Item_Score" width="80" align="center">

      </el-table-column>
      <el-table-column label="扣分点" prop="" align="center">

      </el-table-column>
      <el-table-column label="结果明细" prop="" align="center">

      </el-table-column>
    </el-table>
    <div id="exportDiv" style="display: none;">
      <h3 style="text-align: center;">
        {{ scoreInfo.MS_Name }}
      </h3>
      <table style="width: 100%;">
        <tr>
          <td><span style="font-weight: bold;">姓名：</span>{{ scoreInfo.Student_Name }}</td>
          <td><span style="font-weight: bold;">学/工号：</span>{{ scoreInfo.Student_NO }}</td>
        </tr>
        <tr>
          <td><span style="font-weight: bold;">评价人：</span>{{ scoreInfo.Rater_TrueName }}</td>
          <td><span style="font-weight: bold;">评分时间：</span>{{ scoreInfo.SI_CreateTime }}</td>
        </tr>
        <tr>
          <td><span style="font-weight: bold;">评分表：</span>{{ scoreInfo.MS_Name }}</td>
          <td><span style="font-weight: bold;">得分：</span>{{ scoreInfo.SI_Score }}</td>
        </tr>
      </table>
      <table style="width: 100%;border-right: 1px solid #000000;border-bottom: 1px solid #000000;" cellpadding="5"
        cellspacing="0">
        <tr>
          <th colspan="2" style="border-left: 1px solid #000000; border-top: 1px solid #000000;">评分项</th>
          <th rowspan="2" style="border-left: 1px solid #000000; border-top: 1px solid #000000;">分值</th>
          <th rowspan="2" style="border-left: 1px solid #000000; border-top: 1px solid #000000;">得分</th>
          <th rowspan="2" style="border-left: 1px solid #000000; border-top: 1px solid #000000;">扣分点</th>
          <th rowspan="2" style="border-left: 1px solid #000000; border-top: 1px solid #000000;">结果明细</th>
        </tr>
        <tr>
          <th style="border-left: 1px solid #000000; border-top: 1px solid #000000;width: 30%;">一级评分项</th>
          <th style="border-left: 1px solid #000000; border-top: 1px solid #000000;width: 30%;">二级评分项</th>
        </tr>
        <tr v-for="(row, index) in tableList">
          <td style="border-left: 1px solid #000000; border-top: 1px solid #000000;"
            v-if="tableSpanMethod({ row, column: { property: 'Item_Name' }, rowIndex: index, columnIndex: 0 }).rowspan > 0"
            :rowspan="tableSpanMethod({ row, column: { property: 'Item_Name' }, rowIndex: index, columnIndex: 0 }).rowspan">
            {{ row.Item_Name }}</td>
          <td style="border-left: 1px solid #000000; border-top: 1px solid #000000;">{{ row.Item_Name_2 }}</td>
          <td style="border-left: 1px solid #000000; border-top: 1px solid #000000;text-align: center;">{{ row.MSI_Score
          }}</td>
          <td style="border-left: 1px solid #000000; border-top: 1px solid #000000;text-align: center;">{{ row.Item_Score
          }}</td>
          <td style="border-left: 1px solid #000000; border-top: 1px solid #000000;"></td>
          <td style="border-left: 1px solid #000000; border-top: 1px solid #000000;"></td>
        </tr>
      </table>
    </div>

    <div class="buttons">
      <el-button type="primary" @click="exportWord">导出Word</el-button>
      <el-button @click="closeDialog">关闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getScoreInfo as ateGetScoreInfo } from '@/api/ateexam'
import { getScoreInfo as GetScoreInfo } from '@/api/exam'

import htmlDocx from 'html-docx-js/dist/html-docx';
import saveAs from 'file-saver';

export default {
  components: {

  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    scoreId: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    }
  },
  emits: [
    'update:visible'
  ],
  data() {
    return {
      myVisible: this.visible,
      dataLoading: false,
      scoreInfo: {},
      tableList: [],
      itemNameList: []
    };
  },
  watch: {
    visible(val) {
      this.myVisible = val;
    }
  },
  computed: {},
  methods: {
    getList() {
      this.dataLoading = true;
      if (this.type == 'ate') {
        ateGetScoreInfo(this.scoreId).then(res => {
          console.log(res)
          this.scoreInfo = res.data;
          this.dataLoading = false;
          this.initTable(res.data);
        });
      } else {
        GetScoreInfo(this.scoreId).then(res => {
          console.log(res)
          this.scoreInfo = res.data;
          this.dataLoading = false;
          this.initTable(res.data);
        });
      }

    },
    initTable(data) {
      var items = JSON.parse(data.SI_Items);
      // console.log(tableData)
      var tableData = [];
      this.itemNameList = [];
      this.tableList = [];
      var lastItemName = '';
      items.forEach(el => {
        el.children_item_list.forEach(sel => {
          tableData.push({
            Item_Name: decodeURI(el.MSI_Item),
            Item_Name_2: decodeURI(sel.MSI_Item),
            Item_Score: sel.Item_Score,
            MSI_Score: sel.MSI_Score
          });
          this.itemNameList.push(decodeURI(el.MSI_Item)); //一级评分项列表，用来合并单元格的
        });
      });
      this.tableList = tableData;
    },
    tableSpanMethod1() {

    },
    tableSpanMethod({ row, column, rowIndex, columnIndex }) {
      // console.log('tableSpanMethod', row, column, rowIndex, columnIndex)
      // 判断是否是第一列
      if (columnIndex === 0) {
        // 获取当前单元格的内容
        const currentValue = row[column.property];

        // 获取当前单元格所在列的数据


        // 查找当前单元格的内容在列数据中的起始索引和结束索引
        const startIndex = this.itemNameList.indexOf(currentValue);
        const endIndex = this.itemNameList.lastIndexOf(currentValue);

        // 如果当前单元格的索引等于起始索引，则表示是第一个相同内容的单元格，需要合并
        if (rowIndex === startIndex) {
          // 计算合并的行数
          const rowspan = endIndex - startIndex + 1;
          // 返回合并的行数和列数
          return {
            rowspan: rowspan,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          }
        }
      }
    },
    exportWord() {
      // var content = ` <h1>This is an about page</h1>
      // <h2>This is an about page</h2>`
      var content = document.getElementById('exportDiv').innerHTML;
      var page = '<!DOCTYPE html><html><head><meta charset="UTF-8"></head><body>' + content + '</body></html>'
      var converted = htmlDocx.asBlob(page);
      // 用 FielSaver.js里的保存方法 进行输出
      saveAs(converted, this.scoreInfo.MS_Name +'_'+ this.scoreInfo.Student_Name + '.docx');
    },
    closeDialog() {
      this.myVisible = false;
      this.$emit('update:visible', false)
    },
    openDialog() {
      this.getList();
    }
  },

  created() { },
  mounted() { }
};
</script>
<style lang="scss" scoped>
.form {
  margin-bottom: 10px;

  .row {
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px;

    div {
      font-size: 16px;
      flex: 1;
      color: #555;

      span {
        color: #000;
      }
    }
  }
}

.buttons {
  padding-top: 10px;
  text-align: right;
}
</style>
