<template>
    <div class="wrapper">
        <div ref="phoneVideo" style="height: 90vh; width: 100%; background: #fff; position: relative;">
            <h2 style="position: absolute;top:50%;left: 50%;transform:translate(-50%,-50%);">手机摄像头未开启</h2>
        </div>
        <div class="cameraBtn"><el-button type="primary" :icon="Refresh" circle @click="changeCamera"></el-button></div>

        <div class="micBtn">
            <el-button type="success" :icon="Microphone" circle @click="muteMic" v-if="!isMute"></el-button>
            <el-button type="danger" :icon="Mute" circle @click="muteMic" v-else></el-button>
        </div>

        <div class="volumeSilder">
            <el-slider v-model="volume" vertical height="200px" @change="volumeChange" :disabled="isMute" />
        </div>

    </div>
</template>

<script setup>
import {
    Refresh,
    Microphone,
    Mute
} from '@element-plus/icons-vue'
</script>
<script>
import AgoraRTC from 'agora-rtc-sdk-ng'
import { getToken, startRecording, stopRecording, queryRecording, getRecordingFiles, getRecordingFileUrl } from '@/api/rtcRoom'

const videoClient = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });

export default {
    components: {},
    props: {},
    data() {
        return {
            options: {
                appId: "3ce74dfa23874a94afd3ecf3eaa8ac37",
                channel: "",
                token: "007eJxTYFj6x0Yll/9/9y/PZdudH9qY1nStMzmot2zi6+T4vof5M5QVGIyTU81NUtISjYwtzE0SLU0S01KMU5PTjFMTEy0Sk43Nr7I1pDYEMjJ8WtbGwsgAgSA+D4NpiplpUqqxUZqlqTEDAwDnHyNR",
                uid: null,
            },
            role: 'host',
            cameras: [],
            selectedCameraId: null,
            localVideoTrack: null,
            localAudioTrack: null,
            isMute: false,
            volume: 50,
            facingMode: "environment"
        };
    },
    watch: {},
    computed: {},
    created() {
        window.addEventListener('beforeunload', e => this.exit(e));
        window.addEventListener('popstate', this.stopRtc, false);
    },
    destroyed() { // 销毁vm组件
        // 避免堆栈溢出，多次创建、多次触发
        window.removeEventListener('popstate', this.stopRtc, false);
        // window.removeEventListener('beforeunload', e => this.exit(e));
    },
    mounted() {
        this.type = this.$route.query.type;
        this.options.appId = this.$route.query.appId;
        this.options.channel = this.$route.query.channel;
        this.options.uid = "2" + this.$route.query.uid;
        // var cameraId = window.localStorage.getItem("cameraId");
        // AgoraRTC.getCameras().then(res => {
        //     console.log("getCameras", res);
        //     this.cameras = res;
        //     if (cameraId) {
        //         this.selectedCameraId = cameraId;
        //     } else {
        //         this.selectedCameraId = res[0].deviceId;
        //         window.localStorage.setItem("cameraId", this.selectedCameraId);
        //     }
        // });


        videoClient.on("user-published", this.handleUserPublished)
        videoClient.on("user-unpublished", this.handleUserUnpublished);

        videoClient.on("token-privilege-will-expire", async () => {
            var ret = await getToken(this.options.channel, this.options.uid);
            if (ret.data.status == 200) {
                this.options.token = ret.data.data;
            }
            await videoClient.renewToken(this.options.token);
        });

        // 收到 token-privilege-did-expire 回调时，从服务器重新申请一个 Token，并调用 join 重新加入频道
        videoClient.on("token-privilege-did-expire", async () => {
            console.log("Fetching the new Token")
            await this.init();
        });



        this.$nextTick(() => {
            this.init();
        })
    },
    methods: {
        volumeChange(val) {
            console.log("volumeChange", val);
            this.localAudioTrack.setVolume(val);
        },
        muteMic() {
            this.isMute = !this.isMute;
            if (this.isMute) {
                this.localAudioTrack.setVolume(0);
            }

            else {
                this.localAudioTrack.setVolume(this.volume);
            }

        },
        changeCamera() {
            // var ll = this.cameras.filter(el => el.deviceId != this.selectedCameraId);
            // if (ll.length > 0) {
            //     this.selectedCameraId = ll[0].deviceId;
            //     window.localStorage.setItem("cameraId", this.selectedCameraId);

            //     // videoClient.leave();
            //     // // videoClient = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });
            //     // this.init();
            //     if (this.localVideoTrack) {
            //         this.localVideoTrack.setDevice(this.selectedCameraId).then(() => {
            //             this.$message.success("切换摄像头成功");
            //         }).catch(e => {
            //             this.$message.error("切换摄像头失败");
            //         })
            //     }
            // }
            this.facingMode = this.facingMode == "user" ? "environment" : "user";
            if (this.localVideoTrack) {
                this.localVideoTrack.setDevice({
                    facingMode: this.facingMode
                }).then(() => {
                    this.$message.success("切换摄像头成功");
                }).catch(e => {
                    this.$message.error("切换摄像头失败");
                })
            }

        },
        stopRtc() {
            videoClient.leave();
        },
        async init() {
            var ret = await getToken(this.options.channel, this.options.uid);
            if (ret.data.status == 200) {
                this.options.token = ret.data.data;
            }



            await videoClient.join(this.options.appId, this.options.channel, this.options.token, Number(this.options.uid));

            if (this.role == 'host') {
                // await this.initTracks();

                this.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack(
                    {
                        AEC: true,
                        ANS: true,

                    }
                );
                this.localAudioTrack.setVolume(this.volume);

                this.localVideoTrack = await AgoraRTC.createCameraVideoTrack({
                    // cameraId: this.selectedCameraId,
                    facingMode: this.facingMode
                });
                await videoClient.publish([this.localAudioTrack, this.localVideoTrack]);
                this.localVideoTrack.play(this.$refs.phoneVideo);
                // this.$nextTick(() => {
                //     this.volume = this.localAudioTrack.getVolumeLevel();
                // })

            }
        },
        async handleUserPublished(user, mediaType) {

        },
        handleUserUnpublished(user, mediaType) {

        },

    },

};
</script>
<style lang="scss" scoped>
.wrapper {
    .cameraBtn {
        position: fixed;
        right: 20px;
        top: 20px;
        background: #fff;
        border-radius: 50%;
        border: solid 5px #eee;
    }

    .micBtn {
        position: fixed;
        right: 20px;
        top: 80px;
        background: #fff;
        border-radius: 50%;
        border: solid 5px #eee;
    }

    .volumeSilder {
        position: fixed;
        right: 20px;
        top: 150px;

    }
}
</style>