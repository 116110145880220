import axios from 'axios'
import errorCode from './errorCode'

// HTTPrequest拦截
axios.interceptors.request.use(config => {
  config.headers['AccessToken'] = window.localStorage.getItem('token')// token
  return config
}, error => {
  return Promise.reject(error)
})

// HTTPresponse拦截
axios.interceptors.response.use(res => {
  const status = Number(res.status) || 200
  const message = res.data.msg || errorCode[status] || errorCode['default']

  // 后台定义 424 针对令牌过去的特殊响应码
  if (status === 424) {
    MessageBox.confirm('令牌状态已过期，请点击重新登录', '系统提示', {
      confirmButtonText: '重新登录',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        store.dispatch('LogOut').then(() => {
          // 刷新登录页面，避免多次弹框
          window.location.reload()
        })
      })
      .catch(() => {})
    return
  }

  if (status !== 200 || res.data.code === 1) {
    Message({
      message: message,
      type: 'error'
    })
    return Promise.reject(new Error(message))
  }

  // 针对密文返回解密
  // if (res.data.encryption) {
  //   const originData = JSON.parse(aesDecrypt(res.data.encryption, website.encPassword))
  //   res.data = originData
  //   return res
  // }

  return res
}, error => {
  // 处理 503 网络异常
  if (error.response.status === 503) {
    Message({
      message: error.response.data.msg,
      type: 'error'
    })
  }
  // NProgress.done()
  return Promise.reject(new Error(error))
})

export default axios



// //axiosInstance.js
// //导入axios
// import axios from 'axios'

// //使用axios下面的create([config])方法创建axios实例，其中config参数为axios最基本的配置信息。
// const API = axios.create({
// 	baseURL:'http://localhost:5021/api', //请求后端数据的基本地址，自定义
// 	timeout: 2000                   //请求超时设置，单位ms
// });
// console.log('API',api)

// //导出我们建立的axios实例模块，ES6 export用法
// export default API
