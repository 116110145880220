<template>
  <el-dialog top="5vh" :title="'选择'" style="width: 70%;" :close-on-click-modal="false" v-model="myVisible" @close="cancel"
    draggable>
    <!-- <el-tabs default-active-key="1"  type="card">
      <el-tab-pane key="1" label="按考试信息查询"> -->
    <div class="table-page-search-wrapper">
      <div class="inlineblo">
        <div>考试名称:</div>
        <div>
          <el-input v-model="query.examName" placeholder="考试名称"></el-input>
        </div>
        <div>&nbsp;排考类型:</div>
        <div>
          <el-checkbox-group v-model="query.examKind" label="label">
            <el-checkbox :label="item.value" v-for="item in plainOptions" checked>
              {{ item.label }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
        <div>
          <el-button @click="searchList()" type="primary" :icon="Search">搜索</el-button>
        </div>
      </div>
      <div class="inlineblo">
        <div>考试时间:</div>
        <div>
          <el-radio-group v-model="query.examDatetime">
            <el-radio :label="0"> 不限 </el-radio>
            <el-radio :label="7"> 一周之内 </el-radio>
            <el-radio :label="30"> 一个月之内 </el-radio>
            <el-radio :label="90"> 三个月之内 </el-radio>
            <el-radio :label="180"> 半年之内 </el-radio>
            <el-radio :label="365"> 一年之内 </el-radio>
          </el-radio-group>
        </div>
      </div>
    </div>
    <el-table :data="list" border v-loading="dataLoading" @selection-change="onSelectionChange" height="400">
      <el-table-column type="selection" width="55" />

      <!-- <el-table-column label="序号" prop="">
            <template slot-scope="text, record, index">
              <span>
                {{ (query.pageIndex - 1) * query.pageSize + Number(index) + 1 }}
              </span>
            </template>
          </el-table-column> -->
      <el-table-column label="排考类型" prop="E_Kind">
        <template #default="scope">
          {{ GetExamTypeDetail(scope.row) }}
        </template>
      </el-table-column>

      <el-table-column label="考试名称" prop="E_Name">
      </el-table-column>
      <el-table-column label="考试类型" prop="Exam_Class_Name">
      </el-table-column>
      <el-table-column label="开始时间" prop="E_StartTime" />
      <el-table-column label="结束时间" prop="E_EndTime" />
    </el-table>
    <div class="avue-crud__pagination" style="display: flex;justify-content: space-between;padding:10px 10px 5px 10px;">
      <el-button type="primary" @click="onSelect">选择</el-button>

      <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage" background
        layout="total, prev, pager, next, sizes">
      </el-pagination>
    </div>

    <!-- <div style="
            margin-top: 10px;
            border: 0px green solid;
            height: 40px;
            display: flex;
            align-items: flex-end;
            flex-direction: column-reverse;
            z-index: 1000;
          ">
          <a-pagination :showTotal="(total) => `共 ${total} 条`" v-model="query.pageIndex" :total="total"
            @change="pagesicechang" show-less-items show-size-changer @showSizeChange="onShowSizeChange" />
        </div> -->
    <!-- </el-tab-pane>
      <el-tab-pane key="2" label="按考生信息查询" force-render>
        <div class="table-page-search-wrapper">
          <div class="inlineblo" style="margin-top: 10px">
            <div>考生学号:</div>
            <div>
              <el-input v-model="query2.examStudentUserInfoName"></el-input>
            </div>
            <div>考生姓名:</div>
            <div>
              <el-input v-model="query2.examStudentUserInfoTrueName"></el-input>
            </div>
            <div>
              <el-button @click="searchList2" type="primary" :icon="Search">搜索</el-button>
            </div>
          </div>
        </div>
        <el-table :data="list" :pagination="false" :customRow="rowClick2" rowKey="E_ID" :bordered="true"
          id="tableSelByStudent" :rowSelection="{
            selectedRowKeys: selectedRowKeys2,
            onChange: onSelectChange2,
            type: 'radio',
          }"> -->
    <!-- <el-table-column label="序号">
            <template slot-scope="text, record, index">
              <span>
                {{
                  (query2.pageIndex - 1) * query2.pageSize + Number(index) + 1
                }}
              </span>
            </template>
          </el-table-column> -->
    <!-- <el-table-column label="排考类型">
            <template slot-scope="text, record">
              <span style="word-break: break-all">
                {{ gettype(record.E_Kind, record.float3) }}
              </span>
            </template>
          </el-table-column>

          <el-table-column label="考试名称" key="E_Name">
            <template slot-scope="text, record">
              <span style="word-break: break-all">
                {{ decodeURIComponent(record.E_Name) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="考试类型">
            <template slot-scope="text, record">
              <span style="word-break: break-all">
                {{ decodeURIComponent(record.ExamClassName) }}
              </span>
            </template></el-table-column>
          <el-table-column label="开始时间" data-index="E_StartTime" key="E_StartTime" />
          <el-table-column label="结束时间" key="E_EndTime" data-index="E_EndTime" /> -->
    <!-- </el-table> -->
    <!-- <div style="
            margin-top: 10px;
            border: 0px green solid;
            height: 40px;
            display: flex;
            align-items: flex-end;
            flex-direction: column-reverse;
            z-index: 1000;
          ">
          <a-pagination :showTotal="(total) => `共 ${total} 条`" v-model="query2.pageIndex" :total="total"
            @change="pagesicechang2" show-less-items show-size-changer @showSizeChange="onShowSizeChange2" />
        </div> -->
    <!-- </el-tab-pane>

    </el-tabs> -->
  </el-dialog>
</template>
<script setup>
import {
  Check,
  Delete,
  Edit,
  Message,
  Search,
  Download,
} from '@element-plus/icons-vue'
</script>
<script>
// import { request } from "@/global/CommonService";
import { GetExamTypeDetail } from "./allFun";
import { getList as getExamList } from "@/api/exam"
import { toRaw } from '@vue/reactivity'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    isMatch: {
      type: Number,
      default: 0,
    },
    zIndex: {
      type: Number,
      default: 0
    },
    selectvalueData: {
      type: Array,
    },
    unitCode: {
      type: String
    }
  },
  emits: [
    'update:visible'
  ],
  watch: {
    visible(val) {
      console.log('dialogvisible', val)
      this.myVisible = val;
      // if (val) {
      //   this.isComited = false;
      // }
    },
    'query.examDatetime'(val) {
      this.getlist();
    }
  },
  created() {
    console.log("param isMatch=" + this.isMatch);
    this.query.IsMatch = this.isMatch;
    this.query2.IsMatch = this.isMatch;
    if (this.isMatch == 1) {
      this.ExamKindMap = ["多站式", "赛道式"];
      this.plainOptions = [
        { label: "赛道式考试", value: 3 },
        { label: "多站考试", value: 2 },
      ];
    }

    this.getlist();
    // this.$watch("visible", () => {
    //   //this.getScoreList()
    //   if (this.visible) {
    //     console.log("层叠" + this.zIndex);
    //     // if (window.localStorage.getItem("selectvalue")) {
    //     //   let selectvalue = JSON.parse(
    //     //     window.localStorage.getItem("selectvalue")
    //     //   );
    //     //   this.selectvalue = selectvalue;
    //     // }
    //     // console.log("传递" + JSON.stringify(this.selectvalueData));

    //     let sendData = window.sessionStorage.getItem("selectvalue");
    //     console.log("da" + sendData);

    //     // this.selectedRowKeys = sendData[0].id;
    //     // this.selectedRows = this.selectvalueData;
    //   }
    // });
  },
  data() {
    return {
      dataLoading: false,
      zIndex: 0,
      myVisible: this.visible,
      total: 0,
      ExamKindMap: [
        "长短站",
        "多站式",
        "单站式",
        "自定义", // added by lyl on 20161216
      ],
      zIndex: 999,
      current: 1,
      inline: true,
      checkedList: [],
      plainOptions: [
        { label: "单站考试", value: 3 },
        { label: "多站考试", value: 2 },
        { label: "长短站考试", value: 1 },
        { label: "响应式考试", value: 4 },
      ],
      query: {
        examSearchType: "examCondition",
        examDatetime: 0,
        examName: "",
        examClass: "",
        examKind: [3, 2, 1, 4],
        examStudentUserInfoName: "",
        examStudentNumber: "",
        examStudentUserInfoTrueName: "",
        pageIndex: 1,
        pageSize: 10,
        sortField: "E_StartTime",
        sortOrder: "desc",
        IsMatch: 0,
      },
      query2: {
        examSearchType: "examStudentCondition",
        examDatetime: "",
        examName: "",
        examClass: "",
        examKind: "3,2",
        examStudentUserInfoName: "",
        examStudentNumber: "",
        examStudentUserInfoTrueName: "",
        pageIndex: 1,
        pageSize: 10,
        sortField: "E_StartTime",
        sortOrder: "desc",
        IsMatch: 0,
        E_kind: 3,
      },
      list: [],
      list2: [],
      selectedRowKeys: [],
      selectedRows: [],
      selectedRowKeys2: [],
      selectedRows2: [],
      pageIndex: 1,
      pageSize: 20,
      totalPage: 0,
      multipleSelection: []
    };
  },
  mounted() {
    console.log("阿达的" + this.selectvalueData);
  },
  methods: {
    onSelectionChange(val) {
      this.multipleSelection = val;
    },
    onSelect() {
      if (this.multipleSelection.length <= 0) {
        this.$message.error("至少选择一场考试")
        return
      }
      this.$emit("selected", this.multipleSelection)

      this.myVisible = false;
      this.$emit("update:visible", this.myVisible)

      this.$emit('cancel', false)


    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getlist()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getlist()
    },
    GetExamTypeDetail(r) {
      return GetExamTypeDetail(r, 0);
    },
    searchList() {
      this.query.pageIndex = 1;
      this.getlist();
    },
    searchList2() {
      this.query2.pageIndex = 1;
      this.getlist2();
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
      debugger;

      this.$emit("rowclick", {
        id: this.selectedRows[0].E_ID,
        name: decodeURIComponent(this.selectedRows[0].E_Name),
        kind: this.selectedRows[0].E_Kind,
        E_IsOpenScore: this.selectedRows[0].E_IsOpenScore,
      });
    },
    onSelectChange2(selectedRowKeys, selectedRows) {
      this.selectedRowKeys2 = selectedRowKeys;
      this.selectedRows2 = selectedRows;

      this.$emit("rowclick", {
        id: this.selectedRows2[0].E_ID,
        name: decodeURIComponent(this.selectedRows2[0].E_Name),
        kind: this.selectedRows2[0].E_Kind,
        E_IsOpenScore: this.selectedRows2[0].E_IsOpenScore,
      });
    },
    rowClick(record, index) {
      return {
        on: {
          click: (e) => {
            //this.$emit('rowclick', { id: record.E_ID, name: decodeURIComponent(record.E_Name) })

            this.selectedRowKeys = [];
            this.selectedRowKeys.push(record.E_ID);

            this.selectedRows = [];
            this.selectedRows.push(record);

            setTimeout(() => {
              this.$emit("rowclick", {
                id: record.E_ID,
                name: decodeURIComponent(record.E_Name),
                kind: record.E_Kind,
                E_IsOpenScore: record.E_IsOpenScore,
              });
            }, 200);
          },
        },
      };
    },
    rowClick2(record, index) {
      return {
        on: {
          click: (e) => {
            //this.$emit('rowclick', { id: record.E_ID, name: decodeURIComponent(record.E_Name) })

            this.selectedRowKeys2 = [];
            this.selectedRowKeys2.push(record.E_ID);

            this.selectedRows2 = [];
            this.selectedRows2.push(record);
            setTimeout(() => {
              this.$emit("rowclick", {
                id: record.E_ID,
                name: decodeURIComponent(record.E_Name),
                kind: record.E_Kind,
                E_IsOpenScore: record.E_IsOpenScore,
              });
            }, 200);
          },
        },
      };
    },
    handleSubmit(e) {
      console.log(this.formInline);
    },
    cancel() {
      this.myVisible = false;
      this.$emit('update:visible', this.myVisible)
      //this.$emit("cancel");
    },
    confirmLoading() { },
    //data=一级分类；sec_kind=二级分类
    gettype(data, sec_kind) {
      switch (data) {
        case "1":
          //return this.ExamKindMap[0]
          return "长短站";
        case "2":
          if (sec_kind === "1" || sec_kind === "2") {
            return "多站式";
          }
          if (sec_kind === "10" || sec_kind === "12") {
            return "响应式";
          }
        case "3":
          //return this.ExamKindMap[2]
          if (this.isMatch == 1) {
            return "赛道式";
          } else {
            return "单站式";
          }
      }
    },
    getlist() {
      this.dataLoading = true;
      getExamList({
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        plainTypes: toRaw(this.query.examKind),
        examDatetime: toRaw(this.query.examDatetime),
        examName: toRaw(this.query.examName),
        unitCode: this.unitCode
      }).then(res => {
        this.dataLoading = false;
        this.list = res.data.data
        this.totalPage = res.data.total
      })
      // request(
      //   "/SortExam/PrintResult/SearchScore1.aspx/SearchExamTable",
      //   { ...this.query, examKind: this.query.examKind.toString() },
      //   "matchList"
      // ).then((res) => {
      //   that.list = res.tableData.examTableList;
      //   that.total = parseInt(res.tableData.examTableCount);
      // });
    },
    getlist2() {
      const that = this;
      debugger;
      // request(
      //   "/SortExam/PrintResult/SearchScore1.aspx/SearchExamTable",
      //   this.query2,
      //   "matchList"
      // ).then((res) => {
      //   that.list = res.tableData.examTableList;
      //   that.total = parseInt(res.tableData.examTableCount);
      // });
    },
    pagesicechang(page, size) {
      this.query.pageIndex = page;
      this.query.pageSize = size;
      this.getlist();
    },
    pagesicechang2(page, size) {
      this.query2.pageIndex = page;
      this.query2.pageSize = size;
      this.getlist2();
    },
    onChange() {
      this.query.pageIndex = 1;
      this.getlist();
    },
    changtab(activeKey) {
      const that = this;
      debugger;
      if (activeKey == 1) {
        //that.getlist()
        that.searchList();
      } else {
        //that.getlist2()
        that.searchList2();
      }
    },

    //<a-pagination :showTotal="total => `共 ${total} 条`" v-model="query.pageIndex" :total="total" @change="pagesicechang" show-less-items show-size-changer @showSizeChange="onShowSizeChange"/>
    onShowSizeChange(current, pageSize) {
      console.log(current, pageSize);
      this.query.pageIndex = 1;
      this.query.pageSize = pageSize;
      this.getlist();
    },
    onShowSizeChange2(current, pageSize) {
      console.log(current, pageSize);
      this.query2.pageIndex = 1;
      this.query2.pageSize = pageSize;
      this.getlist2();
    },
  },
};
</script>
<style  lang="scss" scoped>
.inlineblo {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  div {
    margin-left: 5px;
  }
}

div #tableSelByExam .ant-table-body {
  min-height: 500px;
  max-height: 500px;
  overflow-y: auto;
}

div #tableSelByStudent .ant-table-body {
  min-height: 500px;
  max-height: 500px;
  overflow-y: auto;
}
</style>
