<template>
    <video :id="videoId" class="player" controls autoplay style="width:100%;height:100%;">
    </video>
</template>
<script>
import Srs from './srs.sdk'

export default {
    name: 'webrtcPlayer2',
    props: {
        videoId: {
            type: String,
            default: 'player'
        },
        url: {
            type: String,
            default: ''
        },

    },
    data() {
        return {
        }
    },
    created() {

    },
    mounted() {
        this.play()
    },
    methods: {
        play() {
            var player = document.getElementById(this.videoId);

            //方法一：使用srs.sdk.js
            const rtcPlayer = new Srs.SrsRtcPlayerAsync()
            rtcPlayer.play(this.url)
            // video标签
            player.srcObject = rtcPlayer.stream

            //方法二：使用jswebrtc.min.js
            // new JSWebrtc.Player(this.url, { video: player, autoplay: true, });
        }
    }
}
</script>