import { createStore } from 'vuex'

export default createStore({
  state: {
    userInfo: {},
    rtcRoom: {}
  },
  getters: {
    userInfo: state => state.userInfo,
    rtcRoom: state => state.rtcRoom
  },
  mutations: {
    SET_USER_INFO(state, data) {
      state.userInfo = data;
      // 这里可以进行一些操作，比如将用户信息存储到本地
      localStorage.setItem('userInfo', JSON.stringify(data));
    },
    SET_RTC_ROOM(state, data) {
      state.rtcRoom = data;
    }
  },
  actions: {
    //从本地localStorage获取用户信息
    GetUserInfo({ commit }) {
      const userInfo = localStorage.getItem('userInfo');
      if (userInfo) {
        var ui = JSON.parse(userInfo);
        commit('SET_USER_INFO', ui);
        return ui;
      }
      return null
    },
    // 设置用户信息
    SetUserInfo({ commit }, data) {
      commit('SET_USER_INFO', data);
    },
    SetRtcRoom({ commit }, data) {
      commit('SET_RTC_ROOM', data);
    }
  },
  modules: {
  }
})
