<template>
    <div class="wrapper">
        <el-button type="primary" plain>Primary</el-button>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
        return {
            name: ""
        };
    },
    watch: {},
    computed: {},
    methods: {},
    created() {
        let geturl = window.location.href
        let getqyinfo = geturl.split('?')[1]   //qycode=1001&qyname=%E4%BC%81%E4%B8%9A%E5%BF%99   截取到参数部分
        let getqys = new URLSearchParams('?' + getqyinfo)  //将参数放在URLSearchParams函数中
        this.name = getqys.get('name')   //1001   
        //let getqyname = getqys.get('qyname')  //%E4%BC%81%E4%B8%9A%E5%BF%99
    },
    mounted() { }
};
</script>
<style>
.wrapper{}
</style>