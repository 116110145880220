import { createRouter, createWebHashHistory } from 'vue-router'
// import Layout from '@/layout/index.vue'
// import Home from '@/views/home/Home.vue'
import index from '@/pages/home/index.vue'
import about from '@/pages/home/about.vue'

import singleList from "@/pages/singleStatistics/list.vue"
import singleInfo from "@/pages/singleStatistics/detail.vue"
import singleScoreInfoWord from "@/pages/singleStatistics/word.vue"
import singleRateInfo from "@/pages/singleStatistics/rater.vue"

import achievement from "@/pages/achievement/index.vue"
import ate from "@/pages/ate/index.vue"


import examinerList from '@/pages/examiner/list.vue'
import examinerInfo from '@/pages/examiner/info.vue'

import rtcList from '@/pages/rtc/list.vue'
import rtcRoom from '@/pages/rtc/room.vue'
import rtcRoomSw from '@/pages/rtc/room_sw.vue'
import rtcRoomPhone from '@/pages/rtc/room_phone.vue'
const routes = [
    {
        path: '/',
        component: index
    },
    {
        path: '/about',
        component: about,
        // children: [{ path: '', component: Home }],
    },
    {
        path: '/singleStatistics/detail',
        component: singleInfo
    },
    {
        path: '/singleStatistics',
        component: singleList,
        meta: { keepAlive: true }
    },
    {
        path: '/singleStatistics/word',
        component: singleScoreInfoWord
    },
    {
        path: '/singleStatistics/rater',
        component: singleRateInfo
    },
    {
        path: '/achievement',
        component: achievement,
        meta: { keepAlive: true }
    },
    {
        path: '/ate',
        component: ate,
        meta: { keepAlive: true }
    },
    {
        path: '/examiner',
        component: examinerList,
        meta: { keepAlive: true }

    },
    {
        path: '/examiner/info',
        component: examinerInfo
    },
    {
        path: '/rtc/list',
        component: rtcList
    },
    {
        path: '/rtc/room',
        component: rtcRoom
    }, {
        path: '/rtc/room_sw',
        component: rtcRoomSw
    }, {
        path: '/rtc/room_phone',
        component: rtcRoomPhone
    }
]

export default createRouter({
    history: createWebHashHistory(),
    routes,
})